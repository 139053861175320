const filterBoxStyle = (theme) => ({
  paper: {
    padding: "10px 0"
  },
  boxContainer: {
    display: "inline-block"
  },
  filterBox: {
    margin: "10px 20px",
    width: "300px",
    fontSize: "14px"
  },
  title: {
    display: "inline-block",
    margin: "0 0 0 10px",
    "vertical-align": "middle",
    fontSize: "16px"
  },
  btnFilter: {
    margin: "0 10px",
    fontSize: "14px"
  },
  container: {
    display: "inline-block",
    width: "100%",
    margin: "10px 0",

  }
});

export default filterBoxStyle;