import React from "react";
import PropTypes from 'prop-types';

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { DropzoneArea } from 'material-ui-dropzone';

// @material-ui/icons
import GetApp from '@material-ui/icons/GetApp';
// core components

import xls from "../../assets/img/MIS/xls.PNG";

import styles from "../../assets/jss/material-dashboard-react/components/dialogStyle.js";

const useStyles = makeStyles(styles);

export default function UploadDialog(props) {
  const classes = useStyles();
  const { onCloseDialog, openUpload, showHideAlert } = props;
  const [file, setFile] = React.useState([]);
  const [fail, setFail] = React.useState(false);

  const handleClose = () => {
    onCloseDialog();
    setFail(false);
  };

  const handleChange = (fileUpload) => {
    setFile(fileUpload);
  }

  const handleDownloadError = () => {
    console.log('handleDownloadError');
  }

  const handleUpload = (fileUpload) => {
    let isSuccess = false;
    console.log(fileUpload);
    if(isSuccess) {
      showHideAlert(true);
      onCloseDialog();
    } else {
      setFail(true);
    }
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={openUpload}>
      <DialogTitle id="simple-dialog-title">Tải Lên</DialogTitle>
      { !fail
          ? <Box className={classes.bodyDialog}>
              <DropzoneArea
                onChange={(file) => handleChange(file)}
                acceptedFiles={['.xlsx','.xls','.csv']}
                dropzoneText={"Click or drag file to this area to upload"}
              />
            </Box>
          : <Box className={classes.bodyDialog}>
              <Box className={classes.notiBox}>
                <strong style={{color: '#00B517'}}>19</strong>/20 Chỉ tiêu tải lên Thành Công
              </Box>
              <Box className={classes.guildBox}>
                <strong style={{color: '#F53838'}}>01</strong>/20 Chỉ tiêu tải lên Thất Bại
                <p className={classes.text}>
                  <strong>Please download the excel file to re-check.</strong>
                </p>
                <p className={classes.text}>You will need to import a new file with correct amendment in order to create the records.</p>
              </Box>
              <Box className={classes.download} onClick={handleDownloadError}>
                <img src={xls} alt="xls" className={classes.img} />
                <div style={{display: 'inline-block', margin: '0 5px', fontWeight: 'bold'}}>file-error.xlsx</div>
                <GetApp />
              </Box>
            </Box>
      }
      
      <Box className={classes.footerDialog}>
        { !fail
            ? <Button variant="contained" color="primary" onClick={handleUpload} className={classes.btn}>
                Tải Lên
              </Button>
            : <Button variant="contained" color="primary" onClick={handleClose} className={classes.btn}>
                Ok
              </Button>
        }
        <Button variant="outlined" color="primary" onClick={handleClose} className={classes.btn}>
          Hủy
        </Button>
      </Box> 
    </Dialog>
  );
}

UploadDialog.propTypes = {
  onCloseDialog: PropTypes.func.isRequired,
  openUpload: PropTypes.bool.isRequired,
  showHideAlert: PropTypes.func
};
