import React from "react";
import PropTypes from 'prop-types';

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Box, Button } from "@material-ui/core";

// @material-ui/icons
import Info from "@material-ui/icons/Info";
import Apartment from "@material-ui/icons/Apartment";
import ListAlt from "@material-ui/icons/ListAlt";
import PlaylistAddCheck from "@material-ui/icons/PlaylistAddCheck";

// core components
import DataGridEditable from "../DataGrid/DataGridEditable";

import styles from "../../assets/jss/material-dashboard-react/components/viewDetailStyle";

import {Link, Element} from 'react-scroll'

const useStyles = makeStyles(styles);

const res = {
  detail: {
    id_target: "10030012101",
    name_target: "ABC123",
    role: 1,
    status: true,
    cost_center: {
      columns: [
        { title: 'Mã Cơ Sở', field: 'idCostCenter' , lookup: { 1: '1003', 2: '1004', 3: '1005' }},
        { title: 'Tên Cở Sở', field: 'nameCostCenter', lookup: { 1: 'Vinpearl Resort & Spa Nha Trang 1', 2: 'Vinpearl Resort & Spa Nha Trang 2', 3: 'Vinpearl Resort & Spa Nha Trang 3' }},
      ],
      data: [
        { idCostCenter: 1, nameCostCenter: 1}
      ]
    },
    company: {
      columns: [
        { title: 'Mã Cơ Sở', field: 'idCostCenter' , lookup: { 1: '1003', 2: '1004', 3: '1005' }},
        { title: 'Tên Cở Sở', field: 'nameCostCenter', lookup: { 1: 'Vinpearl Resort & Spa Nha Trang 1', 2: 'Vinpearl Resort & Spa Nha Trang 2', 3: 'Vinpearl Resort & Spa Nha Trang 3' }},
      ],
      data: [
        { idCostCenter: 1, nameCostCenter: 1}
      ]
    },
    target: {
      columns: [
        { title: 'Mã Cơ Sở', field: 'idCostCenter' , lookup: { 1: '1003', 2: '1004', 3: '1005' }},
        { title: 'Tên Cở Sở', field: 'nameCostCenter', lookup: { 1: 'Vinpearl Resort & Spa Nha Trang 1', 2: 'Vinpearl Resort & Spa Nha Trang 2', 3: 'Vinpearl Resort & Spa Nha Trang 3' }},
      ],
      data: [
        { idCostCenter: 1, nameCostCenter: 1}
      ]
    }
  }
};

export default function ViewDetailTargetConfig(props) {
  const classes = useStyles();
  const [form, setForm] = React.useState(...[res]);

  const backMainPage = () => {
    // props.openView(false, itemDetail);
    window.location.reload();
  }

  const saveForm = () => {
    console.log(form);
  }

  const toggleChecked = (event) => {
    setForm(prevState => {
      let detail = Object.assign({}, prevState.detail);
      detail.status = event.target.checked;                 
      return { detail };  
    });
  }

  const handleChangeRole = (event) => {
    setForm(prevState => {
      let detail = Object.assign({}, prevState.detail);
      detail.role = event.target.value;                 
      return { detail };  
    });
  }

  const handleChangeText = (event) => {
    setForm(prevState => {
      let key = event.target.id;
      let detail = Object.assign({}, prevState.detail);
      detail[key] = event.target.value;                 
      return { detail };  
    });
  }

  const handleChangeEditable = (newData, action) => {
    setForm(prevState => {
      let detail = Object.assign({}, prevState.detail);
      detail[action].data = newData;
      return { detail };  
    });
  }
  
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={2} sm={2}>
          <Paper className={classes.paper}>
            <ul className={classes.navLinkContainer}>
              <li className={classes.tabs}><Link activeClass="active" to="information" spy={true} smooth={true}>Thông tin chung</Link></li>
              <li className={classes.tabs}><Link to="company" spy={true} smooth={true}>Cơ sở</Link></li>
              <li className={classes.tabs}><Link to="costCenter" spy={true} smooth={true}>Cost Center</Link></li>
              <li className={classes.tabs}><Link to="target" spy={true} smooth={true}>Nhóm Chỉ tiêu</Link></li>
            </ul>
          </Paper>
        </Grid>
        <Grid item xs={10} sm={10}>
          <Paper className={classes.paper}>
            <div id="FormDetail">

              {/* Information */}
              <Element id="information" name="information">
                <Box display="flex" p={1}>
                  <Box p={1} flexGrow={1} style={{paddingTop: "14px"}}>
                    <Info style={{verticalAlign: "middle"}}/>
                    <h3 className={classes.title}>Thông Tin Chung</h3>
                  </Box>
                  <Box p={1}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Switch checked={form.detail.status} onChange={toggleChecked} color="primary" />}
                        label="Trạng Thái" labelPlacement="start" name="status"
                      />
                    </FormGroup>
                  </Box>
                </Box>
                <form noValidate  autoComplete="off">
                  <div className={classes.margin}>
                    <TextField id="id_target" label="Mã Chỉ Tiêu" style={{ margin: 8,width: "45%" }} required className={classes.margin}
                      placeholder="Nhập mã chỉ tiêu" margin="normal" InputLabelProps={{shrink: true,}} value={form.detail.id_target} onChange={handleChangeText} />

                    <TextField id="name_target" label="Tên Chỉ Tiêu" style={{ margin: 8,width: "45%" }} required className={classes.margin}
                      placeholder="Nhập tên chỉ tiêu" margin="normal" InputLabelProps={{shrink: true,}} value={form.detail.name_target} onChange={handleChangeText} />
                  </div>

                  <div className={classes.margin}>
                    <FormControl className={classes.margin} style={{width: "45%"}}>
                      <InputLabel shrink id="GroupTarget">
                        Chức Danh
                      </InputLabel>
                      <Select labelId="GroupTarget" id="GroupTarget" value={form.detail.role} onChange={handleChangeRole} displayEmpty className={classes.selectEmpty}>
                        <MenuItem value="">Chọn Chỉ Tiêu</MenuItem>
                        <MenuItem value={1}>Nhóm Marketing</MenuItem>
                        <MenuItem value={2}>Nhóm Vận Hành</MenuItem>
                        <MenuItem value={3}>Nhóm Nhân Sự</MenuItem>
                        <MenuItem value={4}>Nhóm Pháp Chế</MenuItem>
                        <MenuItem value={5}>Nhóm Mua Sắm</MenuItem>
                        <MenuItem value={6}>Nhóm Kế Hoạch</MenuItem>
                        <MenuItem value={7}>Nhóm Thanh Tra</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </form>
              </Element>

              {/* Company */}
              <Element id="company" name="company">
                <Box display="flex" p={1}>
                  <Box p={1} flexGrow={1} style={{paddingTop: "14px"}}>
                    <Apartment style={{verticalAlign: "middle"}}/>
                    <h3 className={classes.title}>Cơ Sở</h3>
                  </Box>
                </Box>

                <DataGridEditable 
                  columns={form.detail.company.columns}
                  data={form.detail.company.data}
                  handleChangeEditable={handleChangeEditable}
                  action="company" 
                />
              </Element>

              {/* Cost Center */}
              <Element id="costCenter" name="costCenter">
                <Box display="flex" p={1}>
                    <Box p={1} flexGrow={1} style={{paddingTop: "14px"}}>
                      <ListAlt style={{verticalAlign: "middle"}} />
                      <h3 className={classes.title}>Cost Center</h3>
                    </Box>
                  </Box>

                  <DataGridEditable 
                    columns={form.detail.cost_center.columns} 
                    data={form.detail.cost_center.data}
                    handleChangeEditable={handleChangeEditable}
                    action="cost_center"
                  />
              </Element>

              {/* Group Target */}
              <Element id="target" name="target">
                <Box display="flex" p={1}>
                  <Box p={1} flexGrow={1} style={{paddingTop: "14px"}}>
                    <PlaylistAddCheck style={{verticalAlign: "middle"}} />
                    <h3 className={classes.title}>Nhóm Chỉ Tiêu</h3>
                  </Box>
                </Box>

                <DataGridEditable
                  columns={form.detail.target.columns}
                  data={form.detail.target.data}
                  handleChangeEditable={handleChangeEditable}
                  action="target" 
                />
              </Element>
            </div>
            
            {/* Submit */}
            <Box display="flex" flexDirection="row-reverse" p={1} m={1}>
              <Box p={1}>
                <Button variant="outlined" color="primary" className={classes.margin} onClick={() => backMainPage()}>
                  Hủy
                </Button>
                <Button variant="contained" color="primary" className={classes.margin} onClick={() => saveForm()}>
                  Lưu
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

ViewDetailTargetConfig.propTypes = {
  itemDetail: PropTypes.object,
  openView: PropTypes.func.isRequired,
};
