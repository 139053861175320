import React from "react";
// react plugin for creating charts

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import Navbar from "../../components/Navbar/Navbar.js";
import FilterBox from './../../components/Filter/FilterBox';
import DataGrid from "../../components/DataGrid/DataGrid.js";

import routes from "../../routes.js";

import styles from "../../assets/jss/material-dashboard-react/components/userConfigStyle.js";

const useStyles = makeStyles(styles);

const filterData = [
  {
    name: "company",
    label:"Cơ Sở",
    type: "dropdown",
    filters:[
      {label:"Chọn Cơ Sở",value: ""},
      {label:"Vinpearl Resort & Spa Nha Trang 1",value:"vinpearl1"},
      {label:"Vinpearl Resort & Spa Nha Trang 2",value:"vinpearl2"},
      {label:"Vinpearl Resort & Spa Nha Trang 3",value:"vinpearl3"},
    ]
  },{
    name: "status",
    label:"Trạng Thái",
    type: "dropdown",
    filters:[
      {label:"Chọn Trạng Thái",value: ""},
      {label:"Thành Công",value:"success"},
      {label:"Thất Bại",value:"fail"},
    ]
  }
];

const rows = [
  {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",location:"RIC00000001", status: "Thành Công"},
  {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",location:"RIC00000001", status: "Thành Công"},
  {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",location:"RIC00000001", status: "Thất Bại"},
  {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",location:"RIC00000001", status: "Thành Công"},
  {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",location:"RIC00000001", status: "Thành Công"},
  {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",location:"RIC00000001", status: "Thành Công"},
  {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",location:"RIC00000001", status: "Thất Bại"},
  {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",location:"RIC00000001", status: "Thành Công"},
  {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",location:"RIC00000001", status: "Thành Công"},
  {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",location:"RIC00000001", status: "Thành Công"},
  {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",location:"RIC00000001", status: "Thành Công"},
  {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",location:"RIC00000001", status: "Thành Công"},
  {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",location:"RIC00000001", status: "Thành Công"},
  {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",location:"RIC00000001", status: "Thành Công"},
  {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",location:"RIC00000001", status: "Thành Công"},
  {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",location:"RIC00000001", status: "Thành Công"},
  {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",location:"RIC00000001", status: "Thành Công"},
  {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",location:"RIC00000001", status: "Thành Công"},
];

const headCells = [
  { id: 'id_company', numeric: false, disablePadding: true, label: 'Mã Cơ Sở', rendered: {type: "key"} },
  { id: 'name_company', numeric: true, disablePadding: false, label: 'Tên Cơ Sở', rendered: false },
  { id: 'location', numeric: true, disablePadding: false, label: 'Khu Vực Sở Hữu', rendered: false },
  { id: 'status', numeric: true, disablePadding: false, label: 'Trạng Thái', rendered: {type: "chip"} },
  { id: 'action', numeric: true, disablePadding: false, label: 'Hành Động', rendered: {type: "view_detail"} },
];

export default function CompanyData() {
  const classes = useStyles();
  const [openFilter, setOpenFilter] = React.useState(false);

  const openFilterBox = (openFilter) => {
    setOpenFilter(openFilter);
  }

  return (
    <GridContainer>
      <Navbar routes={routes} open={false} openFilterBox={openFilterBox} openFilter={openFilter} />

      { openFilter 
        ? <FilterBox filterData={filterData} />
        : null
      }

      <DataGrid 
        rows={rows} 
        headCells={headCells}
        hiddenBtnExport={true} 
        hiddenBtnDownloadForm={true} 
        title="Cơ Sở"
      />
    </GridContainer>
  );
}
