import React, { useEffect } from "react";
// react plugin for creating charts
import "./ManagementReport.less";
// @material-ui/core
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
// @material-ui/icons

// core components


import styles from "~/assets/jss/material-dashboard-react/components/userConfigStyle.js";


import Autocomplete from '@material-ui/lab/Autocomplete';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ViewListIcon from '@material-ui/icons/ViewList';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes'



import { Drawer, Row, Divider, Col, Spin } from 'antd';
import Button from '@material-ui/core/Button';
import { managementReportStore } from "~/stores/management-report/management-report.store.js";
import { withMobx } from "~/hooks";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { Tabs } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { languageStore } from "~/stores/language/language.store.js";
import { KeyLocalStore } from "~/common/constants/KeyLocalStore.js";


const { TabPane } = Tabs;
const useStyles = makeStyles(styles);

function a11yProps(id) {
  return {
    id: `scrollable-force-tab-${id}`,
    'aria-controls': `scrollable-force-tabpanel-${id}`,
  };
}
const ManagementReport = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { translate } = languageStore;
  const { onClose, visible, nameLv1Active,
    showDrawer,
    onChangeTab,
    dataSource,
    nameLv2Active, dataLv3, listNameLv1, listNameLv2, selectedItem, isLoading, loadData, selectedReport, selectedNameLv2 } = managementReportStore;
  useEffect(() => {
    loadData();
  }, [loadData])

  const onChangeAutocomplete = (event, value, reason, details) => {
    if (value) {
      managementReportStore.selectedItem = value;
    }

  }
  const { id: reportId = "", embedUrl = "" } = selectedItem
  return (
    <div>
      <Drawer
        title={translate("[MainMenu]Management report")}
        placement={'right'}
        closable={true}
        onClose={onClose}
        width={'85vw'}
        visible={visible}
        forceRender={false}

        key={'ManagementReport'}
      >
        <Tabs defaultActiveKey={nameLv1Active}
          onChange={onChangeTab}
          size="large" tabPosition={"left"}>
          {listNameLv1.map(nameLv1 => {
            return (
              <TabPane
                style={{ height: '90vh' }}
                tab={
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <ViewListIcon />
                    {nameLv1}
                  </span>
                }

                key={nameLv1}
              >
                <div style={{ height: '100%', }}>
                  <Row gutter={[10, 10]}>
                    <Col span={8}>
                      <Row gutter={[10, 10]} >
                        {listNameLv2.map(nameLv2 => {
                          return (
                            <Col key={nameLv2} span={24}>
                              <Button
                                variant="contained"
                                style={{ display: 'flex', justifyContent: 'start' }}
                                color={nameLv2Active === nameLv2 ? "primary" : "default"}
                                size="medium"
                                onClick={selectedNameLv2.bind(null, nameLv2)}
                                fullWidth={true}
                                startIcon={<SpeakerNotesIcon />}
                              >
                                {nameLv2}
                              </Button>
                            </Col>
                          )
                        })}
                      </Row>
                    </Col>
                    <Col span={16}>
                      <List component="nav" aria-label="main mailbox folders" style={{ borderStyle: "solid", borderWidth: 1, borderColor: theme.palette.divider }}>
                        {dataLv3.map(item => {
                          return (
                            <ListItem
                              {...a11yProps(item.id)}
                              divider
                              button
                              // onClick={selectedReport.bind(null, item)}
                              onClick={()=> window.open(item.webUrl, "_blank")}
                              selected={reportId === item.id}
                            >
                              <ListItemIcon>
                                <LibraryBooksIcon />
                              </ListItemIcon>
                              <ListItemText primary={item.name} />
                            </ListItem>
                          )
                        })}


                      </List>
                    </Col>
                  </Row>

                  <Divider type="vertical" />
                  {/* <div>
                  <TextField id="standard-basic" label="Standard" />
                </div> */}
                  <div>

                  </div>
                </div>
              </TabPane>
            )
          })}

        </Tabs>
      </Drawer>
      <div>
        <Spin indicator={<Loading3QuartersOutlined style={{ fontSize: 35 }} spin />} spinning={isLoading} >
          <div>
            <Row justify="space-between">
              <Col span={12} style={{ paddingTop: 20 }}>
                <h5>{selectedItem.name}</h5>
              </Col>
              <Col span={12}>
                <Row gutter={[10, 0]} justify="end">
                  <Col >
                    <Autocomplete
                      id="list-report-atc"
                      options={dataSource}
                      filterOptions={(options, state) => {
                        const { inputValue = "" } = state;
                        return options.filter(v => v.name.toLowerCase().includes(inputValue.toLowerCase()) || v.defineName.toLowerCase().includes(inputValue.toLowerCase()))
                      }}
                      size="small"
                      groupBy={(option) => `[${option.nameLv1}] ${option.nameLv2}`}
                      onChange={onChangeAutocomplete}
                      getOptionLabel={(option) => option.name}
                      style={{ width: 500 }}
                      renderInput={(params) => <TextField {...params} label="Tìm kiếm báo cáo" variant="standard" />}
                    />
                  </Col>
                  <Col style={{ paddingTop: 10 }} >
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      startIcon={<ViewListIcon />}
                      onClick={showDrawer}
                    >
                      {translate("[ManagementReport]List report")}
                    </Button>
                  </Col>
                </Row>
              </Col>


            </Row>
          </div>
          <Divider type="vertical" style={{ borderWidth: 0 }} />
          {/* <div style={{ width: '100%', height: '80vh', borderStyle: "solid", borderWidth: 1, borderColor: theme.palette.divider }}>
            {reportId && <PowerBIEmbed
              key={reportId}
              embedConfig={{
                type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                id: reportId,
                embedUrl: embedUrl,
                accessToken: localStorage.getItem(KeyLocalStore.accessToken),
                tokenType: models.TokenType.Aad,
                settings: {
                  panes: {
                    filters: {
                      expanded: false,
                      visible: true
                    }
                  },
                }
              }}

              eventHandlers={
                new Map([
                  ['loaded', function () { console.log('Report loaded'); }],
                  ['rendered', function () { console.log('Report rendered'); }],
                  ['error', function (event) { console.log(event.detail); }]
                ])
              }

              cssClassName={"powerbi-container"}
              getEmbeddedComponent={(embeddedReport) => {
                window.report = embeddedReport;
              }}
            />}
          </div> */}
        </Spin>
      </div>
    </div>

  );
}

export default withMobx(ManagementReport)
