const viewDetailStyle = (theme) => ({
  filterBox: {
    margin: "10px 20px",
    width: "300px"
  },
  title: {
    display: "inline-block",
    margin: "0 0 0 10px"
  },
  btnFilter: {
    margin: "0 10px"
  },
  container: {
    display: "inline-block",
    width: "100%",
    margin: "10px 0",

  },
  alert: {
    position: 'absolute',
    top: 0,
    right: 0,
    'z-index': 99999,
    display: 'none'
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
});

export default viewDetailStyle;