import { rootApiService } from "./@global";
const Enpoint = {
  listReport: "/portal/api/business/powerbi/report/list",

};
class PowerbiService {
  async listReport(params) {
    return rootApiService.get(Enpoint.listReport, params);
  }
}
export default new PowerbiService();
