import { makeAutoObservable } from "mobx";

export class UserReq {
  constructor() {
    makeAutoObservable(this);
  }

  isMaster = false;
  active = true;
  companyCode = "";
  employeeCode = "";
  fullName = "";
  jobTitleCode = "";
  jobTitleName = "";
  email = "";
  department = "";
  groups = [];
}

export class UserListReq {
  constructor() {
    makeAutoObservable(this);
  }
  pageIndex = 1;
  pageSize = 10;
  filter = "";
}

export class OrgranizationMatrixReq {
  constructor() {
    makeAutoObservable(this);
  }
  pageIndex = 1;
  pageSize = 1000;
  email = "";
}