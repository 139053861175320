const filterStyle = (theme) => ({
  container: {
    display: "inline-block"
  },
  searchBar: {
    display: "inline-block",
    padding: "0 5px",
    fontSize: "14px"
  },
  filterBar: {
    display: "inline-block",
    padding: "0 5px",
    fontSize: "14px"
  },
  btnFilter: {
    border: "1px",
    fontSize: "14px"
  }
});

export default filterStyle;