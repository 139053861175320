import React from "react";
// react plugin for creating charts

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import DataGrid from "../../components/DataGrid/DataGrid.js";
import Navbar from "../../components/Navbar/Navbar.js";
import ViewDetailTargetConfig from '../../components/ViewDetail/ViewDetailTargetConfig.js';
import FilterBox from './../../components/Filter/FilterBox';

import routes from "../../routes.js";

import styles from "../../assets/jss/material-dashboard-react/components/userConfigStyle.js";

const useStyles = makeStyles(styles);

const rows = [
  { id_target: "10030012101", name_target: "NT/RSBY/G/ROOM/FO", group_target: "Nhóm Vận Hành", status: true },
  { id_target: "10030012102", name_target: "NT/RSBY/G/ROOM/FO", group_target: "Nhóm Vận Hành", status: true },
  { id_target: "10030012103", name_target: "NT/RSBY/G/ROOM/FO", group_target: "Nhóm Vận Hành", status: false },
  { id_target: "10030012104", name_target: "NT/RSBY/G/ROOM/FO", group_target: "Nhóm Vận Hành", status: true },
  { id_target: "10030012105", name_target: "NT/RSBY/G/ROOM/FO", group_target: "Nhóm Vận Hành", status: false },
  { id_target: "10030012106", name_target: "NT/RSBY/G/ROOM/FO", group_target: "Nhóm Vận Hành", status: true },
  { id_target: "10030012107", name_target: "NT/RSBY/G/ROOM/FO", group_target: "Nhóm Vận Hành", status: false },
  { id_target: "10030012108", name_target: "NT/RSBY/G/ROOM/FO", group_target: "Nhóm Vận Hành", status: true },
  { id_target: "10030012109", name_target: "NT/RSBY/G/ROOM/FO", group_target: "Nhóm Vận Hành", status: true },
  { id_target: "10030012110", name_target: "NT/RSBY/G/ROOM/FO", group_target: "Nhóm Vận Hành", status: true },
  { id_target: "10030012111", name_target: "NT/RSBY/G/ROOM/FO", group_target: "Nhóm Vận Hành", status: true },
];

const headCells = [
  { id: 'id_target', numeric: false, disablePadding: true, label: 'Mã Chỉ Tiêu', rendered: { type: "key" } },
  { id: 'name_target', numeric: true, disablePadding: false, label: 'Tên Chỉ Tiêu', rendered: false },
  { id: 'group_target', numeric: true, disablePadding: false, label: 'Nhóm Chỉ Tiêu', rendered: false },
  { id: 'status', numeric: true, disablePadding: false, label: 'Trạng Thái', rendered: { type: "btn_switch" } },
  { id: 'action', numeric: true, disablePadding: false, label: 'Hành Động', rendered: { type: "action" } },
];

const filterData = [
  {
    name: "group_target",
    label: "Nhóm Chỉ Tiêu",
    type: "dropdown",
    filters: [
      { label: "Chọn Nhóm Chỉ Tiêu", value: "" },
      { label: "Nhóm Marketting", value: "marketting" },
      { label: "Nhóm Vận Hành", value: "operation" },
      { label: "Nhóm Nhân Sự", value: "human_resource" },
      { label: "Nhóm Pháp Chế", value: "legislation" },
      { label: "Nhóm Mua Sắm", value: "shopping" },
      { label: "Nhóm Kế Hoạch", value: "planning" },
      { label: "Nhóm Thanh Tra", value: "inspection" },
    ]
  }, {
    name: "status",
    label: "Trạng Thái",
    type: "dropdown",
    filters: [
      { label: "Chọn Trạng Thái", value: "" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" }
    ]
  }
];

export default function TargetConfig() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [itemDetail, setItemDetail] = React.useState({});

  const openView = (open, itemDetail) => {
    openFilter && setOpenFilter(false);

    setItemDetail(itemDetail);
    setOpen(open);

    window.scrollTo(0, 0);
  };

  const openFilterBox = (openFilter) => {
    setOpenFilter(openFilter);
  }

  return (
    <div>
      <div className={classes.container}>
        <DataGrid openView={openView} rows={rows} headCells={headCells} />
      </div>
    </div>
  );
}
