

import { fileReportApiService, } from "./@global";
import axios from "axios";
const Enpoint = {
  listTemplateHistory: "/kpiparser/history",
  dataAggregation: "/kpiparser/data_history",
  listTemplate: "/kpiparser/template",
  dowloadTemplateHistory: "/kpiparser",
  uploadTempate: "/kpiparser/upload",
  mergedDownload: "/kpiparser/merged_download",
  duplicateCheck: "/kpiparser/upload/duplicate-check",
  dowloadTemplate: "/kpiparser/template",
};
class FileReportService {
  async listTemplateHistory({ pageIndex = 1, pageSize = 10, type = "all", templateCode = "", segment = "" }) {
    return fileReportApiService.get(Enpoint.listTemplateHistory, {
      pageIndex, pageSize, type, templateCode, segment
    });
  }

  async listTemplateHistoryReport(req) {
    return fileReportApiService.get(Enpoint.listTemplateHistory, req);
  }

  async listDataAggregationReport(req) {
    let formData = new FormData();
    if (req.month) {
      formData.append('month', req.month);
    }
    if (req.pageIndex) {
      formData.append('pageIndex', req.pageIndex);
    }
    if (req.pageSize) {
      formData.append('pageSize', req.pageSize);
    }
    if (req.year) {
      formData.append('year', req.year);
    }
    if (req.segment_code) {
      formData.append('segment', req.segment_code);
    }
    if (req.kpi_code) {
      formData.append('kpi_code', req.kpi_code);
    }
    if (req.templateCode) {
      formData.append('templateCode', req.templateCode);
    }
    if (req.user) {
      formData.append('user', req.user);
    }
    if (req.is_download) {
      formData.append('is_download', req.is_download);
    }
    if (req.upload_type) {
      formData.append('upload_type', req.upload_type);
    }
    return fileReportApiService.uploadFile(Enpoint.dataAggregation, formData);
  }

  async listTemplate() {
    return fileReportApiService.get(Enpoint.listTemplate);
  }
  async dowloadTemplateHistory(id) {
    const res = await fileReportApiService.get(Enpoint.dowloadTemplateHistory + `/${id}`,);
    return res;
  }

  async dowloadTemplate(templateCode) {
    const res = await fileReportApiService.get(Enpoint.dowloadTemplate + `/${templateCode}`,);
    return res;
  }

  async mergedDownload(body) {
    const res = await fileReportApiService.downloadMergeFile(Enpoint.mergedDownload, body);
    return res;
  }


  uploadTemplate(formData) {
    return fileReportApiService.uploadFile(Enpoint.uploadTempate, formData);
  }

  async duplicateCheck({ type, period, segmentCode, templateCode }) {
    return fileReportApiService.get(Enpoint.duplicateCheck, {
      type, period, segmentCode, templateCode
    });
  }

  dowloadUrl = ({ url, fileName }) => {
    axios({
      url,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  }
}

export default new FileReportService();