import * as envs from './envs';

let envConfig = undefined;
export function configEnv() {
  if (envConfig) {
    return envConfig;
  }
  const envName = process.env.REACT_APP_ENV || 'dev';
  console.log(`Env: ${envName}`);
  const currentConfig = (envs)[envName];
  console.log({ currentConfig });
  return {
    ...currentConfig,
    name: envName
  }
};



