import { authApiService } from "./@global";
const Enpoint = {
  login: "/portal/api/public/auth/login-vinpearl".trim(),
  authenticate: "/portal/api/public/auth/authenticate".trim(),
  verifyUser: "/portal/api/public/auth/verify-user".trim(),
};
export class AuthService {
  async authenticate() {
    return authApiService.post(Enpoint.authenticate);
  }
  login(loginReq) {
    return authApiService.post(Enpoint.login, loginReq);
  }

  async verifyUser() {
    return authApiService.post(Enpoint.verifyUser);
  }
}
export default new AuthService();
