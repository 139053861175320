import { makeAutoObservable } from "mobx";

export class GroupRoleListReq {
  constructor() {
    makeAutoObservable(this);
  }
  pageIndex = 1;
  pageSize = 10;
  filter = "";
}

export class GroupRoleDto {
  constructor() {
    makeAutoObservable(this);
  }
  id;
  groupRoleName;
  groupRoleCode;
  description;
  templateCodes = [];
}