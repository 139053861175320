const formStyle = (theme) => ({
  formContainer: {
    display: 'inline-block',
    width: "500px",
    margin: "0 20px"
  },
  line: {
    display: 'flex',
    margin: '5px 0',
    position: 'relative'
  },
  switch: {
    marginTop: '10px'
  },
  footerDialog: {
    display: "flex",
    "flex-direction": "row-reverse",
    margin: "10px"
  },
  btn: {
    margin: "10px"
  },
  textarea: {
    width: '100%',
    'min-height': '90px',
    margin: '25px 7px 0',
  },
  label: {
    position: 'absolute',
    top: '5px',
    left: '7px',
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '13px',
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial','Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol','Noto Color Emoji'",
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em'
  }
});

export default formStyle;