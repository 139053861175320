// import React from "react";
import Axios from 'axios';
import { ApiException } from '../dto';
import { EHttpHeaders, EHttpStatus, EMediaType } from '../constants';
import qs from "qs";
export class AxiosHttpClient {
    constructor(config) {
        this.qsMediaTypes = [EMediaType.APPLICATION_FORM_URLENCODED];
        const { baseurl = '', options = {}, interceptors = {} } = config;
        this.baseUrl = baseurl;
        this.options = options;
        if (interceptors) {
            this.interceptors = interceptors;
        }
        else {
            this.interceptors = {};
        }
        this.instance = Axios.create({
            baseURL: baseurl,
            ...options
        });
    }
    fixUrlPathName(pathName) {
        const output = pathName.replace("//", "/");
        if (!pathName.includes("//")) {
            return output;
        }
        else {
            return this.fixUrlPathName(output);
        }
    }
    fixUrl(url) {
        const fixUrl = new URL(url);
        const { origin, pathname } = fixUrl;
        const newUrl = origin + this.fixUrlPathName(pathname);
        return newUrl;
    }
    handerError(error) {
        if (!error) {
            return new ApiException("Unknown", EHttpStatus.INTERNAL_SERVER_ERROR);
        }
        if (!error.isAxiosError) {
            if (error instanceof ApiException) {
                return error;
            }
            if (error.message) {
                return new ApiException(error.message, EHttpStatus.INTERNAL_SERVER_ERROR);
            }
            return new ApiException("Unknown", EHttpStatus.INTERNAL_SERVER_ERROR);
        }
        let { response, message = "Unknown" } = error;
        let type = "DEFAULT";
        let businessCode = -1;
        if (response) {
            const { data = {}, status = EHttpStatus.INTERNAL_SERVER_ERROR } = response;
            if (data.message) {
                message = data.message;
            }
            if (data.type) {
                type = data.type;
            }
            if (data.businessCode) {
                businessCode = data.businessCode;
            }
            return new ApiException(message, status, data, type, businessCode);
        }
        return new ApiException(message, EHttpStatus.INTERNAL_SERVER_ERROR);
    }
    async intercept() {
        const headerAppend = await Promise.allObject(this.interceptors);
        const { headers = {} } = this.options;
        return {
            ...headers,
            ...headerAppend
        };
    }
    async get(endpoint, params = {}, headerEnpoint = {}) {
        try {
            const staticHeaders = await this.intercept();
            const headers = Object.assign({}, staticHeaders, headerEnpoint);
            const paramUrls = new URLSearchParams(params).toString();
            const url = (endpoint + "?" + paramUrls).trim();
            return await this.instance.get(url, { headers });
        }
        catch (error) {
            throw this.handerError(error);
        }
    }
    async getByBody(endpoint, body = {}) {
        try {
            const headers = await this.intercept();
            const contentType = headers[EHttpHeaders.CONTENT_TYPE];
            if (!contentType) {
                throw new ApiException("Empty media type", EHttpStatus.UNSUPPORTED_MEDIA_TYPE);
            }
            if (this.qsMediaTypes.includes(contentType)) {
                const url = (endpoint).trim();
                return await this.instance.get(url, {
                    headers,
                    data: qs.stringify(body)
                });
            }
            return await this.instance.get(endpoint, { headers, data: body });
        }
        catch (error) {
            throw this.handerError(error);
        }
    }
    async post(endpoint, body = {}, headerEnpoint = {}) {
        try {
            const staticHeaders = await this.intercept();
            const headers = Object.assign({}, staticHeaders, headerEnpoint);
            const contentType = headers[EHttpHeaders.CONTENT_TYPE];
            if (!contentType) {
                throw new ApiException("Empty media type", EHttpStatus.UNSUPPORTED_MEDIA_TYPE);
            }
            if (this.qsMediaTypes.includes(contentType)) {
                const url = (endpoint).trim();
                return await this.instance.post(url, qs.stringify(body), {
                    headers
                });
            }
            const url = (endpoint).trim();
            return await this.instance.post(url, body, {
                headers
            });
        }
        catch (error) {
            throw this.handerError(error);
        }
    }
    async postParams(endpoint, params = {}, headerEnpoint = {}) {
        try {
            const staticHeaders = await this.intercept();
            const headers = Object.assign({}, staticHeaders, headerEnpoint);
            const paramUrls = new URLSearchParams(params).toString();
            const url = (endpoint + "?" + paramUrls).trim();
            return await this.instance.post(url, { headers });
        }
        catch (error) {
            throw this.handerError(error);
        }
    }
    async put(endpoint, body = {}) {
        try {
            const headers = await this.intercept();
            const contentType = headers[EHttpHeaders.CONTENT_TYPE];
            if (!contentType) {
                throw new ApiException("Empty media type", EHttpStatus.UNSUPPORTED_MEDIA_TYPE);
            }
            if (this.qsMediaTypes.includes(contentType)) {
                const url = (endpoint).trim();
                return await this.instance.put(url, qs.stringify(body), {
                    headers
                });
            }
            const url = (endpoint).trim();
            return await this.instance.put(url, body, {
                headers
            });
        }
        catch (error) {
            throw this.handerError(error);
        }
    }
    async patch(endpoint, body = {}) {
        try {
            const headers = await this.intercept();
            const contentType = headers[EHttpHeaders.CONTENT_TYPE];
            if (!contentType) {
                throw new ApiException("Empty media type", EHttpStatus.UNSUPPORTED_MEDIA_TYPE);
            }
            if (this.qsMediaTypes.includes(contentType)) {
                const url = (endpoint).trim();
                return await this.instance.patch(url, qs.stringify(body), {
                    headers
                });
            }
            const url = (endpoint).trim();
            return await this.instance.patch(url, body, {
                headers
            });
        }
        catch (error) {
            throw this.handerError(error);
        }
    }
    async delete(endpoint, body = {}) {
        try {
            const headers = await this.intercept();
            const url = (endpoint).trim();
            const contentType = headers[EHttpHeaders.CONTENT_TYPE];
            if (!contentType) {
                throw new ApiException("Empty media type", EHttpStatus.UNSUPPORTED_MEDIA_TYPE);
            }
            if (this.qsMediaTypes.includes(contentType)) {
                const url = (endpoint).trim();
                return await this.instance.delete(url, {
                    headers,
                    data: qs.stringify(body)
                });
            }
            return await this.instance.delete(url, {
                headers,
                data: body
            });
        }
        catch (error) {
            throw this.handerError(error);
        }
    }
    async uploadFile(endpoint, formData, onUploadProgress) {
        try {
            const headers = await this.intercept();
            Object.assign(headers, {
                [EHttpHeaders.CONTENT_TYPE]: EMediaType.MULTIPART_FORM_DATA
            });
            const url = (endpoint).trim();
            return await this.instance.post(url, formData, {
                headers,
                onUploadProgress
            });
        }
        catch (error) {
            throw this.handerError(error);
        }
    }

    async postFormData(endpoint, formData, onUploadProgress) {
        try {
            const headers = await this.intercept();
            Object.assign(headers, {
                [EHttpHeaders.CONTENT_TYPE]: EMediaType.APPLICATION_FORM_URLENCODED
            });
            const url = (endpoint).trim();
            return await this.instance.post(url, formData, {
                headers,
                onUploadProgress
            });
        }
        catch (error) {
            throw this.handerError(error);
        }
    }

    async downloadMergeFile(endpoint, formData, onUploadProgress) {
        var bodyFormData = new FormData();
        var download_type = formData.download_type != null ? formData.download_type : 1;
        bodyFormData.append("list_event", formData.list_event);
        bodyFormData.append("download_type", download_type);
        try {
            const headers = await this.intercept();
            Object.assign(headers, {
                [EHttpHeaders.CONTENT_TYPE]: EMediaType.APPLICATION_FORM_URLENCODED
            });
            const url = (endpoint).trim();
            return await this.instance.post(url, bodyFormData, {
                headers,
            });
        }
        catch (error) {
            throw this.handerError(error);
        }
    }
}
