import { makeAutoObservable } from "mobx"
import { KeyLocalStore } from "~/common/constants";
import authService from "~/services/auth.service";
import { toastService } from "~/services";




class AuthStore {
  constructor() {
    makeAutoObservable(this);
  }
  isFirstLoading = true;
  isLoading = false;
  userSessionDto;
  logged = false;


  setLogged = (logged = false) => {
    this.logged = logged;
  }

  verifyUser = async () => {
    try {

      this.isFirstLoading = true;
      const accessToken = localStorage.getItem(KeyLocalStore.accessToken);
      if (!accessToken) {
        this.logged = false;
        this.isFirstLoading = false;
      } else {
        const userSessionDto = await authService.verifyUser();
        this.userSessionDto = userSessionDto;
        localStorage.setItem(KeyLocalStore.accessToken, userSessionDto.accessToken);
        // localStorage.setItem(KeyLocalStore.refreshToken, userSessionDto.refreshToken);
        localStorage.setItem(KeyLocalStore.userSession, JSON.stringify(userSessionDto))
        this.logged = true;
        this.isFirstLoading = false;
      }
    } catch (error) {
      this.logged = false;
      this.isFirstLoading = false;
      toastService.handleError(error);
    }
  }



  logout = () => {
    console.log('----------logout---------');
    console.log();
    console.log('-------------------');
    localStorage.clear();
    this.logged = false;
  }

}

export const authStore = new AuthStore()