import { makeAutoObservable, toJS } from "mobx";
import { GroupRoleListReq, UserListReq } from "~/dto";
import { toastService } from "~/services";
import groupRoleService from "~/services/group-role.service";


class GroupRoleListStore {
  constructor() {
    makeAutoObservable(this);
  }
  isLoading = false;
  data = [];
  total = 0;
  req = new GroupRoleListReq();
  loadData = async () => {
    try {
      this.isLoading = true;
      const { pageIndex, pageSize, filter } = this.req;
      let filters = "";
      if (filter.isActive) {
        filters = `gr.ACTIVE=${filter.isActive}`
      }
      if (filter.keyword) {
        filters = filters ? `${filters} and (REGEXP_CONTAINS(LOWER(gr.GROUP_ROLE_NAME),LOWER('${filter.keyword}')) or REGEXP_CONTAINS(LOWER(gr.GROUP_ROLE_CODE),LOWER('${filter.keyword}')))`
          : `REGEXP_CONTAINS(LOWER(gr.GROUP_ROLE_NAME),LOWER('${filter.keyword}')) or REGEXP_CONTAINS(LOWER(gr.GROUP_ROLE_CODE),LOWER('${filter.keyword}'))`
      }
      const res = await groupRoleService.list({
        pageIndex,
        pageSize,
        filters
      })
      this.data = res.data;
      this.total = res.total;
      this.isLoading = false;
    } catch (error) {
      toastService.handleError(error);
      this.isLoading = false;
    }
  }



  deleteGroupRole = async (item, index) => {
    try {
      this.isLoading = true;
      const { id } = item;
      if (id) {
        const res = await groupRoleService.delete({ id });
      }
      this.data = this.data.filter(v => v.id !== id);

      this.total = this.total - 1;
      this.isLoading = false;
      toastService.success();
    } catch (error) {
      this.isLoading = false;
      toastService.handleError(error);
    }
  }
}

export const groupRoleListStore = new GroupRoleListStore()