export const LANGUAGE_RESOURCE = {
  vi: {
    "[LoginView]Login": "Đăng nhập",
    "[LoginView]Username": "Tên đăng nhập",
    "[LoginView]Password": "Mật khẩu",
    "[LoginView]Logout": "Đăng xuất",
    "[LoginView]Please input your username!": "Vui lòng nhập tên tài khoản",
    "[LoginView]Please input your password!": "Vui lòng nhập mật khẩu",


    "[MainMenu]Home": "Trang chủ",
    "[MainMenu]List report": "Danh mục báo cáo",
    "[MainMenu]Management report": "Báo cáo quản trị",
    "[MainMenu]Input data": "Dữ liệu đầu vào",
    "[MainMenu]Setting": "Cấu hình",
    "[MainMenu]Group user": "Nhóm người dùng",
    "[MainMenu]User": "Người dùng",
    "[Common]Status": "Trạng thái",


    "[CommonError]Unknown": "Có lỗi xảy ra",
    "[ServerError]missing file upload": "Không đủ file đầu vào",
    "[Common]Upload": "Tải lên",
    "[Common]Download": "Tải xuống",
    "[Common]updatedDate": "Ngày cập nhật",
    "[Common]updatedBy": "Người cập nhật",
    "[Common]templateCode": "Mẫu báo cáo",
    "[Common]Action": "Hành động",
    "[Common]Search": "Tìm kiếm",
    "[Common]View history": "Xem lịch sử",
    "[Common]Please select file upload": "Vui lòng chọn file tải lên",

    "[Common]Updated date": "Ngày cập nhật",

    "[ManagementReportView]Download file result": "Tải xuống file kết quả",
    "[ManagementReportView]List file invalid": "Danh sách file không hợp lệ",
    "[ManagementReportView]Upload success": "Tải lên thành công",
    "[ManagementReportView]Click the button below to download": "Ấn vào nút bên dưới để tải về kết quả",
    "[InputDataView]History update": "Lịch sử cập nhật",

    "[Button]Search": "Tìm kiếm",
    "[CommonAction]Add": "Thêm",
    "[GroupRoleView]Status": "Trạng thái",
    "[GroupRoleView]FilterGroup": "Nhóm phân quyền",
    "[GroupRoleView]FilterGroupPlaceHolder": "Nhập têm nhóm phân quyền",
    "[GroupRoleView]FilterStatusPlaceHolder": "Chọn trạng thái",
    "[GroupRoleView]Group": "Tên nhóm",
    "[GroupRoleDetail]Add report and item": "Thêm báo cáo và chỉ tiêu mới",
    "[GroupRoleDetail]Add total item": "Thêm tất cả chỉ tiêu",
    "[GroupRoleDetail]Add all report": "Thêm tất cả báo cáo",


    // Userconfif detail
    "[UserConfigDetail]Company code": "Mã công ty",
    "[UserConfigDetail]Input company code": "Nhập mã công ty",
    "[UserConfigDetail]Employee code": "Mã nhân viên",
    "[UserConfigDetail]Input employee code": "Nhập mã nhân viên",
    "[UserConfigDetail]Full name": "Họ và tên",
    "[UserConfigDetail]Input full name": "Nhập họ tên",
    "[UserConfigDetail]Job title": "Chức danh",
    "[UserConfigDetail]Input email": "Nhập email",
    "[UserConfigDetail]Input login name": "Tài khoản đăng nhập",
    "[GroupRoleDetail]Form template": "Biểu mẫu",
    "[GroupRoleDetail]Form template upload": "Biểu mẫu upload",


    "[ManagementReport]List report": "Danh mục chi tiết",

    "[FormReportView]Segment": "Cơ sở",



    "[FormReportView]Data type": "Loại dữ liệu",

    "[FormReportView]Select data type": "Chọn loại dữ liệu",

    "[FormReportView]Duplicate file": "Phát hiện trùng lặp",
    "[FormReportView]Duplicate template detected": "Có template trùng lặp tải lên trước đó",
    "[FormReportView]Template name": "Tên biểu mẫu",

    "[FormReportView]File not found, please download file to edit": "File lỗi,vui lòng tải file lỗi xuống để chỉnh sửa",


    "[FormReportView]Form report": "Biểu mẫu",
    "[FormReportView]Form report placeholder name": "Tìm biểu mẫu",
    "[FormReportView]Download form report": "Tải biểu mẫu",
    "[FormReportView]User update": "Người cập nhật",
    "[FormReportView]Reconcile": "Dữ liệu đã đối soát",
    "[FormReportView]Unreconcile": "Dữ liệu chưa đối soát",
    "[FormReportView]Select template": "Chọn biểu mẫu",
    "[FormReportView]Reporting period": "Kỳ báo cáo",

    "[FormReportView]Select reprot period": "Chọn kì báo cáo",

    "[FormReportView]History update": "Lịch sử cập nhật",
    "[FormReportView]List form report": "Danh sách biểu mẫu",
    "[FormReportView]Check & upload": "Kiểm tra & tải lên",
    "[FormReportView]Please select template": "Vui lòng chọn biểu mẫu",

    "[FormReportView]Please select data type": "Vui lòng chọn loại dữ liệu",
    "[FormReportView]Please select report period": "Vui lòng chọn kỳ báo cáo",

    "[UserConfigDetail]User group": "Nhóm người dùng",

    "[Common]Success": "Thành công",
    "[Common]Fail": "Thất bại",
    "[Securebreadcrumb]Home": "Trang chủ",
    "[Securebreadcrumb]Management report": "Báo cáo quản trị",
    "[Securebreadcrumb]Form report": "Biểu mẫu",
    "[Securebreadcrumb]Group role": "Nhóm người dùng",
    "[Securebreadcrumb]User config": "Người dùng",


    "UserConfigDetail]Please input department": "Vui lòng chọn mã bộ phận",

    "[UserConfigDetail]Department": "Bộ phận",

    "[UserConfigDetail]Root account": "Tài khoản quản trị",
    "[UserConfigDetail]Normal account": "Tài khoản thường",
    "[UserConfigDetail]Account type": "Loại tài khoản",

  },
  en: {
    "Login": "Login",
    "Username": "Username",
    "Password": "Password",
  }
};
