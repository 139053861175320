export var ETemplateCode;
(function (ETemplateCode) {
    ETemplateCode["ASSUMPTIONS"] = "ASSUMPTIONS";
    ETemplateCode["BS"] = "BS";
    ETemplateCode["COMPS"] = "COMPS";
    ETemplateCode["DIST_CAPEX"] = "DIST_CAPEX";
    ETemplateCode["PL"] = "PL";
    ETemplateCode["VF_FIN"] = "VF_FIN";
    ETemplateCode["VF_TRANS"] = "VF_TRANS";
    ETemplateCode["VF_FINAL_OUTPUT"] = "VF_FINAL_OUTPUT";
})(ETemplateCode || (ETemplateCode = {}));
