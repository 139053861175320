const enhancedTableToolbarStyle = (theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    justifyContent: "space-between"
  },
  btnToolbar: {
    textTransform: "none",
    width: "170px",
    margin: "0 10px",
    padding: "5px 0"
  },
  pr5: {
    paddingRight: "5px" 
  }
});

export default enhancedTableToolbarStyle;