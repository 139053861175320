import './index.less';
import React, { useState } from 'react';
import { Breadcrumb } from 'antd';
import { useHistory, useLocation } from 'react-router';
import { languageStore } from '~/stores/language';

import {
  HomeOutlined,
  UnorderedListOutlined,
  FileDoneOutlined,
  SettingOutlined
} from '@ant-design/icons';


const Securebreadcrumb = (props) => {
  const location = useLocation();
  const history = useHistory();

  const { translate } = languageStore;

  const breadcrumbNames = {
    "secure": {
      title: translate("[Securebreadcrumb]Home"),
      icon: HomeOutlined
    },
    "management-report": {
      title: translate("[Securebreadcrumb]Management report"),
      icon: FileDoneOutlined
    },
    "form-report": {
      title: translate("[Securebreadcrumb]Form report"),
      icon: FileDoneOutlined
    },
    "group-role": {
      title: translate("[Securebreadcrumb]Group role"),
      icon: FileDoneOutlined
    },
    "user-config": {
      title: translate("[Securebreadcrumb]User config"),
      icon: FileDoneOutlined
    },
    // "target-config": {
    //   title: translate("Chỉ tiêu"),
    //   icon: FileDoneOutlined
    // },
    "data-reconciliation": {
      title: translate("Đối soát dữ liệu"),
      icon: FileDoneOutlined
    },
    "update-master-data-report-item": {
      title: translate("Chỉ tiêu & báo cáo"),
      icon: FileDoneOutlined
    }

  }

  const { pathname } = location;

  return (
    <Breadcrumb style={{ margin: '16px 0' }}>
      {pathname.split("/").filter(v => v && breadcrumbNames[v.toLowerCase()]).map((key, idx) => {
        const item = breadcrumbNames[key.toLowerCase()];
        const { icon: IconCpn, title } = item;
        return (
          <Breadcrumb.Item key={key + idx + ""}>

            <span> {title}</span>
          </Breadcrumb.Item>
        )
      })}

    </Breadcrumb>
  )
}

export default Securebreadcrumb;