import React, { useEffect, useCallback } from 'react';
import { Form, Button, Spin } from "antd";

import { useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";
import "./../index.less";
import { withMobx } from '~/common/hoc';
import { languageStore } from '~/stores/language';
import { useHistory } from 'react-router-dom';
import logo from "~/assets/logo/login_logo.png";
import logoHms from "~/assets/logo/logoVinpearl.png";
import { authStore } from '~/stores/auth';
import { loginRequest } from '~/authConfig';
import { KeyLocalStore } from '~/common/constants';
import { layoutConfigStore } from '~/stores/master-data';
const LoginView = () => {
  const history = useHistory();
  const { logged, isLoading, verifyUser } = authStore;
  const { loadMenu } = layoutConfigStore;
  const { instance, inProgress } = useMsal();


  const loadDataResource = useCallback(
    async () => {
      await verifyUser();
      await loadMenu();
    },
    [loadMenu, verifyUser],
  )
  const loginAD = async () => {
    await instance.loginRedirect(loginRequest);
  }


  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      const account = instance.getActiveAccount();
      const accessTokenRequest = {
        scopes: ["openid"],
        account
      }
      instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
        let accessToken = accessTokenResponse.accessToken;
        localStorage.setItem(KeyLocalStore.accessToken, accessToken);
        // loadDataResource();
        authStore.setLogged(true);
      }).catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(accessTokenRequest);
        }
        authStore.logged = false;

        console.log(error);
      })
    }
  }, [inProgress, instance]);

  useEffect(() => {
    if (logged) {
      history.push("/secure")
    }
  }, [logged])

  // useEffect(() => {

  //   if (logged) {
  //     history.push('/secure/dashboard')
  //   }
  // }, [history, logged])



  return (
    <div className="login-container">
      <Form className="content" size="large" layout="vertical" >
        <div className="title">
          <img alt="logo" src={logo} />
        </div>
        <Spin spinning={isLoading}>

          <Form.Item>
            <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
              <Button
                onClick={loginAD}
                style={{ width: "100%" }}
                type="primary"
                className="form-button">Đăng nhập</Button>
            </div>
          </Form.Item>
          <Form.Item>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img alt="logoHms" src={logoHms}  style={{width:70}}/>
              {/* <Link to="/">{translate("[LoginView]Login instructions")}</Link> */}
            </div>
          </Form.Item>
        </Spin>
      </Form>
    </div>
  );
};
export default withMobx(LoginView);
