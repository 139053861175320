import { makeAutoObservable, toJS } from "mobx";
import { GroupRoleDto } from "~/dto";
import { toastService } from "~/services";
import groupRoleService from "~/services/group-role.service";
import itemService from "~/services/item.service";
import reportService from "~/services/report.service";




class GroupRoleDetailStore {

  constructor() {
    makeAutoObservable(this);
  }
  isLoadingView = false;
  type = "ADD";
  groupRoleDto = new GroupRoleDto();

  itemCodes = [];
  reportCodeSelecteds = [];
  isLoading = false;


  assignReports = [];
  reports = [];
  assignItems = [];

  allItems = [];


  addAllReport = false;

  changeAddAllReport = (event) => {
    const { checked = false } = event.target
    this.addAllReport = checked;
  }


  isModalVisible = false;
  showModal = () => {
    this.selectedRowKeys = [];
    this.isModalVisible = true;
  };

  handleModalCancel = () => {
    this.reportCodeSelecteds = [];
    this.isModalVisible = false;
  };

  handleModalOk = (listItemAdd = []) => {

    if (this.reportCodeSelecteds.length > 0) {
      const checkArr = this.assignReports.filter(v => this.reportCodeSelecteds.includes(v.reportCode)).map(t => t.reportCode) || [];

      if (checkArr.length > 0) {
        const listItemFind = this.reports.filter(v => this.reportCodeSelecteds.includes(v.reportCode) && !checkArr.includes(v.reportCode)) || []
        this.assignReports = [...listItemFind, ...this.assignReports]
      } else {
        const listItemFind = this.reports.filter(v => this.reportCodeSelecteds.includes(v.reportCode)) || []
        this.assignReports = [...listItemFind, ...this.assignReports]
      }
      // if (!this.assignReports.find(v => v.reportCode === this.reportCodeSelecteds)) {
      //   const itemFind = this.reports.find(v => v.reportCode === this.reportCodeSelecteds)
      //   if (itemFind) {
      //     this.assignReports = [itemFind, ...this.assignReports]
      //   }
      // }
    }
    const reportItems = this.assignItems.reduce((result, it) => {
      result = result.concat(it.items);
      return result;
    }, [])
    let uniqReprotItems = Object.values(reportItems.byMap(["itemGroupCode", "reportCode", "itemCode"]))
    uniqReprotItems = uniqReprotItems.concat(listItemAdd);
    const mapData = uniqReprotItems.byMapArrayJsonKey(["itemGroupCode", "itemGroupName"])
    const res = Object.keys(mapData).map(keyJson => {
      const itemGroup = JSON.parse(keyJson);
      const items = mapData[keyJson];
      return {
        ...itemGroup,
        items
      }
    })
    this.assignItems = res;
    this.handleModalCancel();
  };


  listReportByGroupRole = async () => {
    this.isLoading = true;
    const { groupRoleCode = "" } = this.groupRoleDto;
    try {
      const res = await groupRoleService.listReportByGroupRole({
        groupRoleCode
      })
      this.assignReports = res;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.assignReports = [];
      toastService.handleError(error);
    }
  }
  listReport = async () => {
    try {
      const res = await reportService.list()
      this.reports = res;
    } catch (error) {
      toastService.handleError(error);
      this.reports = []
    }
  }

  listItemAssignByGroupRole = async () => {
    try {
      const { groupRoleCode = "" } = this.groupRoleDto;
      const res = await groupRoleService.listItemAssignByGroupRole({ groupRoleCode })
      this.assignItems = res;
    } catch (error) {
      this.assignItems = [];
      toastService.handleError(error);
    }
  }

  deleteReportByGroupRole = async (reportCode) => {
    this.isLoading = true;
    const { groupRoleCode = "" } = this.groupRoleDto;
    try {
      await groupRoleService.deleteReportByGroupRole({
        groupRoleCode,
        reportCode
      })
      this.assignReports = this.assignReports.filter(v => v.reportCode !== reportCode);
      // this.data.splice(index, 1);

      await this.listItemAssignByGroupRole();
      this.isLoading = false;
      toastService.success();
    } catch (error) {
      this.isLoading = false;
      toastService.handleError(error);
    }
  }

  listAllItem = async () => {
    try {
      const res = await itemService.list()
      this.allItems = res;
    } catch (error) {
      toastService.handleError(error);
    }
  }

  get itemSeleteds() {
    const uniqReprotItems = Object.values(this.allItems.filter(v => this.reportCodeSelecteds.includes(v.reportCode)).byMap(["itemCode", "reportCode"]))
    return uniqReprotItems.sort((x, y) => x.itemCode.localeCompare(y.itemCode))
  }


  onChangeSelectItem = (itemCode, record, idx) => {
    record.itemCode = itemCode;
    this.assignItems = [...this.assignItems];
  }


  getListBySelected = ({ itemGroupCode }) => {
    const reportCodes = Array.from(new Set(this.assignReports.map(v => v.reportCode)))
    const data = this.allItems.filter(v => v.itemGroupCode === itemGroupCode && reportCodes.includes(v.reportCode))
    return data;
  }

  addReporToGroupRole = async () => {
    this.isLoadingView = true;
    const { groupRoleCode = "", groupRoleName, description, templateCodes = [] } = this.groupRoleDto;
    const reportItems = this.assignItems.reduce((result, it) => {
      result = result.concat(it.items);
      return result;
    }, [])
    try {
      await groupRoleService.addReporToGroupRole({
        groupRoleCode, groupRoleName, description,
        reportItems,
        templateCodes,
        addAllReport: this.addAllReport
      })
      await this.listItemAssignByGroupRole();
      this.isLoadingView = false;
      toastService.success();
    } catch (error) {
      this.isLoadingView = false;
      toastService.handleError(error);
    }
  }

  selectedRowKeys = [];

  onSelectTableChange = (selected) => {
    console.log('selectedRowKeys changed: ', selected);
    this.selectedRowKeys = selected;
  };


}

export const groupRoleDetailStore = new GroupRoleDetailStore()