String.prototype.removeAccents = function () {
    return String.removeAccents(this);
};
String.prototype.createInternalString = function () {
    return String.createInternalString(this);
};
String.prototype.camelCaseToSnakeCase = function () {
    return String.camelCaseToSnakeCase(this);
};
String.prototype.snakeCaseToCamelCase = function () {
    return String.snakeCaseToCamelCase(this);
};
String.prototype.upperCaseFirstLetter = function () {
    return String.upperCaseFirstLetter(this);
};
String.prototype.toCamelCase = function () {
    return String.toCamelCase(this);
};
String.prototype.removeNonWord = function () {
    return String.removeNonWord(this);
};
String.prototype.unCamelCase = function () {
    return String.unCamelCase(this);
};
String.prototype.toProperCase = function () {
    return String.toProperCase(this);
};
String.prototype.toPascalCase = function () {
    return String.toPascalCase(this);
};
String.prototype.removeNonASCII = function () {
    return String.removeNonASCII(this);
};
String.prototype.stripHtmlTags = function () {
    return String.stripHtmlTags(this);
};
export {};
