import { rootApiService } from "./@global";
const Enpoint = {
  list: "/portal/api/business/group-role/list",
  changeStatus: "/portal/api/business/group-role/change-status",
  delete: "/portal/api/business/group-role/delete",
  listReportByGroupRole: "/portal/api/business/group-role/list-report-by-group-role",
  addReporToGroupRole: "/portal/api/business/group-role/add-reprot-to-group-role",
  listItemAssignByGroupRole: "/portal/api/business/group-role/list-item-assign-by-group-role",
  deleteReportByGroupRole: "/portal/api/business/group-role/delete-report-by-group-role",
};
export class GroupRoleService {
  async list(params) {
    return rootApiService.get(Enpoint.list, params);
  }

  changeStatus = ({ groupRoleCode, active }) => {
    return rootApiService.post(Enpoint.changeStatus, { groupRoleCode, active });

  }

  delete = ({ id }) => {
    return rootApiService.delete(Enpoint.delete, { id });
  }

  listReportByGroupRole({ groupRoleCode }) {
    return rootApiService.get(Enpoint.listReportByGroupRole, { groupRoleCode });

  }

  addReporToGroupRole({ groupRoleCode, groupRoleName, reportItems = [], description = "", templateCodes = [], addAllReport = false }) {
    return rootApiService.post(Enpoint.addReporToGroupRole, { groupRoleCode, groupRoleName, description, reportItems, templateCodes, addAllReport });
  }

  listItemAssignByGroupRole({ groupRoleCode }) {
    return rootApiService.get(Enpoint.listItemAssignByGroupRole, { groupRoleCode });
  }

  deleteReportByGroupRole = ({ groupRoleCode, reportCode }) => {
    return rootApiService.delete(Enpoint.deleteReportByGroupRole, { groupRoleCode, reportCode });
  }
}
export default new GroupRoleService();
