import "./index.less";
import React, { useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { Layout, Row, Col, Divider } from 'antd';

import DoubleArrow from '@material-ui/icons/DoubleArrow';
import { withMobx } from "~/common/hoc";
import logo from "~/assets/img/MIS/logo.PNG";
import logoHms from "~/assets/logo/logoVinpearl.png"
import routes from "~/routes";
import SecureMenu from "./SecureMenu";
import Secureheader from "./SecureHeader";
import { layoutConfigStore } from "~/stores/master-data";
import Securebreadcrumb from "./SecureBreadcrumb";
import { authStore } from "~/stores/auth";
import { Spin } from "antd"
import { Loading3QuartersOutlined } from '@ant-design/icons';

const { Content, Sider } = Layout;
const Loading = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Spin indicator={<Loading3QuartersOutlined style={{ fontSize: 40 }} spin />} spinning={true} ></Spin>
    </div>
  )
}
const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/secure") {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/secure" to="/secure/dashboard" />
  </Switch>
);

const Secure = () => {
  const history = useHistory()

  const { collapsed, toggle, isloadedMenu } = layoutConfigStore;
  const { logged } = authStore;

  useEffect(() => {
    if (!logged) {
      history.push("/")
    }
  }, [logged])


  return (
    <Layout style={{ overflow: "hidden", height: "100vh" }}>
      <Sider collapsed={collapsed} onCollapse={toggle} width={300} style={{
        height: '100vh',
      }}>
        <div style={{ display: "flex", flexDirection: 'column', height: '100%' }}>
          <div className="logo-container" >
            <Row align="middle" justify="center" gutter={[30, 0]}>
              <Col >
                <img src={logo} alt="logo" className="image-logo" />
              </Col>
              {!collapsed && <Col >
                <div style={{ fontSize: 20, }}>MIS Web Portal</div>
              </Col>}
            </Row>

          </div>
          <div style={{ flex: 1, overflow: 'auto', }}>
            <div onClick={toggle} className="btn-collapse">
              {!collapsed ? <DoubleArrow style={{ transform: "rotate(-180deg)" }} /> : <DoubleArrow />}
            </div>
            {isloadedMenu ? <SecureMenu /> : <Loading />}
          </div>
          <Divider type="vertical" />
          <div className="menu-footer">
            <Row align="middle" justify="center">
              <Col>
                {collapsed ? <img style={{ marginTop: 5, width: 70, height: 40 }} src={logoHms} alt="logoHms" /> : <img style={{ marginTop: 5, width: 90, height: 50 }} src={logoHms} alt="logoHms" />}
              </Col>
            </Row>
          </div>
        </div>
      </Sider>
      <Layout style={{ overflowY: "scroll" }}>
        <Secureheader />
        <Content className="content-container">
          <Securebreadcrumb />
          <div className="view-container" >
            {switchRoutes}
          </div>
          <div style={{ height: 24 }} >
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default withMobx(Secure)