import { makeAutoObservable, observable } from "mobx"
import fileReportService from "~/services/file-report.service";
import { toastService } from "~/services";
import { DataAggregationReportReq } from "~/dto";
import { ApiException } from "~/@core/dto";


class DataAggregationStore {

  constructor() {
    makeAutoObservable(this);
  }
  isLoading = false;
  data = [];
  presigned_url = "";
  total = 0;
  req = new DataAggregationReportReq();
  selectedItem = undefined;
  loadData = async () => {
    this.isLoading = true;
    var request = {
      pageIndex: this.req.pageIndex,
      pageSize: this.req.pageSize
    };
    try {
      if (this.req.segment_code) {
        request.segment_code = this.req.segment_code;
      }
      if (this.req.user) {
        request.user = this.req.user;
      }
      if (this.req.templateCode) {
        request.templateCode = this.req.templateCode;
      }
      if (this.req.year) {
        request.year = this.req.year;
      }
      if (this.req.kpi_code) {
        request.kpi_code = this.req.kpi_code;
      }
      if (this.req.month) {
        request.month = this.req.month;
      }
      if (this.req.is_download) {
        request.is_download = this.req.is_download;
      }
      if (this.req.upload_type) {
        request.upload_type = this.req.upload_type;
      }

      const { data, total, presigned_url } = await fileReportService.listDataAggregationReport(request);
      this.presigned_url = presigned_url;
      this.data = data;
      this.total = total;
      if (presigned_url) {
        window.location.assign(presigned_url);
      }
    } catch (error) {
      toastService.handleError(error);
    }
    this.isLoading = false;
  }

}

export const dataAggregationStore = new DataAggregationStore();