import React, { useState, useEffect, useRef } from "react";
// react plugin for creating charts

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components


import styles from "~/assets/jss/material-dashboard-react/components/userConfigStyle.js";
import userConfigService from "~/services/user-config.service";


// @material-ui/icons
import { Popconfirm, message } from 'antd';

import { Table, Switch, Button, Divider, Form, Input, Col, Row, PageHeader, Select } from 'antd';
import IconButton from '@material-ui/core/IconButton';
import { PlusOutlined, FilterOutlined, CloseOutlined, SearchOutlined, RollbackOutlined } from '@ant-design/icons';
import { authStore } from '~/stores/auth';
import DeleteIcon from '@material-ui/icons/Delete';
import BorderColor from '@material-ui/icons/BorderColor';
import { useHistory } from "react-router-dom";
import { languageStore } from "~/stores/language";
import { userActionStore, userConfigStore } from "~/stores/user-config";
import { withMobx } from "~/hooks";
import { UserReq } from "~/dto";
import { toastService } from "~/services";

const useStyles = makeStyles(styles);

const { Option } = Select;
const UserConfig = (props) => {
  const { userSessionDto } = authStore;
  const { translate } = languageStore;
  const { isLoading, loadData, data, total, req, delete: deleteUser } = userConfigStore;
  const classes = useStyles();
  const pageSizeOptions = ['10', '20', '50', '100']
  const [openFilter, setOpenFilter] = React.useState(false);
  const [heightContent, setHeightContent] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const ref = useRef(null);
  const history = useHistory();
  const [showExpandSearch, setShowExpandSearch] = useState(false);
  const showHideAdvance = () => {
    setShowExpandSearch(!showExpandSearch);
  }
  const [filter, setFilter] = useState({
    keyword: '', isActive: ''
  });
  const handleOnChange = event => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
    req.filter = filter;
  };
  const search = () => {
    loadData();
  }
  useEffect(() => {
    req.filter = filter;
  }, [filter]);
  useEffect(() => {
    setHeightContent(ref.current.clientHeight);
  }, []);
  useEffect(() => {
    loadData()
  }, [loadData])

  if (!userSessionDto?.isMaster) {
    history.push({
      pathname: `/secure/dashboard`,
    });
  }
  const openFilterBox = (openFilter) => {
    setOpenFilter(openFilter);
  }

  const confirm = (record) => {
    console.log(record);
    
  }

  const cancel = (e)=> {
    console.log(e);
  }

  const columns = [
    {
      dataIndex: "companyCode",
      key: "companyCode",
      title: "Mã Công Ty",
      // render: (value, record, index) => {
      //     return <span>{moment(value).add('7', "hours").format('DD-MM-YYYY HH:mm:ss')}</span>;
      // }
    },
    {
      dataIndex: "employeeCode",
      key: "employeeCode",
      title: "Mã Nhân Viên"
    },
    {
      dataIndex: "fullName",
      key: "fullName",
      title: "Họ Tên"
    },
    {
      dataIndex: "jobTitleName",
      key: "jobTitleName",
      title: "Chức danh"
    },
    {
      dataIndex: "email",
      key: "email",
      title: "Email"
    },
    {
      dataIndex: "active",
      key: "active",
      title: "Trạng thái",
      render: (value, record, index) => {
        return (
          <Switch defaultChecked={Boolean(record.active)} onChange={(checked) => toggleStatus(record, checked, index)} />
        )
      }
    },
    {
      key: "action",
      title: translate("[Common]Action"),
      render: (value, record, index) => {
        return (
          <span>
            <div>
              <IconButton aria-label="filter list" onClick={handleEdit.bind(null, record)}>
                <BorderColor />
              </IconButton>
              <IconButton aria-label="filter list">
                <Popconfirm
                  title="Bạn có chắn muốn xóa item này không?"
                  onConfirm={deleteUser.bind(null, record)}
                  onCancel={cancel}
                  okText="Có"
                  cancelText="Không"
                  placement="topRight"
                >
                  <a href="#"><DeleteIcon /></a>
                </Popconfirm>
              </IconButton>
            </div>
          </span>
        )
      }
    }
  ];

  const gotoActionView = () => {
    history.push({
      pathname: `/secure/user-config/update`,
    });
  }
  const handleEdit = async (item) => {
    const userReq = new UserReq();
    userReq.companyCode = item.companyCode;
    userReq.employeeCode = item.employeeCode;
    userReq.email = item.email;
    userReq.fullName = item.fullName;
    userReq.jobTitleCode = item.jobTitleCode;
    userReq.jobTitleName = item.jobTitleName;
    userReq.groups = item.groups;
    userReq.active = item.active;
    userReq.isMaster = item.isMaster;
    userReq.department = item.department;
    userActionStore.userReq = userReq;

    userActionStore.type = "EDIT";
    gotoActionView()
  }

  const toggleStatus = async (record, checked, index) => {
    data[index].active = checked;
    userConfigStore.isLoading = true;
    try {

      await userConfigService.changeStatus({
        email: record.email,
        active: checked
      })
      userConfigStore.isLoading = false;
      toastService.success();
    } catch (error) {
      userConfigStore.isLoading = false;
      data[index].active = !checked;
      toastService.handleError(error);
    }

  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };
  const scrollY = `calc(100vh - (${heightContent}px + 300px))`;


  const onSelectChange = (selected) => {
    console.log('selectedRowKeys changed: ', selected);
    setSelectedRowKeys(selected);
    // setSelectedRowKeys(Array.from(new Set(selectedRowKeys.concat(selected))))
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: 50,
  };
  return (
    <div>
      <div ref={ref} >
        <PageHeader style={{ padding: "0px 0px 20px 0px" }} title={translate("[MainMenu]User")}
          extra={[
            <Button icon={showExpandSearch ? <CloseOutlined /> : <FilterOutlined />} onClick={showHideAdvance}>
              {showExpandSearch ? "Đóng tìm kiếm" : "Tìm kiếm nâng cao"}
            </Button>,
            <Button onClick={async () => {
              userActionStore.userReq = new UserReq();
              userActionStore.type = "ADD";
              gotoActionView();
            }} icon={<PlusOutlined />} type="primary">{translate("[CommonAction]Add")}</Button>
          ]}
        ></PageHeader>
        {showExpandSearch ? <Form layout="vertical" {...formItemLayout}>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item label="Thông tin người dùng">
                <Input placeholder="Nhập họ tên, email" name="keyword" value={filter.keyword} onKeyPress={event => {
                  if (event.key === 'Enter') {
                    loadData();
                  }
                }}
                  onChange={handleOnChange}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={translate("[GroupRoleView]Status")}>
                <Select placeholder={translate("[GroupRoleView]FilterStatusPlaceHolder")} showSearch
                  name="isActive"
                  value={filter.isActive}
                  onChange={(value) => {
                    if (value) {
                      setFilter({ ...filter, ["isActive"]: value });
                    }
                  }}
                >
                  <Option value="true">Active</Option>
                  <Option value="false">Inactive</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label=" ">
                <Button icon={<SearchOutlined />} onClick={search} type="primary">{translate("[Button]Search")}</Button>
                <Button icon={<RollbackOutlined />} onClick={() => {
                  setFilter({ keyword: '', isActive: '' });
                  req.filter = { keyword: '', isActive: '' };
                  search();
                }} style={{ marginLeft: 12 }}></Button>
              </Form.Item>
            </Col>
          </Row>
        </Form> : null}
        <div style={{ width: '100%' }} >
          <div className={classes.container}>
            <Row style={{ width: '100%', height: '100%' }}>
              <Table
                size="large" className="table-striped" scroll={{ y: scrollY }} loading={isLoading} columns={columns}
                dataSource={data}
                rowSelection={rowSelection}
                rowKey={(record, index) => record.id}
                pagination={{
                  defaultPageSize: Number(pageSizeOptions[0]),
                  defaultCurrent: 1,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  pageSizeOptions: pageSizeOptions,
                  total,
                  onShowSizeChange: (pageIndex, pageSize) => {
                    req.pageIndex = pageIndex;
                    req.pageSize = pageSize;
                    loadData();
                  },
                  onChange: async (pageIndex, pageSize) => {
                    req.pageIndex = pageIndex;
                    req.pageSize = pageSize;
                    loadData();
                  }
                }}>
              </Table>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withMobx(UserConfig);
