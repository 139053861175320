


import React, { useEffect } from 'react';
import { withMobx } from '~/common/hoc';

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { Box, Button } from "@material-ui/core";


import ListAltIcon from '@material-ui/icons/ListAlt';

import FormControl from '@material-ui/core/FormControl';

// @material-ui/icons
import Info from "@material-ui/icons/Info";
import Apartment from "@material-ui/icons/Apartment";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';


import { Spin, Row, Col, Table, Select, Divider, Modal, TreeSelect, Checkbox } from 'antd';



// core components

import { Loading3QuartersOutlined } from '@ant-design/icons';
import styles from "~/assets/jss/material-dashboard-react/components/viewDetailStyle";

import { Link, Element } from 'react-scroll'

import { useFormik } from 'formik';
import * as yup from 'yup';
import { authStore } from '~/stores/auth';
import { useHistory } from "react-router-dom";
import { languageStore } from '~/stores/language';
import { groupRoleDetailStore } from '~/stores/group-role';
import groupRoleService from '~/services/group-role.service';
import { toastService } from '~/services';
import ListItemGroup from './ListItemGroup';
import { masterDataStore } from '~/stores/master-data';

const useStyles = makeStyles(styles);

const GroupRoleDetail = () => {
  const { userSessionDto } = authStore;
  const history = useHistory();
  const classes = useStyles();

  const { SHOW_CHILD } = TreeSelect;

  const { translate } = languageStore;
  const { groupRoleDto, isLoadingView, isLoading, assignReports, reports, listReport,
    listItemAssignByGroupRole,
    deleteReportByGroupRole,
    reportCodeSelecteds,
    allItems,
    addReporToGroupRole,
    listAllItem,
    itemSeleteds,
    listReportByGroupRole, type,
    selectedRowKeys,
    onSelectTableChange,
    addAllReport,
    changeAddAllReport,
    isModalVisible, showModal, handleModalCancel, handleModalOk,

  } = groupRoleDetailStore;

  const { loadListTreeMenuFormTemplate, treeMenuTemplates } = masterDataStore;

  const formik = useFormik({
    initialValues: {
      ...groupRoleDto,
    },
    validationSchema: yup.object({
      groupRoleName: yup.string().required(`${translate("[UserConfigDetail]Please input your group role name")}!`),
      groupRoleCode: yup.string().required(`${translate("[UserConfigDetail]Please input your group role code")}!`),
    }),
    onSubmit: () => {

    },
  });
  if (!userSessionDto?.isMaster) {
    history.push({
      pathname: `/secure/dashboard`,
    });
  }

  const cancel = () => {
    history.push({
      pathname: `/secure/group-role`,
    });
  }
  
  const toggleChecked = async (event) => {
    const { name, checked } = event.target;
    if (type === "ADD") {
      formik.setFieldValue(name, checked)
      return;
    }
    formik.setFieldValue(name, checked)

    try {
      groupRoleDetailStore.isLoadingView = true;
      await groupRoleService.changeStatus({
        groupRoleCode: groupRoleDto.groupRoleCode,
        active: checked
      })
      groupRoleDetailStore.isLoadingView = false;
      toastService.success();
    } catch (error) {
      groupRoleDetailStore.isLoadingView = false;
      formik.setFieldValue(name, !checked)
      toastService.handleError(error);

    }

  }


  const formItemStyle = { margin: 8, width: "96%" };

  const columns = [
    {
      dataIndex: "reportCode",
      key: "reportCode",
      title: translate("[GroupRoleDetail]Report code"),

    },
    {
      dataIndex: "reportName",
      key: "reportName",
      title: translate("[GroupRoleDetail]Report name"),

    },

    {
      key: "action",
      title: translate("[Common]Action"),
      width: "200px",
      render: (value, record) => {
        return (
          <span>
            <div>
              <IconButton aria-label="filter list" onClick={deleteReportByGroupRole.bind(null, record.reportCode)}>
                <RemoveCircleOutlineIcon />
              </IconButton>
            </div>
          </span>
        )
      }
    }
  ];

  useEffect(() => {
    listReport();
    listReportByGroupRole();
    listItemAssignByGroupRole();
    listAllItem();
    loadListTreeMenuFormTemplate();
  }, [listReportByGroupRole, listReport, listItemAssignByGroupRole, listAllItem, loadListTreeMenuFormTemplate])

  const onChangeSelect = (reportCodes) => {

    groupRoleDetailStore.reportCodeSelecteds = reportCodes;
  }



  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectTableChange,
    columnWidth: 50
  };

  const Footer = () => {
    return (
      <Button
        startIcon={<AddIcon />}
        variant="outlined"
        color="primary"
        className={classes.margin}
        onClick={showModal}>
        {translate("[Common]Add")}
      </Button>
    )
  }
  const confirm = (num, listItemAdd) => {
    Modal.confirm({
      title: 'Confirm',
      centered: true,
      content: `Bạn chắc chắn thêm ${num} chỉ tiêu vào nhóm người dùng này chứ?`,
      okText: 'Đồng ý',
      cancelText: 'Hủy',
      onOk: () => handleModalOk(listItemAdd)
    });
  }

  const okModal = () => {
    const listItemAdd = allItems.filter(v => selectedRowKeys.includes(v.itemCode))
    confirm(selectedRowKeys.length, listItemAdd);
  }
  const addTototalItem = () => {
    confirm(itemSeleteds.length, itemSeleteds);
  }



  return (
    <div className={classes.root}>

      <Grid container>

        <Grid item xs={2} sm={2}>
          <Paper className={classes.paper}>
            <ul className={classes.navLinkContainer}>
              <li className={classes.tabs}><Link activeClass="active" to="information" spy={true} smooth={true}>Thông tin chung</Link></li>
              <li className={classes.tabs}><Link to="report" spy={true} smooth={true}>Báo cáo</Link></li>
              <li className={classes.tabs}><Link to="targetGroup" spy={true} smooth={true}>Nhóm chỉ tiêu</Link></li>
              <li className={classes.tabs}><Link to="target" spy={true} smooth={true}>Chỉ tiêu</Link></li>
              <li className={classes.tabs}><Link to="role" spy={true} smooth={true}>Phân quyền</Link></li>
            </ul>
          </Paper>
        </Grid>
        <Grid item xs={10} sm={10}>
          <Spin indicator={<Loading3QuartersOutlined style={{ fontSize: 40 }} spin />} spinning={isLoadingView} >
            <form onSubmit={formik.handleSubmit} noValidate autoComplete="off" >
              <Paper className={classes.paper}>
                <div>
                  {/* Information */}
                  <Element id="information" name="information">
                    <Box display="flex" p={1}>
                      <Box p={1} flexGrow={1} style={{ paddingTop: "14px" }}>
                        <Info style={{ verticalAlign: "middle" }} />
                        <h4 className={classes.title}>Thông Tin Chung</h4>
                      </Box>
                      <Box p={1}>
                        <FormGroup>
                          <FormControlLabel

                            control={<Switch name="active" checked={formik.values.active} onChange={toggleChecked} color="primary" />}
                            label="Trạng Thái" labelPlacement="start" name="status"
                          />
                        </FormGroup>
                      </Box>
                    </Box>


                    <Row gutter={[10, 20]}>
                      <Col span={12}>
                        <TextField
                          style={formItemStyle}
                          className={classes.margin}
                          InputLabelProps={{ shrink: true, }}
                          margin="normal"
                          name="groupRoleName"
                          label={translate("[GroupRoleDetail]Group role name")}
                          required
                          placeholder={translate("[UserConfigDetail]Input group role name")}
                          value={formik.values.groupRoleName}
                          onChange={formik.handleChange}
                          error={formik.touched.groupRoleName && Boolean(formik.errors.groupRoleName)}
                          helperText={formik.touched.groupRoleName && formik.errors.groupRoleName}
                        />
                      </Col>
                      <Col span={12}>
                        <TextField
                          style={formItemStyle}
                          className={classes.margin}
                          InputLabelProps={{ shrink: true, }}
                          margin="normal"
                          name="groupRoleCode"
                          label={translate("[GroupRoleDetail]Group role code")}
                          required
                          placeholder={translate("[UserConfigDetail]Input group role code")}
                          value={formik.values.groupRoleCode}
                          onChange={formik.handleChange}
                          error={formik.touched.groupRoleCode && Boolean(formik.errors.groupRoleCode)}
                          helperText={formik.touched.groupRoleCode && formik.errors.groupRoleCode}

                        />

                      </Col>
                      <Col span={24}>
                        <InputLabel
                          style={{ margin: 8 }}
                          error={formik.touched.description && Boolean(formik.errors.description)}
                          shrink id="description">
                          {translate("[GroupRoleDetail]Description")}
                        </InputLabel>
                        <TextareaAutosize
                          name="description"
                          placeholder={translate("[UserConfigDetail]Input description")}
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          error={formik.touched.description && Boolean(formik.errors.description)}
                          helperText={formik.touched.description && formik.errors.description}
                          style={{ marginLeft: 8, width: '98%' }}
                          minRows={5}
                          aria-label={translate("[UserConfigDetail]Input description")}
                        />
                      </Col>
                      <Col span={24}>
                        <FormControl className={classes.formControl} style={{ width: "98%" }} >
                          <Box p={1} flexGrow={1} style={{ paddingTop: "14px" }}>
                            <ListAltIcon style={{ verticalAlign: "middle" }} />
                            <h4 className={classes.title}>{translate("[GroupRoleDetail]Form template upload")}</h4>
                          </Box>
                          <TreeSelect
                            style={{ width: '100%' }}
                            treeCheckable={true}
                            multiple={true}

                            filterTreeNode={(search, item) => {
                              return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                            }}

                            showCheckedStrategy={SHOW_CHILD}
                            value={groupRoleDto.templateCodes}
                            onChange={(values, lableList, extra) => {
                              const { checked, triggerValue = "" } = extra;
                              if (!checked) {
                                groupRoleDto.templateCodes = groupRoleDto.templateCodes.filter(v => v !== triggerValue)
                              } else {
                                groupRoleDto.templateCodes = values;
                              }

                            }}
                            treeData={treeMenuTemplates}
                          />
                        </FormControl>
                      </Col>
                    </Row>


                  </Element>

                  {/* Role */}
                  <Element id="report" name="report">
                    <Row justify="space-between">
                      <Col>
                        <Box display="flex" p={1}>
                          <Box p={1} flexGrow={1} style={{ paddingTop: "14px" }}>
                            <Apartment style={{ verticalAlign: "middle" }} />
                            <h4 className={classes.title}>Báo cáo</h4>
                          </Box>
                        </Box>
                      </Col>
                      <Col>
                        <Box display="flex" p={1} style={{ marginTop: 20 }}>
                          <Checkbox value={addAllReport} onChange={changeAddAllReport}>{translate("[GroupRoleDetail]Add all report")}</Checkbox>
                        </Box>
                      </Col>

                    </Row>
                    <Table
                      bordered
                      size="large" className="table-striped"
                      // scroll={{ y: scrollY }}
                      loading={isLoading}
                      columns={columns}
                      dataSource={assignReports}
                      rowKey={(record, index) => index + record.itemCode}

                      footer={Footer}
                    >
                    </Table>
                    <Modal
                      bodyStyle={{ overflowX: 'scroll' }}
                      width={'80vw'}
                      title={<Box display="flex" p={1}>
                        <h6 className={classes.title}>{translate("[GroupRoleDetail]Add report and item")}</h6>
                      </Box>}
                      visible={isModalVisible}
                      okText={translate("[CommonAction]Add")}
                      onOk={okModal} onCancel={handleModalCancel}>
                      <h6>Chọn báo cáo</h6>
                      <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        onChange={onChangeSelect}
                        defaultValue={reportCodeSelecteds}
                        value={reportCodeSelecteds}
                        placeholder={translate("[GroupRoleDetail]Please select report")}>
                        {reports.map(item =>
                          <Select.Option key={JSON.stringify(item)} value={item.reportCode}>{item.reportCode} - {item.reportName}</Select.Option>
                        )}
                      </Select>
                      <Divider type='vertical' />
                      <Box display="flex" p={1}>
                        <Box p={1} flexGrow={1} style={{ paddingTop: "14px" }}>
                          <PlaylistAddCheckIcon style={{ verticalAlign: "middle" }} />
                          <h6 className={classes.title}>Chỉ tiêu</h6>
                        </Box>
                        <Button
                          onClick={addTototalItem}
                          startIcon={<PlaylistAddIcon />}
                          variant="outlined" color="primary"
                          className={classes.margin}>
                          {translate("[GroupRoleDetail]Add total item")}
                        </Button>

                      </Box>

                      <Table
                        bordered
                        size="large" className="table-striped"
                        // scroll={{ y: scrollY }}
                        pagination={{
                          defaultPageSize: 5,
                          pageSizeOptions: ['5', '10']
                        }}
                        columns={[
                          {
                            dataIndex: "itemCode",
                            key: "itemCode",
                            title: translate("[GroupRoleDetail]Item code"),
                          },
                          {
                            dataIndex: "itemName",
                            key: "itemName",
                            title: translate("[GroupRoleDetail]Item name"),
                          },
                        ]}
                        rowSelection={rowSelection}
                        dataSource={itemSeleteds}
                        rowKey={(record) => record.itemCode}
                      >
                      </Table>

                    </Modal>

                  </Element>

                  {/* Company */}
                  <Element id="targetGroup" name="targetGroup">
                    <Box display="flex" p={1}>
                      <Box p={1} flexGrow={1} style={{ paddingTop: "14px" }}>
                        <PlaylistAddCheckIcon style={{ verticalAlign: "middle" }} />
                        <h4 className={classes.title}>Chỉ tiêu</h4>
                      </Box>
                    </Box>
                    <ListItemGroup />
                  </Element>
                </div>
                {/* Submit */}
                <Box display="flex" flexDirection="row-reverse" p={1} m={1}>
                  <Box p={1}>
                    <Button variant="outlined" onClick={cancel} color="primary" className={classes.margin}>
                      Hủy
                    </Button>
                    <Button
                      onClick={() => {

                        const { templateCodes = [] } = groupRoleDto;
                        Object.assign(groupRoleDto, formik.values, { templateCodes })

                        addReporToGroupRole();
                      }}
                      variant="contained"
                      color="primary" type="submit"
                      className={classes.margin}>
                      Lưu
                    </Button>
                  </Box>
                </Box>
              </Paper>
            </form>
          </Spin>
        </Grid>

      </Grid>


    </div >
  );
}



export default withMobx(GroupRoleDetail)