import { rootApiService } from "./@global";
const Enpoint = {
  listJobTitle: "/portal/api/business/master-data/list-job-title",
  listSegmentHotel: "/portal/api/business/master-data/list-segment-hotel",
  listGroupRole: "/portal/api/business/master-data/list-group-role",
  listOrgranizationMatrix: "/portal/api/business/master-data/list-orgranization-matrix",
  saveOrgranizationMatrix: "/portal/api/business/master-data/save-orgranization-matrix",
  listGroupRoleByUser: "/portal/api/business/master-data/list-group-role-by-user",
  //  listMenuFormReport: "/portal/api/public/master-data/list-menu-form-report",

  listMenuFormReport: "/portal/api/business/menu-form/list-menu-form",
  listFormTemplate: "/portal/api/business/master-data/list-form-template",
  listTreeMenuFormTemplate: "/portal/api/business/master-data/list-tree-menu-form-template",
  listKpiFormTemplate: "/portal/api/business/kpi-template/get-list",


  listItemGroup: "/portal/api/business/master-data/list-item-group",

};
export class MasterDataService {
  async listJobTitle() {
    return rootApiService.get(Enpoint.listJobTitle);
  }
  async listSegmentHotel() {
    return rootApiService.get(Enpoint.listSegmentHotel);
  }
  async listGroupRole() {
    return rootApiService.get(Enpoint.listGroupRole);
  }

  async listGroupRoleByUser({ email }) {
    return rootApiService.get(Enpoint.listGroupRoleByUser, { email });
  }
  async listOrgranizationMatrix(pageReq) {
    return rootApiService.get(Enpoint.listOrgranizationMatrix, pageReq);
  }

  async saveOrgranizationMatrix(body) {
    return rootApiService.post(Enpoint.saveOrgranizationMatrix, body);
  }

  async listMenuFormReport() {
    return rootApiService.get(Enpoint.listMenuFormReport);
  }


  async listFormTemplate() {
    return rootApiService.get(Enpoint.listFormTemplate);
  }

  async listTreeMenuFormTemplate() {
    return rootApiService.get(Enpoint.listTreeMenuFormTemplate);
  }

  async listItemGroup() {
    return rootApiService.get(Enpoint.listItemGroup);
  }

  async listKPITemplate() {
    return rootApiService.get(Enpoint.listKpiFormTemplate);
  }


}
export default new MasterDataService();
