
import { makeObservable, observable, computed, action, flow } from "mobx"
import { LANGUAGE_RESOURCE, LOCALE_RESOURCE } from "./resources"
class LanguageStore {
    constructor() {
        makeObservable(this, {
            localeKey: observable,
            resource: computed,
            localeResource: computed,
            loadResource: action,
            changeLanguage: action,
            translate: action
        })

    }

    localeKey = "vi";

    get resource() {
        return LANGUAGE_RESOURCE[this.localeKey];
    }

    get localeResource() {
        return LOCALE_RESOURCE[this.localeKey]
    }

    loadResource = async () => {
        this.localeKey = "vi";
    }


    changeLanguage = (key) => {
        this.localeKey = key;
    }



    translate = (key) => {
        if (!key) return '';
        try {
            if (!this.resource) {
                // return key;
                return key.replace(/\[(.*?)\]/g, "");
            }
            if (!this.resource[key] && !this.resource[key.toLowerCase()] && !this.resource[key.toUpperCase()]) {
                // return key;
                return key.replace(/\[(.*?)\]/g, "");
            }
            return this.resource[key] || this.resource[key.toLowerCase()] || this.resource[key.toUpperCase()]
        } catch (error) {

            // return key;
            return key.replace(/\[(.*?)\]/g, "");
        }

    }
}

export const languageStore = new LanguageStore();