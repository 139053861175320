import { rootApiService } from "./@global";
const Enpoint = {
  list: "/portal/api/business/item/list",
};
export class ItemService {
  async list() {
    return rootApiService.get(Enpoint.list);
  }
}
export default new ItemService();
