import { withMobx } from "~/hooks"
import { Drawer, Row, Divider, Col, Spin, Tabs } from 'antd';

import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ViewListIcon from '@material-ui/icons/ViewList';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes'

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox'

// core components
import DataGridEditable from "~/components/DataGrid/DataGridEditable";

import { PlusOutlined, LoadingOutlined, Loading3QuartersOutlined } from '@ant-design/icons';
import { groupRoleDetailStore } from "~/stores/group-role";
import { toJS } from "mobx";

const { TabPane } = Tabs;

const Listitemgroup = (props) => {


  const { assignItems, allItems, getListBySelected } = groupRoleDetailStore;




  const onChangePage = () => {

  }

  const onChangeTab = () => {

  }



  return (
    <Tabs defaultActiveKey={"nameLv1Active"}
      onChange={onChangeTab}
      size="large" tabPosition={"left"}>
      {assignItems.map(assignItem => {
        return (
          <TabPane
            style={{ height: '90vh' }}
            tab={
              <span style={{ display: "flex", alignItems: "center" }}>
                <ViewListIcon />
                {assignItem.itemGroupName}
              </span>
            }

            key={assignItem.itemGroupCode}
          >
            <div style={{ height: '100%' }} key={assignItem.itemGroupCode + "table"}>
              <DataGridEditable
                key={assignItem.itemGroupCode + "table-list"}
                onRowAdd={async (item) => {
                  const { itemCode } = item;
                  const findItem = getListBySelected({ ...assignItem }).find(v => v.itemCode === itemCode);
                  assignItem.items.push(findItem);
                }}
                onRowDelete={async (item) => {
                  for (const asItem of assignItems) {
                    const idx = asItem.items.findIndex(v => v.itemCode === item.itemCode)
                    if (idx > -1) {
                      asItem.items.splice(idx, 1)
                    }
                  }
                }}
                isLoading={false}
                columns={[
                  {
                    title: 'Mã chỉ tiêu',
                    field: 'itemCode',
                    editable: 'never',
                    width: "20%"
                  },
                  {
                    title: 'Tên chỉ tiêu',
                    field: 'itemCode',
                    width: "70%",
                    lookup: getListBySelected({ ...assignItem }).reduce((result, item) => {
                      result[item.itemCode] = item.itemName;
                      return result
                    }, {}),
                    editComponent: (tableData) => {
                      return <Select value={tableData.value || ''} onChange={(e) => {
                        tableData.onChange(String(e.target.value));
                      }}>
                        {getListBySelected({ ...assignItem }).map((type) => <MenuItem key={type.itemCode} value={type.itemCode}>{`${type.itemName}`}</MenuItem>)}
                      </Select>;
                    }
                  },
                ]}
                data={assignItem.items}

                action="assignItem" />
            </div>
          </TabPane>
        )
      })}

    </Tabs>
  )
}

export default withMobx(Listitemgroup)