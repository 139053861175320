import { withMobx } from '~/common/hoc';
import { InboxOutlined, DownloadOutlined, FileExcelOutlined, FileExcelFilled } from '@ant-design/icons';
import { toastService } from '~/services';
import { languageStore } from '~/stores/language';
import Dragger from 'antd/lib/upload/Dragger';
import { Spin, Typography, Button, Row, Col, Alert, Divider, Modal, Select, Form, DatePicker, Input } from 'antd';
import { formReportStore } from '~/stores/form-report';
const { Text } = Typography;



const { Option } = Select;
const UploadModal = () => {
  const { modalUploadDto, fileList, setFileList, listTemplate,
    onChangeDate,
    uploadTemplateReq,
    handleChangeDataType,
    handleChangeTemplate,
    segmentName,
    errorFileDto,
    checkDuplicate,
    messageErrorDto,
    verifyAndUpload } = formReportStore;
  const { translate } = languageStore;

  const [form] = Form.useForm();
  const draggerProps = () => {
    return {
      name: "files",
      accept: ".xlsx, .xls",
      multiple: false,
      fileList: fileList,
      onRemove: (file) => {
        const cloneArr = fileList.filter(v => v.uid !== file.uid);
        setFileList(cloneArr);
      },
      onChange: (info) => {
        if (fileList.length > 0) {
          messageErrorDto.file = "";
          messageErrorDto.messageServer = "";
        }
        const { status } = info.file;
        if (status === "done") {
          // toastService.success(`${info.file.name} success`);
        }
        else if (status === "error") {
          toastService.error(`${info.file.name} error`);
        }
      },
      beforeUpload: (file, fileListRes) => {
        setFileList(fileListRes);
        return true;
      },
      customRequest(e) {
        e.onSuccess();
      }
    };
  };

  const getFileName = (url) => {
    const arr = url.replace("?", "/").split("/");
    const fileName = arr.find(v => v.includes("VF_FINAL_OUTPUT_"))
    return fileName;
  }



  const renderUiValidateFile = () => {
    if (errorFileDto && errorFileDto.urlDownload) {
      return (
        <Row >

          <Col span={24}>
            <div> <span style={{ color: "green" }}>{errorFileDto.success}</span>/{errorFileDto.total}{' '} <span style={{ color: 'green' }}>Chỉ tiêu thành công</span> </div>
            <div> <span style={{ color: "red" }}>{errorFileDto.errors}</span>/{errorFileDto.total}{' '} <span style={{ color: 'red' }}>Chỉ tiêu thất bại</span>  </div>
            <Divider type="vertical" />
          </Col>
          <Col span={24}>
            <Alert style={{ fontSize: 14 }}
              message={translate("[FormReportView]File not found, please download file to edit")}
              type="warning" showIcon />
            <Divider type="vertical" />
          </Col>
          <Button type="ghost" style={{ height: 60, minWidth: '50%', maxWidth: '100%' }} onClick={() => { window.location.assign(errorFileDto.urlDownload) }}>
            <FileExcelFilled style={{ color: "#00B517", fontSize: 25 }} />
            <Text style={{ fontSize: 14 }}>{errorFileDto.templateName}</Text>
            <DownloadOutlined style={{ fontSize: 25 }} />
          </Button>
        </Row>
      )
    }
    if (checkDuplicate !== 1) {
      return (
        <Dragger showUploadList={true} {...draggerProps()}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined size={5} />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <Text type="secondary">File type: .xlxs</Text>  <Text type="secondary">Maximum: 25MB</Text>
          {fileList.length === 0 && (<p>
            <Text type="secondary">
              {`Dữ liệu đầu vào`}
            </Text>
          </p>)}
        </Dragger>
      )
    }
    return (
      <Row justify="center">
        <Col span={24}>
          <Alert style={{ fontSize: 16 }}
            message={translate("[FormReportView]Duplicate file")}
            description={translate("[FormReportView]Duplicate template detected")}
            type="warning" showIcon />
          <Divider type="vertical" />
        </Col>
      </Row>
    )

  }


  const uiFileValidate = renderUiValidateFile();


  const onFinish = async (values) => {
    verifyAndUpload();
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (<>

    <Modal
      title={translate("[Common]Upload")}
      visible={modalUploadDto.visible}
      onOk={form.submit}
      onCancel={modalUploadDto.close}
      // footer={[
      //   <div>

      //     <Button>
      //       {translate("[Common]Cancel")}
      //     </Button>
      //     <Button key="submit" htmlType="submit">
      //       {translate("[Common]Cancel")}
      //     </Button>

      //   </div>
      // ]}
      okButtonProps={{ style: { display: errorFileDto && errorFileDto.urlDownload ? 'none' : '' } }}
      okText={checkDuplicate !== 1 ? translate("[FormReportView]Check & upload") : translate("[Common]Upload")}
    >
      <Spin spinning={modalUploadDto.isLoading} size="large">
        <div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row gutter={[0, 0]}>
              <Col span={24}>
                <Form.Item
                  style={{ width: '100%' }}
                  label={translate("[FormReportView]Data type")}
                  name="templateCode"
                  rules={[{ required: true, message: translate("[FormReportView]Please select data type") + "!" }]}
                >
                  <Select
                    style={{ width: '100%' }}
                    defaultValue="" onChange={handleChangeDataType}>
                    <Option value={""}>{translate("[FormReportView]Select data type")}</Option>
                    <Option value={"reconcile"}>{translate("[FormReportView]Reconcile")}</Option>
                    <Option value={"unreconcile"}>{translate("[FormReportView]Unreconcile")}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  style={{ width: '100%' }}
                  label={translate("[FormReportView]Form report")}
                  name="type"
                  rules={[{ required: true, message: translate("[FormReportView]Please select report period") + "!" }]}
                >
                  <Select
                    style={{ width: '100%' }}
                    showSearch
                    defaultValue=""
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={handleChangeTemplate}>
                    <Option value={""}>{translate("[FormReportView]Select template")}</Option>
                    {listTemplate.map((it) => {
                      return (
                        <Option key={it.templateCode} value={it.templateCode}>{it.templateName}</Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item style={{ width: '100%' }}
                  label={translate("[FormReportView]Reporting period")}
                  name="period"
                  rules={[{ required: true, message: translate("[FormReportView]Please select period report") + "!" }]}
                >
                  <DatePicker
                    placeholder={translate("[FormReportView]Select reprot period")}
                    disabled={!uploadTemplateReq.periodType}
                    style={{ width: '100%' }}
                    picker={uploadTemplateReq.getPickerType()}
                    onChange={onChangeDate}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          {uiFileValidate}
          {messageErrorDto.file && <div class="ant-form-item-explain ant-form-item-explain-error">
            <div role="alert">{messageErrorDto.file}</div>
          </div>}
          <Divider type="vertical" />
          {messageErrorDto.messageServer && <div class="ant-form-item-explain ant-form-item-explain-error">
            <div role="alert">{messageErrorDto.messageServer}</div>
          </div>}
        </div>
      </Spin>

    </Modal>

  </>);
};
export default withMobx(UploadModal);
