import { configEnv } from "~/@config";
import { EMediaType } from "~/@core/constants";
import { ApiException } from "~/@core/dto";
import { AxiosHttpClient } from "~/@core/network/axios-http-client";
import { KeyHeader, KeyLocalStore } from "~/common/constants";
class ApiService {
  constructor(config) {
    this.httpClient = new AxiosHttpClient(config);
  }
  async get(endpoint, params = {}) {
    try {
      const res = await this.httpClient.get(endpoint, params);
      return res.data;
    }
    catch (error) {
      throw error;
    }
  }
  async getByBody(endpoint, body = {}) {
    try {
      const res = await this.httpClient.getByBody(endpoint, body);
      return res.data;
    }
    catch (error) {
      throw error;
    }
  }
  async post(endpoint, body = {}) {
    try {
      const res = await this.httpClient.post(endpoint, body);
      return res.data;
    }
    catch (error) {
      throw error;
    }
  }
  async put(endpoint, body = {}) {
    try {
      const res = await this.httpClient.put(endpoint, body);
      return res.data;
    }
    catch (error) {
      throw error;
    }
  }

  async delete(endpoint, body = {}) {
    try {
      const res = await this.httpClient.delete(endpoint, body);
      return res.data;
    }
    catch (error) {
      throw error;
    }
  }
  async uploadFile(endpoint, formData, onUploadProgress) {
    try {
      const res = await this.httpClient.uploadFile(endpoint, formData, onUploadProgress);
      return res.data;
    }
    catch (error) {
      throw error;
    }
  }
  async postFormData(endpoint, formData, onUploadProgress) {
    try {
      const res = await this.httpClient.postFormData(endpoint, formData, onUploadProgress);
      return res.data;
    }
    catch (error) {
      throw error;
    }
  }
  

  async downloadMergeFile(endpoint, formData, onUploadProgress) {
    try {
      const res = await this.httpClient.downloadMergeFile(endpoint, formData, onUploadProgress);
      return res.data;
    }
    catch (error) {
      throw error;
    }
  }
}
const { ROOT, FILE_REPORT } = configEnv().CONNECTORS;


export const authApiService = new ApiService({
  baseurl: ROOT.baseUrl,
  options: {
    timeout: 600000,
    headers: {
      "Content-Type": EMediaType.APPLICATION_JSON
    }
  },
  interceptors: {
    [KeyHeader.AUTHORIZATION]: async () => {
      return localStorage.getItem(KeyLocalStore.accessToken);
    }
  }
});

export const rootApiService = new ApiService({
  baseurl: ROOT.baseUrl,
  options: {
    timeout: 600000,
    headers: {
      "Content-Type": EMediaType.APPLICATION_JSON
    }
  },
  interceptors: {
    [KeyHeader.AUTHORIZATION]: async () => {
      // try {

      //   const refreshToken = localStorage.getItem(KeyLocalStore.refreshToken);
      //   const userSession = localStorage.getItem(KeyLocalStore.userSession);
      //   const { expiresOn } = userSession;
      //   const now = Math.floor(new Date().getTime() / 1000);
      //   const userSessionDto = await authApiService.post("/portal/api/public/auth/refresh-token", { refreshToken });
      //   localStorage.setItem(KeyLocalStore.accessToken, userSessionDto.accessToken);
      //   localStorage.setItem(KeyLocalStore.refreshToken, userSessionDto.refreshToken);
      //   // if (now >= Number(expiresOn)) {
      //   //   const userSessionDto = await authApiService.post("/portal/api/public/auth/refresh-token", { refreshToken });
      //   //   localStorage.setItem(KeyLocalStore.accessToken, userSessionDto.accessToken);
      //   //   localStorage.setItem(KeyLocalStore.refreshToken, userSessionDto.refreshToken);
      //   // }
      // } catch (error) {
      //   throw new ApiException("Phiên đăng nhập hết hạn", 401)
      // }
      return localStorage.getItem(KeyLocalStore.accessToken);
    }
  }
});

export const fileReportApiService = new ApiService({
  baseurl: FILE_REPORT.baseUrl,
  options: {
    timeout: 600000,
    headers: {
      "Content-Type": EMediaType.APPLICATION_JSON,
      "api_key": "18162BA9-DCCE-4B30-927B-97227C9D7117"
    }
  },
  interceptors: {
    [KeyHeader.AUTHORIZATION]: async () => {
      return localStorage.getItem(KeyLocalStore.accessToken);
    }
  }
});

export const optionalApiService = new ApiService({
  baseurl: FILE_REPORT.baseUrl,
  options: {
    timeout: 600000,
    headers: {
      "Content-Type": EMediaType.APPLICATION_FORM_URLENCODED,
    }
  },
  interceptors: {
  }
});
