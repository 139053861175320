import "./index.less";
import { withMobx } from "~/hooks"
import { Row, Select, Form, Col, Input, Button, Table, Spin, Tag } from 'antd';
import { languageStore } from "~/stores/language";
import { useEffect } from "react";
import UploadModal from "./UploadModal";
import { authStore } from '~/stores/auth';
import { DownloadOutlined, UploadOutlined, ClockCircleOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { ErrorFileDto, formReportStore, MessageErrorDto } from "~/stores/form-report";
import { useParams } from "react-router";
const { Option } = Select;


const FormReportView = () => {
  const { userSessionDto } = authStore;
  const { segment = "" } = useParams();
  const { translate } = languageStore;

  const { modalUploadDto, loadListTemplate, listTemplate, templateHistoryDto,
    loadListSegment,
    uploadTemplateReq,
    templateCodeSelected,
    isLoading,
    dowloadTemplate,
    dowloadTemplateHistory,
    keySearch,
    listTemplateFilter,
    filterListTemplate,
    onChangeKeySearch,
  } = formReportStore;
  const { loadData: loadDataHistory } = templateHistoryDto;
  // const canDownloadAll = () => {
  //   return userSessionDto.isMaster;
  // }
  const dowloadHistory = (row) => {
    dowloadTemplateHistory(row.id)
  };
  const onEnter = (e) => {
    const { key, keyCode } = e;
    if (keyCode === 13) {
      filterListTemplate();
    }
  }
  const dowload = (row) => {
    dowloadTemplate(row.templateCode)
  };
  const columns = [
    {
      title: translate("[FormReportView]Template name"),
      dataIndex: 'templateName',
      key: 'templateName',
      ellipsis: true,
    },
    {
      key: "action",
      width: "200px",
      title: translate("[Common]Action"),
      render: (value, record) => {
        return (
          <span>
            <Button
              icon={<DownloadOutlined />} size={'middle'} onClick={dowload.bind(null, record)}>
              {translate("[FormReportView]Download form report")}
            </Button>
          </span>
        )
      }
    }
  ];

  useEffect(() => {
    uploadTemplateReq.segment = segment;

    loadListTemplate();
    loadDataHistory({
      pageIndex: 1,
      pageSize: 100,
      type: "all"
    });
    loadListSegment();

  }, [loadListTemplate, loadDataHistory, loadListSegment, segment, uploadTemplateReq])

  return (
    <div style={{ width: '100%', minHeight: '100%' }}>
      <Spin spinning={isLoading}>
        <Form
          layout="vertical"
        >
          <Row gutter={[10, 0]}>
            <Col span={6}>
              <Form.Item label={translate("[FormReportView]Form report")}  >
                <Input
                  onKeyDown={onEnter}
                  placeholder={translate("[FormReportView]Form report placeholder name")}
                  name="keySearch"
                  value={keySearch}

                  onChange={onChangeKeySearch}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label=" " >
                <Button onClick={filterListTemplate} type="primary">{translate("[Common]Search")}</Button>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Row justify="end">
                <Form.Item label=" " >
                  <Button type="primary" icon={<UploadOutlined />} onClick={() => {
                    formReportStore.messageErrorDto = new MessageErrorDto();
                    formReportStore.errorFileDto = new ErrorFileDto();
                    formReportStore.fileList = [];
                    formReportStore.checkDuplicate = 0;
                    formReportStore.fileList = [];
                    modalUploadDto.open();
                  }}> {translate("[Common]Upload")}</Button>
                </Form.Item>
              </Row>
            </Col>
          </Row>
        </Form>
        <Row>
          <Row align="middle" style={{ marginBottom: 10 }}>

            <UnorderedListOutlined style={{ fontSize: 16, marginRight: 5 }} />
            <span style={{ fontSize: 16, fontWeight: "bold" }}>  {translate("[FormReportView]List form report")}</span>
          </Row>
          <Col span={24}>
            <Table
              rowClassName={(record) => record.templateCode === templateCodeSelected ? 'table-row-select' : ''}
              onRow={(record) => {
                return {
                  onClick: () => {
                    const { templateCode = "" } = record;
                    formReportStore.templateCodeSelected = templateCode;
                    loadDataHistory({
                      segment: segment,
                      templateCode: templateCode,
                      pageIndex: 1,
                      pageSize: 100,
                      type: "all"
                    })
                  }, // click row
                  onDoubleClick: () => { }, // double click row
                  onContextMenu: () => { }, // right button click row
                  onMouseEnter: () => { }, // mouse enter row
                  onMouseLeave: () => { }, // mouse leave row
                };
              }}
              rowKey={(it) => it.id}
              pagination={{
                defaultPageSize: 5
              }}
              columns={columns}
              dataSource={listTemplateFilter} />
          </Col>
          <Col span={24}>

            <Row align="middle" style={{ marginBottom: 10 }}>
              <ClockCircleOutlined style={{ fontSize: 16, marginRight: 5 }} />
              <span style={{ fontSize: 16, fontWeight: "bold" }}>  {translate("[FormReportView]History update")}</span>
            </Row>

            <Table
              loading={templateHistoryDto.isLoading}
              rowKey={(it) => it.id}
              pagination={{
                defaultPageSize: 5
              }}
              columns={[
                {
                  title: translate("[Common]Updated date"),
                  dataIndex: 'updatedDate',
                  key: 'updatedDate',

                },

                {
                  title: translate("[FormReportView]User update"),
                  dataIndex: 'user',
                  key: 'user'
                },
                {
                  title: translate("[Common]Status"),
                  dataIndex: 'errorRecords',
                  key: 'status',
                  render: (value, record) => {
                    if (value === 0) {
                      return <Tag color="success">{translate("[Common]Success")}</Tag>
                    }
                    return <Tag color="error">{translate("[Common]Fail")}</Tag>

                  }
                },
                {
                  key: "action",
                  title: translate("[Common]Action"),

                  width: "200px",
                  render: (value, record) => {
                    return (
                      <span>
                        <Button icon={<DownloadOutlined />} size={'middle'} onClick={dowloadHistory.bind(null, record)}>
                          {translate("[FormReportView]Download form report")}
                        </Button>
                      </span>
                    )
                  }
                }
              ]}
              dataSource={templateHistoryDto.data} />
          </Col>
        </Row>
        <UploadModal />
      </Spin>

    </div>
  )
}

export default withMobx(FormReportView);