import { makeAutoObservable } from "mobx";
import moment from "moment";

export class DataAggregationReportReq {
  constructor() {
    makeAutoObservable(this);
  }
  pageIndex = 1;
  pageSize = 10;
  month = moment().month() + 1;
  year = moment().year();
  segment_code = "";
  kpi_code = "";
  templateCode = "";
  user = "";
  is_download = "";
  upload_type ="";
}