// @material-ui/icons
import HomeOutlined from "@material-ui/icons/HomeOutlined";
// import AssignmentOutlined from "@material-ui/icons/AssignmentOutlined";
// import FormatListBulletedOutlined from "@material-ui/icons/FormatListBulletedOutlined";
// import SettingsOutlined from "@material-ui/icons/SettingsOutlined";
// core components/views for Admin layout
import DashboardPage from "./views/Dashboard/Dashboard.js";
import UserConfigPage from "./views/UserConfig/UserConfig.js";
import UserAction from "./views/UserConfig/UserAction";
import TargetConfig from './views/TargetConfig/TargetConfig';
import DataReconciliation from './views/DataReconciliation/DataReconciliation';
import ManagementReport from './views/ManagementReport/ManagementReport';
import CompanyData from './views/CompanyData/CompanyData';
import LoginPage from './layouts/Login';
import UserConfigDetail from "./views/UserConfig/UserConfigDetail";
import ExampleView from "./views/ExampleView/index.js";
import GroupRoleView from "./views/GroupRoleView/index.js";
import GroupRoleDetail from "./views/GroupRoleView/GroupRoleDetail/index.js";
import PowerbiView from "./views/PowerbiView/index.js";
import FormReportView from "./views/FormReportView/index.js";
import UpdateMasterData from "./views/UpdateMasterData/index.js";
import DataAggregation from "./views/DataAggregation/DataAggregation";

const dashboardRoutes = [
  {

    path: "/",
    name: "Login",
    component: LoginPage,
    // layout: "/secure",
  }, {
    path: "/dashboard",
    name: "Trang chủ",
    icon: HomeOutlined,
    component: DashboardPage,
    layout: "/secure",
  },
  {
    path: "/user-config",
    name: "Người Dùng",
    layout: "/secure",
    component: UserConfigPage
  },
  // {
  //   path: "/target-config",
  //   name: "Chỉ Tiêu",
  //   layout: "/secure",
  //   component: TargetConfig
  // },
  {
    path: "/data-reconciliation",
    name: "Đối Soát Dữ Liệu",
    layout: "/secure",
    component: DataReconciliation
  },
  {
    path: "/data-aggregation",
    name: "Tổng hợp dữ liệu",
    layout: "/secure",
    component: DataAggregation
  },
  {
    path: "/management-report",
    name: "Báo Cáo Quản Trị",
    layout: "/secure",
    component: ManagementReport
  },
  {
    path: "/form-report/:segment",
    name: "Biểu mẫu",
    layout: "/secure",
    component: FormReportView
  },
  {
    path: "/company-data",
    name: "Danh Sách Cơ Sở",
    layout: "/secure",
    component: CompanyData
  },
  {
    path: "/user-config/add",
    name: "Danh Sách Cơ Sở",
    layout: "/secure",
    component: UserAction
  },
  {
    path: "/user-config/update",
    name: "Danh Sách Cơ Sở",
    layout: "/secure",
    component: UserConfigDetail
  },

  {
    path: "/group-role".trim(),
    name: "Danh Sách Cơ Sở",
    layout: "/secure",
    component: GroupRoleView
  },
  {
    path: "/group-role/update".trim(),
    name: "Nhóm người dùng chi tiết",
    layout: "/secure",
    component: GroupRoleDetail
  },
  {
    path: "/example",
    name: "Danh Sách Cơ Sở",
    layout: "/secure",
    component: ExampleView
  },
  {
    path: "/powerbi",
    name: "Danh Sách Cơ Sở",
    layout: "/secure",
    component: PowerbiView
  },
  {
    path: "/update-master-data-report-item",
    name: "Chỉ tiêu và báo cáo",
    layout: "/secure",
    component: UpdateMasterData
  }
];

export default dashboardRoutes;
