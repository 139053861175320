import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// @material-ui/icons

// core components

import styles from "../../assets/jss/material-dashboard-react/components/filterBoxStyle.js";

const useStyles = makeStyles(styles);

export default function Dropdown(props) {
  const classes = useStyles();
  const { item } = props;
  const [value, setValue] = React.useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  }

  return (
    <FormControl className={classes.filterBox}>
      <InputLabel shrink id={item.name}>
        {item.label}
      </InputLabel>
      <Select labelId={item.name} id={item.name} value={value} onChange={handleChange} displayEmpty className={classes.selectEmpty}>
        { item.filters.map((arr, index) => {
            return (
              <MenuItem key={index} value={arr.value}>{arr.label}</MenuItem>
            );
          })
        }
      </Select>
    </FormControl>
  );
}

Dropdown.propTypes = {
  item: PropTypes.object
};
