import { EHttpStatus } from "../constants";
export class ApiException {
    constructor(message, httpCode = EHttpStatus.INTERNAL_SERVER_ERROR, errors = undefined, type = "DEFAULT", businessCode = -1) {
        this.type = "DEFAULT";
        this.businessCode = -1;
        this.httpCode = httpCode;
        this.message = message;
        this.type = type;
        this.businessCode = businessCode;
        this.errors = errors;
    }
}
