const dialogStyle = (theme) => ({
  bodyDialog: {
    width: "500px",
    padding: "0 20px"
  },
  footerDialog: {
    display: "flex",
    "flex-direction": "row-reverse",
    margin: "10px"
  },
  btn: {
    margin: "10px"
  },
  notiBox: {
    margin: '10px',
    border: '1px solid #ccc',
    borderRadius: "8px",
    padding: '10px'
  },
  guildBox: {
    margin: '10px',
    padding: '10px'
  },
  text: {
    margin: 0
  },
  download: {
    display: 'inline-block',
    border: '1px solid #CCC',
    padding: '10px 15px',
    margin: '10px',
    cursor: 'pointer',
    'border-radius': '8px',
  },
  img: {
    width: "35px",
    display: "inline-block"
  }
});

export default dialogStyle;