
import React, { useState } from 'react';

import { InboxOutlined, DownloadOutlined } from '@ant-design/icons';
import { Typography, Button, Row, Col, Divider, Spin } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { withMobx } from "~/hooks";
import { rootApiService, toastService } from '~/services';
import fileReportService from '~/services/file-report.service';
import { authStore } from '~/stores/auth';
import { useHistory } from "react-router-dom";

const UpdateMasterData = () => {
  const { userSessionDto } = authStore;
  const [fileList, setFileList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  if (!userSessionDto?.isMaster) {
    history.push({
      pathname: `/secure/dashboard`,
    });
  }
  const draggerProps = () => {
    return {
      name: "files",
      accept: ".xlsx, .xls",
      multiple: false,
      fileList: fileList,
      onRemove: (file) => {
        const cloneArr = fileList.filter(v => v.uid !== file.uid);
        setFileList(cloneArr);
      },
      onChange: (info) => {

        const { status } = info.file;
        if (status === "done") {
          // toastService.success(`${info.file.name} success`);
        }
        else if (status === "error") {
          toastService.error(`${info.file.name} error`);
        }
      },
      beforeUpload: (file, fileListRes) => {
        setFileList(fileListRes);
        return true;
      },
      customRequest(e) {
        e.onSuccess();
      }
    };
  };

  const uploadItemCodeReport = async () => {
    if (!(fileList.length > 0)) {
      return toastService.handleError({message:"Bạn phải chọn file trước khi tải lên"});
    }
    setLoading(true)
    try {

      const fmData = new FormData();
      fileList.forEach(file => {
        fmData.append("file", file)
      })
      rootApiService.uploadFile("/portal/api/business/resources/upload-item-code", fmData);

      await new Promise(resolve => {
        setTimeout(() => {
          resolve("OK");
        }, 120 * 1000);
      })
      setLoading(false);
      toastService.success();
    } catch (error) {
      setLoading(false)
      toastService.handleError(error);

    }
  }

  return (
    <div>
      <Spin spinning={isLoading}>
        <h5> Cập nhật thông tin báo cáo và chỉ tiêu </h5>
        <Row justify="center">
          <Col span={12}>
            <Dragger showUploadList={true} {...draggerProps()}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined size={5} />
              </p>
              <p className="ant-upload-text">
                Nhấp hoặc kéo tệp vào khu vực này để tải lên
              </p>
              <Typography.Text type="secondary">Định dạng tệp: .xlxs</Typography.Text>  <Typography.Text type="secondary">Tối đa: 50MB</Typography.Text>
              {fileList.length === 0 && (<p>
                <Typography.Text type="secondary">
                  {`Dữ liệu đầu vào`}
                </Typography.Text>
              </p>)}
            </Dragger>

            <Row justify="center">
              <Divider type="horizontal" />
              <Button style={{ width: '100%' }} onClick={uploadItemCodeReport} size="large" type="primary" icon={<DownloadOutlined />}> Tải lên</Button>
            </Row>
          </Col>
        </Row>
      </Spin>

    </div>
  )
}

export default withMobx(UpdateMasterData)