import React from "react";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

// @material-ui/icons

// core components

import styles from "../../assets/jss/material-dashboard-react/components/filterBoxStyle.js";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

const useStyles = makeStyles(styles);

export default function DatePicker(props) {
  const classes = useStyles();
  const [startDate, setStartDate] = React.useState( () => {
    return moment().subtract(30, 'days');
  });
  const [endDate, setEndDate] = React.useState( () => {
    return moment().startOf('hour');
  });

  const handleCallback = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  }

  return (
    <FormControl className={classes.filterBox}>
      <InputLabel shrink style={{marginTop: "-15px"}}>
        Thời Gian
      </InputLabel>
      <DateRangePicker
        initialSettings={{ startDate, endDate }}
        onCallback={handleCallback} 
      >
        <input id="DateTimes" type="text" className="form-control col-4" style={{height: "50px"}} />
      </DateRangePicker>
    </FormControl>
  );
}
