import React from 'react';
import PropTypes from 'prop-types';

// material/core
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';

// material/icon
import DeleteIcon from '@material-ui/icons/Delete';
import BorderColor from '@material-ui/icons/BorderColor';
import Visibility from '@material-ui/icons/Visibility';

// components
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import EnhancedTableHead from "./EnhancedTableHead";
import TableDialog from "../Dialog/TableDialog";
import AddDialog from "../Dialog/AddDialog";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  btnSwitch: {
    display: "inline-block"
  },
  footer: {
    display: "flex",
    justifyContent: "space-between"
  }
}));

export default function DataGrid(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openTableDialog, setOpenTableDialog] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [type, setType] = React.useState("");
  const [form, setForm] = React.useState({
    detail: {
      id_company: '123456',
      status: false,
      name_company: '123456',
      location: 'nhatrang',
      description: '123456'
    }
  });

  const { 
    rows, 
    headCells, 
    hiddenBtnAdd, 
    hiddenBtnDownloadForm, 
    hiddenBtnExport, 
    hiddenBtnUpload, 
    showHistoryTitle, 
    hiddenCheckboxCell, 
    showLastRefresh, 
    showHideAlert,
    title } = props;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    let id = "";
    headCells.forEach( (head) => {
      if(head.rendered.type === "key") {
        id = head.id;
      }
    });
    if (event.target.checked) {
      const newSelecteds = rows.map((n, index) => n[id] + index);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openViewDetail = (row) => {
    props.openView(true, row);
  }

  const openPopup = () => {
    setOpenTableDialog(true);
  }

  const handleCloseDialog = () => {
    setOpenTableDialog(false);
  }

  const openViewDetailDialog = (row) => {
    setOpenAdd(true);
    setType("edit");
  }

  const handleCloseDialogAdd = () => {
    setOpenAdd(false);
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar 
          numSelected={selected.length} 
          rows={rows} 
          hiddenBtnAdd={hiddenBtnAdd} 
          hiddenBtnDownloadForm={hiddenBtnDownloadForm}
          hiddenBtnExport={hiddenBtnExport}
          hiddenBtnUpload={hiddenBtnUpload}
          showHistoryTitle={showHistoryTitle}
          showHideAlert={showHideAlert}
          title={title} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
              hiddenCheckboxCell={hiddenCheckboxCell}
            />
            <TableBody>
              { stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  var key = "";
                  headCells.forEach( (column) => {
                    if(typeof column.rendered === "object" && column.rendered.type === "key") {
                      key = row[column.id] + index;
                    }
                  });
                  const isItemSelected = isSelected(key);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={key}
                      selected={isItemSelected}
                    >
                      { hiddenCheckboxCell
                        ? null
                        : <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                              onClick={(event) => handleClick(event, key)}
                            />
                          </TableCell>
                      }

                      {/* Key */}
                      {/* <TableCell component="th" id={labelId} scope="row" padding="none">
                        {key}
                      </TableCell> */}

                      { headCells.map( (column, index) => {
                          return (
                            <TableCell 
                              align="left" 
                              key={index}
                              id={typeof column.rendered === "object" && column.rendered.type === "key"
                                  ? {labelId}
                                  : null
                                }
                              >
                              { typeof column.rendered === "object" && column.rendered.type === "key"
                                ? row[column.id]
                                : null
                              }

                              { typeof column.rendered === "object" && column.rendered.type === "highlight_text"
                                ? <div>
                                    <span 
                                      style={row[column.id].type==="success" ? {color: "#00B517"}: {color: "#F53838"}}>
                                      {row[column.id].highlight}
                                    </span>{row[column.id].text}
                                  </div>
                                : null
                              }

                              { !column.rendered 
                                ? row[column.id]
                                : null
                              }

                              { typeof column.rendered === "object" && column.rendered.type === "btn_switch"
                                ? <FormGroup className={classes.btnSwitch}>
                                    <FormControlLabel
                                      control={<Switch checked={row.status} color="primary" />}
                                    />
                                  </FormGroup>
                                : null
                              }

                              { typeof column.rendered === "object" && column.rendered.type === "action"
                                ? <div>
                                    <IconButton aria-label="filter list" onClick={() => openViewDetail(row)}>
                                      <BorderColor />
                                    </IconButton>
                                    <IconButton aria-label="filter list">
                                      <DeleteIcon />
                                    </IconButton>
                                  </div>
                                : null
                              }

                              { typeof column.rendered === "object" && column.rendered.type === "view_detail"
                                ? <div>
                                    <IconButton aria-label="filter list" onClick={() => openViewDetailDialog(row)}>
                                      <BorderColor />
                                    </IconButton>
                                    <IconButton aria-label="filter list">
                                      <DeleteIcon />
                                    </IconButton>
                                  </div>
                                : null
                              }

                              { typeof column.rendered === "object" && column.rendered.type === "chip"
                                ? <Chip 
                                    variant="outlined" 
                                    size="small" 
                                    label={row[column.id]} 
                                    style={ row[column.id] === "Thành Công" 
                                      ? {color: "#00B517", backgroundColor: "#EFFAF1", border: "1px solid #EFFAF1", width: "100px"} 
                                      : {color: "#F53838", backgroundColor: "#FEEAEA", border: "1px solid #FEEAEA", width: "100px"}} />
                                : null
                              }

                              { typeof column.rendered === "object" && column.rendered.type === "open_popup"
                                ? <IconButton aria-label="filter list" onClick={() => openPopup()}>
                                    <Visibility />
                                  </IconButton>
                                : null
                              }
                            </TableCell>
                          )
                        })
                      }
                    </TableRow>
                  );
                })
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box className={classes.footer}>
          { showLastRefresh
            ? <Box style={{padding: "15px"}}>
                Cập nhật lần cuối: 28/06/2021 14:00
              </Box>
            : null
          }
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Paper>

      <TableDialog openDialog={openTableDialog} onCloseDialog={handleCloseDialog}></TableDialog>

      <AddDialog openAdd={openAdd} onCloseDialog={handleCloseDialogAdd} title={title} type={type} form={form}></AddDialog>
    </div>
  );
}

DataGrid.propTypes = {
  openView: PropTypes.func,
  rows: PropTypes.array,
  headCells: PropTypes.arrayOf(PropTypes.object),
  hiddenBtnAdd: PropTypes.bool,
  hiddenBtnDownloadForm: PropTypes.bool,
  hiddenBtnExport: PropTypes.bool,
  hiddenBtnUpload: PropTypes.bool,
  showHistoryTitle: PropTypes.bool,
  hiddenCheckboxCell: PropTypes.bool,
  showLastRefresh: PropTypes.bool,
  showHideAlert: PropTypes.func,
  title: PropTypes.string
};
