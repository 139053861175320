export var EHttpHeaders;
(function (EHttpHeaders) {
    EHttpHeaders["ACCEPT"] = "Accept";
    EHttpHeaders["ACCEPT_CHARSET"] = "Accept-Charset";
    EHttpHeaders["ACCEPT_ENCODING"] = "Accept-Encoding";
    EHttpHeaders["ACCEPT_LANGUAGE"] = "Accept-Language";
    EHttpHeaders["ACCEPT_RANGES"] = "Accept-Ranges";
    EHttpHeaders["ACCESS_CONTROL_ALLOW_CREDENTIALS"] = "Access-Control-Allow-Credentials";
    EHttpHeaders["ACCESS_CONTROL_ALLOW_HEADERS"] = "Access-Control-Allow-Headers";
    EHttpHeaders["ACCESS_CONTROL_ALLOW_METHODS"] = "Access-Control-Allow-Methods";
    EHttpHeaders["ACCESS_CONTROL_ALLOW_ORIGIN"] = "Access-Control-Allow-Origin";
    EHttpHeaders["ACCESS_CONTROL_EXPOSE_HEADERS"] = "Access-Control-Expose-Headers";
    EHttpHeaders["ACCESS_CONTROL_MAX_AGE"] = "Access-Control-Max-Age";
    EHttpHeaders["ACCESS_CONTROL_REQUEST_HEADERS"] = "Access-Control-Request-Headers";
    EHttpHeaders["ACCESS_CONTROL_REQUEST_METHOD"] = "Access-Control-Request-Method";
    EHttpHeaders["AGE"] = "Age";
    EHttpHeaders["ALLOW"] = "Allow";
    EHttpHeaders["AUTHORIZATION"] = "Authorization";
    EHttpHeaders["CACHE_CONTROL"] = "Cache-Control";
    EHttpHeaders["CONNECTION"] = "Connection";
    EHttpHeaders["CONTENT_ENCODING"] = "Content-Encoding";
    EHttpHeaders["CONTENT_DISPOSITION"] = "Content-Disposition";
    EHttpHeaders["CONTENT_LANGUAGE"] = "Content-Language";
    EHttpHeaders["CONTENT_LENGTH"] = "Content-Length";
    EHttpHeaders["CONTENT_LOCATION"] = "Content-Location";
    EHttpHeaders["CONTENT_RANGE"] = "Content-Range";
    EHttpHeaders["CONTENT_TYPE"] = "Content-Type";
    EHttpHeaders["COOKIE"] = "Cookie";
    EHttpHeaders["DATE"] = "Date";
    EHttpHeaders["ETAG"] = "ETag";
    EHttpHeaders["EXPECT"] = "Expect";
    EHttpHeaders["EXPIRES"] = "Expires";
    EHttpHeaders["FROM"] = "From";
    EHttpHeaders["HOST"] = "Host";
    EHttpHeaders["IF_MATCH"] = "If-Match";
    EHttpHeaders["IF_MODIFIED_SINCE"] = "If-Modified-Since";
    EHttpHeaders["IF_NONE_MATCH"] = "If-None-Match";
    EHttpHeaders["IF_RANGE"] = "If-Range";
    EHttpHeaders["IF_UNMODIFIED_SINCE"] = "If-Unmodified-Since";
    EHttpHeaders["LAST_MODIFIED"] = "Last-Modified";
    EHttpHeaders["LINK"] = "Link";
    EHttpHeaders["LOCATION"] = "Location";
    EHttpHeaders["MAX_FORWARDS"] = "Max-Forwards";
    EHttpHeaders["ORIGIN"] = "Origin";
    EHttpHeaders["PRAGMA"] = "Pragma";
    EHttpHeaders["PROXY_AUTHENTICATE"] = "Proxy-Authenticate";
    EHttpHeaders["PROXY_AUTHORIZATION"] = "Proxy-Authorization";
    EHttpHeaders["RANGE"] = "Range";
    EHttpHeaders["REFERER"] = "Referer";
    EHttpHeaders["RETRY_AFTER"] = "Retry-After";
    EHttpHeaders["SERVER"] = "Server";
    EHttpHeaders["SET_COOKIE"] = "Set-Cookie";
    EHttpHeaders["SET_COOKIE2"] = "Set-Cookie2";
    EHttpHeaders["TE"] = "TE";
    EHttpHeaders["TRAILER"] = "Trailer";
    EHttpHeaders["TRANSFER_ENCODING"] = "Transfer-Encoding";
    EHttpHeaders["UPGRADE"] = "Upgrade";
    EHttpHeaders["USER_AGENT"] = "User-Agent";
    EHttpHeaders["VARY"] = "Vary";
    EHttpHeaders["VIA"] = "Via";
    EHttpHeaders["WARNING"] = "Warning";
    EHttpHeaders["WWW_AUTHENTICATE"] = "WWW-Authenticate";
})(EHttpHeaders || (EHttpHeaders = {}));
