import React from "react";
import PropTypes from 'prop-types';

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

// @material-ui/icons

// core components
import CompanyForm from "../Form/CompanyForm";

import styles from "../../assets/jss/material-dashboard-react/components/dialogStyle.js";

const useStyles = makeStyles(styles);

export default function AddDialog(props) {
  const classes = useStyles();
  const { onCloseDialog, openAdd, title, type, form } = props;

  const handleClose = () => {
    onCloseDialog();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={openAdd}>
      <DialogTitle id="simple-dialog-title">{ type === 'add' ? 'Thêm ' : 'Chỉnh Sửa '} {title}</DialogTitle>

      <CompanyForm res={form} onCloseDialog={onCloseDialog}></CompanyForm>
    </Dialog>
  );
}

AddDialog.propTypes = {
  onCloseDialog: PropTypes.func.isRequired,
  openAdd: PropTypes.bool.isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
  form: PropTypes.object
};
