import React from "react";
import PropTypes from 'prop-types';

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

// @material-ui/icons

// components
import DataGrid from "../DataGrid/DataGrid.js";

import styles from "../../assets/jss/material-dashboard-react/components/dialogStyle.js";

const useStyles = makeStyles(styles);

export default function TableDialog(props) {
  const classes = useStyles();
  const { onCloseDialog, openDialog } = props;

  const handleClose = () => {
    onCloseDialog();
  };

  const rows = [
    {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",id_report:"RIC00000001",id_kpis:"100320121001", status: "Thành Công"},
    {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",id_report:"RIC00000001",id_kpis:"100320121001", status: "Thành Công"},
    {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",id_report:"RIC00000001",id_kpis:"100320121001", status: "Thất Bại"},
    {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",id_report:"RIC00000001",id_kpis:"100320121001", status: "Thành Công"},
    {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",id_report:"RIC00000001",id_kpis:"100320121001", status: "Thành Công"},
    {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",id_report:"RIC00000001",id_kpis:"100320121001", status: "Thành Công"},
    {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",id_report:"RIC00000001",id_kpis:"100320121001", status: "Thất Bại"},
    {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",id_report:"RIC00000001",id_kpis:"100320121001", status: "Thành Công"},
    {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",id_report:"RIC00000001",id_kpis:"100320121001", status: "Thành Công"},
    {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",id_report:"RIC00000001",id_kpis:"100320121001", status: "Thành Công"},
    {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",id_report:"RIC00000001",id_kpis:"100320121001", status: "Thành Công"},
    {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",id_report:"RIC00000001",id_kpis:"100320121001", status: "Thành Công"},
    {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",id_report:"RIC00000001",id_kpis:"100320121001", status: "Thành Công"},
    {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",id_report:"RIC00000001",id_kpis:"100320121001", status: "Thành Công"},
    {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",id_report:"RIC00000001",id_kpis:"100320121001", status: "Thành Công"},
    {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",id_report:"RIC00000001",id_kpis:"100320121001", status: "Thành Công"},
    {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",id_report:"RIC00000001",id_kpis:"100320121001", status: "Thành Công"},
    {id_company: "VPSPQ-GƯ",name_company:"VinWonders GrandWorld Phú Quốc",id_report:"RIC00000001",id_kpis:"100320121001", status: "Thành Công"},
  ];
  
  const headCells = [
    { id: 'id_company', numeric: false, disablePadding: true, label: 'Mã Cơ Sở', rendered: {type: "key"} },
    { id: 'name_company', numeric: true, disablePadding: false, label: 'Tên Cơ Sở', rendered: false },
    { id: 'id_report', numeric: true, disablePadding: false, label: 'Mã Báo Cáo', rendered: false },
    { id: 'id_kpis', numeric: true, disablePadding: false, label: 'Mã KPIs', rendered: false },
    { id: 'status', numeric: true, disablePadding: false, label: 'Trạng Thái', rendered: {type: "chip"} },
  ];

  return (
    <Dialog maxWidth={'md'} onClose={handleClose} aria-labelledby="simple-dialog-title" open={openDialog}>
      {/* <DialogTitle id="simple-dialog-title">Lịch Sử Cập Nhật</DialogTitle> */}
      <DataGrid 
        rows={rows} 
        headCells={headCells} 
        hiddenBtnAdd={true} 
        hiddenBtnDownloadForm={false}
        hiddenBtnExport={true}
        hiddenBtnUpload={true}
        showLastRefresh={true}
        hiddenCheckboxCell={true}
        showHistoryTitle={true}
      />
    </Dialog>
  );
}

TableDialog.propTypes = {
  onCloseDialog: PropTypes.func.isRequired,
  openDialog: PropTypes.bool.isRequired,
};
