export var EMediaType;
(function (EMediaType) {
    EMediaType["APPLICATION_ATOM_XML"] = "application/atom+xml";
    EMediaType["APPLICATION_CBOR"] = "application/cbor";
    EMediaType["APPLICATION_FORM_URLENCODED"] = "application/x-www-form-urlencoded";
    EMediaType["APPLICATION_JSON"] = "application/json";
    EMediaType["APPLICATION_JSON_UTF8"] = "application/json,charset=UTF-8";
    EMediaType["APPLICATION_OCTET_STREAM"] = "application/octet-stream";
    EMediaType["APPLICATION_PDF"] = "application/pdf";
    EMediaType["APPLICATION_PROBLEM_JSON"] = "application/problem+json";
    EMediaType["APPLICATION_PROBLEM_JSON_UTF8"] = "application/problem+json,charset=UTF-8";
    EMediaType["APPLICATION_PROBLEM_XML"] = "application/problem+xml";
    EMediaType["APPLICATION_RSS_XML"] = "application/rss+xml";
    EMediaType["APPLICATION_STREAM_JSON"] = "application/stream+json";
    EMediaType["APPLICATION_XHTML_XML"] = "application/xhtml+xml";
    EMediaType["APPLICATION_XML"] = "application/xml";
    EMediaType["IMAGE_GIF"] = "image/gif";
    EMediaType["IMAGE_JPEG"] = "image/jpeg";
    EMediaType["IMAGE_PNG"] = "image/png";
    EMediaType["MULTIPART_FORM_DATA"] = "multipart/form-data";
    EMediaType["MULTIPART_MIXED"] = "multipart/mixed";
    EMediaType["MULTIPART_RELATED"] = "multipart/related";
    EMediaType["TEXT_EVENT_STREAM"] = "text/event-stream";
    EMediaType["TEXT_HTML"] = "text/html";
    EMediaType["TEXT_MARKDOWN"] = "text/markdown";
    EMediaType["TEXT_PLAIN"] = "text/plain";
    EMediaType["TEXT_XML"] = "text/xml";
    EMediaType["PARAM_QUALITY_FACTOR"] = "q";
})(EMediaType || (EMediaType = {}));
