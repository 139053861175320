

const config = {
  name: "DEVELOPMENT",
  DOMAIN_URL: "http://localhost:9005",
  msalAuth: {
    // clientId: "19d9b36b-bc05-4c9d-8097-39f2854360ec",
    clientId: "15455962-ffb4-4051-8309-1cdfc8d2c437",
    authority: "https://login.microsoftonline.com/vingroupjsc.onmicrosoft.com",
  },
  CONNECTORS: {
    ROOT: {
      baseUrl: "http://localhost:3000"
    },
    FILE_REPORT: {
      baseUrl: "https://api.dev.bcqt.cloudhms.io"
    }
  }
}

export default config;
