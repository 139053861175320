import './index.less';
import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import {
  HomeOutlined,
  UnorderedListOutlined,
  FileDoneOutlined,
  SettingOutlined
} from '@ant-design/icons';
import { languageStore } from '~/stores/language';
import { layoutConfigStore } from '~/stores/master-data';
import { useHistory, useLocation } from 'react-router';

const { Sider } = Layout;
const { SubMenu } = Menu;

const SecureMenu = () => {

  const { translate } = languageStore;


  const menuFixedData = [
    //  Trang chủ 0
    {
      path: "/secure/dashboard",
      labeIcon: HomeOutlined,
      title: "Trang chủ",
      nodeId: "dashboard",
      children: []
    },
    // Báo cáo 1
    {
      path: "",
      labeIcon: FileDoneOutlined,
      title: "Báo Cáo",
      nodeId: "reprot",
      children: [
        {
          path: "/secure/management-report",
          // labeIcon: AssignmentOutlined,
          title: "Báo Cáo Quản Trị",
          nodeId: "management-report",
        },
        {
          path: "",
          // labeIcon: AssignmentOutlined,
          title: "Báo Cáo Đối Soát",
          nodeId: "reconciliation",
          children: [
            {
              path: "/secure/data-reconciliation",
              // labeIcon: AssignmentOutlined,
              title: "Đối Soát Dữ Liệu",
              nodeId: "data-reconciliation",
            },
            {
              path: "/secure/data-aggregation",
              // labeIcon: AssignmentOutlined,
              title: "Tổng hợp dữ liệu upload",
              nodeId: "data-aggregation",
            }
          ]
        },

      ]
    },

    // Biểu mẫu 2
    {
      path: "",
      labeIcon: UnorderedListOutlined,
      title: "Biểu mẫu",
      nodeId: "form-report",
      children: [
      ]
    },

    // Cấu hình

    {
      path: "",
      labeIcon: SettingOutlined,
      title: "Cấu Hình",
      nodeId: "configuration",
      children: [
        // {
        //   path: "/secure/target-config",
        //   // labeIcon: SettingsOutlined,
        //   title: "Chỉ Tiêu",
        //   nodeId: "configuration-item",

        // },
        {
          path: "/secure/group-role",
          // labeIcon: SettingsOutlined,
          title: translate("[MainMenu]Group user"),
          nodeId: "configuration-group-role",
        },
        {
          path: "/secure/user-config",
          // labeIcon: SettingsOutlined,
          title: "Người Dùng",
          nodeId: "configuration-user-config",
        },
        // {
        //   path: "",
        //   // labeIcon: SettingsOutlined,
        //   title: "Dữ Liệu Tổng Quát",
        //   nodeId: "configuration-company-data-1",
        //   children: [
        //     {
        //       path: "/secure/company-data",
        //       // labeIcon: SettingsOutlined,
        //       title: "Người Dùng",
        //       nodeId: "configuration-company-data-2",
        //     }
        //   ]
        // }
      ]
    },
  ];

  const { menus, loadMenu, collapsed } = layoutConfigStore;
  const location = useLocation();
  const history = useHistory()
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return location.pathname === routeName;
  }

  useEffect(() => {
    
  }, [menus])

  const onMenuClick = (path) => {
    history.push(path)
  };


  const makeElementMenu = (menus = []) => {
    const els = [];
    for (const menu of menus) {
      const { children = [], title, labeIcon: LabelIcon, nodeId, path } = menu;
      if (children.length) {
        const elChids = makeElementMenu(children);
        els.push(
          <SubMenu key={nodeId} icon={LabelIcon ? <LabelIcon style={{ color: "#535E6C", fontSize: 20, fontWeight: 'bold' }} /> : null} title={title}>
            {elChids}
          </SubMenu>
        )
      } else {
        els.push(
          <Menu.Item key={nodeId} icon={LabelIcon ? <LabelIcon style={{ color: "#535E6C", fontSize: 20, fontWeight: 'bold' }} /> : null} onClick={onMenuClick.bind(null, path)} >
            {title}
          </Menu.Item>
        )
      }
    }
    return els;
  }

  const elements = makeElementMenu(menus);
  return (
    <Menu style={{ fontSize: 16 }} inlineIndent={15} mode="inline">
      {elements}
    </Menu>

  )
}

export default SecureMenu