import React, { useEffect, useCallback } from 'react';
import { ConfigProvider } from "antd";
import { Route, Switch, Redirect, Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { Spin } from "antd"
import { Loading3QuartersOutlined } from '@ant-design/icons';
import moment from 'moment';
import Secure from './layouts/Secure';
import Login from './layouts/Login';
import { languageStore } from './stores/language';
import { withMobx } from '~/common/hoc';
import { KeyLocalStore } from './common/constants';
import { authStore } from './stores/auth';
import { navService } from './services';
import { layoutConfigStore } from './stores/master-data';

const Loading = () => {
  return (
    <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Spin indicator={<Loading3QuartersOutlined style={{ fontSize: 40 }} spin />} spinning={true} ></Spin>
    </div>
  )
}

const history = createBrowserHistory();
navService.initRefNav(history);

const AppContainer = (props) => {
  const { localeResource, loadResource } = languageStore;
  const { verifyUser, isFirstLoading, logged } = authStore;
  const { antdValue, momentValue } = localeResource;
  const { instance, inProgress } = useMsal();


  const { loadMenu } = layoutConfigStore;

  const loadDataResource = useCallback(
    async () => {
      console.log('-------------------');
      console.log("loadDataResource");
      console.log('-------------------');
      await verifyUser();
      await loadMenu();
    },
    [loadMenu, verifyUser],
  )

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      const account = instance.getActiveAccount();
      const accessTokenRequest = {
        scopes: ["openid"],
        account
      }
      instance.acquireTokenSilent(accessTokenRequest).then(async (accessTokenResponse) => {
        const { accessToken } = accessTokenResponse;
        localStorage.setItem(KeyLocalStore.accessToken, accessToken);
        await loadDataResource()
      }).catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(accessTokenRequest);
        }
        authStore.isFirstLoading = false;
        authStore.logged = false;
        console.log(error);
      })
    }
  }, [inProgress, instance, loadDataResource, momentValue]);



  return (
    <ConfigProvider locale={antdValue}>
      {isFirstLoading ? <Loading /> : (<Router history={history} >
        <Switch>
          <Route path="/secure" component={Secure} />
          <Route path="/" component={Login} />
        </Switch>
      </Router>)}
    </ConfigProvider>
  );
};
export default withMobx(AppContainer);
