


import React, { useEffect, useState } from 'react';
import { withMobx } from '~/common/hoc';
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Select as AntdSelect } from 'antd';
import { Box, Button } from "@material-ui/core";
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';

// @material-ui/icons
import Info from "@material-ui/icons/Info";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Apartment from "@material-ui/icons/Apartment";
import { useHistory } from "react-router-dom";
import { authStore } from '~/stores/auth';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox'
import { Spin, Modal } from 'antd';
import masterDataService from "~/services/master-data.service";
// core components
import DataGridEditable from "~/components/DataGrid/DataGridEditable";

import { Loading3QuartersOutlined } from '@ant-design/icons';
import styles from "~/assets/jss/material-dashboard-react/components/viewDetailStyle";

import { Link, Element } from 'react-scroll'

import { useFormik } from 'formik';
import * as yup from 'yup';


import { languageStore } from '~/stores/language';
import { masterDataStore } from '~/stores/master-data';
import { userActionStore } from '~/stores/user-config';
import userConfigService from '~/services/user-config.service';
import { toastService } from '~/services';
import { toJS } from 'mobx';

const useStyles = makeStyles(styles);


const UserConfigDetail = (props) => {
  const { Option } = AntdSelect;
  const { userSessionDto } = authStore;
  const classes = useStyles();
  const { translate } = languageStore;
  const { userReq, save,
    isLoadingView, isLoading, loadData,
    data, req,
    total,
    type,
    update, removeSegmentByUser
  } = userActionStore;

  const { loadListJobTitle, loadListGroupRole, loadListSegmentHotel, loadListItemGroup, itemGroups, jobTitles, segmentHotels, groupRoles } = masterDataStore;
  let itemGroupFilter = itemGroups;
  const formik = useFormik({
    initialValues: {
      ...userReq,
    },
    validationSchema: yup.object({
      companyCode: yup.string().required(`${translate("[UserConfigDetail]Please input your company code")}!`),
      employeeCode: yup.string().required(`${translate("[UserConfigDetail]Please input your employee code")}!`),
      fullName: yup.string().required(`${translate("[UserConfigDetail]Please input your full name")}!`),
      jobTitleCode: yup.string().required(`${translate("[UserConfigDetail]Please input your job title")}!`),
      department: yup.string().required(`${translate("[UserConfigDetail]Please input department")}!`),
      email: yup.string().required(`${translate("[UserConfigDetail]Please input email")}!`).email(translate("[UserConfigDetail]Email invalid")),
      groups: yup.array().min(1, `${translate("[UserConfigDetail]Please select group role")}!`).required(`${translate("[UserConfigDetail]Please select group role")}!`),
    }),
    onSubmit: (values) => {

      console.log('-------------------');
      console.log(values, groupRoles.map(v => toJS(v)));
      console.log('-------------------');
      const { jobTitleCode, groups } = values;
      const { jobTitleName } = jobTitles.find(v => v.jobTitleCode === jobTitleCode);

      const dataGrs = groups.filter(v => groupRoles.find(t => t.groupRoleCode === v)).map(v => {
        const { groupRoleName } = groupRoles.find(t => t.groupRoleCode === v)
        return {
          groupRoleCode: v,
          groupRoleName
        }
      })
      save({
        ...values,
        jobTitleName,
        groups: dataGrs,
        segments: data.map(v => v.segment)
      });
    },
  });

  const updateItems = async (items) => {
    try {
      userActionStore.isLoading = true;
      if (type === "ADD") {
        let cloned = { ...data };
        items.map((item) => {
          const { segment, segmentName } = item;
          if (!data.find(v => v.segment === segment)) {
            cloned = [{
              segment,
              segmentName
            }].concat(cloned);
          }
        })
        userActionStore.data = cloned.filter(i => i.segment);
      } else {
        await Promise.all(items.map(async (item) => {
          const { segment, segmentName } = item;
          await masterDataService.saveOrgranizationMatrix({
            segment: segment,
            email: userReq.email
          });

          if (!userActionStore.data.find(v => v.segment === segment)) {
            userActionStore.data = [{
              segment,
              segmentName
            }].concat(userActionStore.data);
            userActionStore.total = userActionStore.total + 1;
          }
        }));
      }
      userActionStore.isLoading = false;
      toastService.success();
    } catch (error) {
      userActionStore.isLoading = false;
      toastService.handleError(error);
    }
  }

  const toggleChecked = async (event) => {
    const { name, checked } = event.target;
    if (type === "ADD") {
      formik.setFieldValue(name, checked)
      return;
    }
    formik.setFieldValue(name, checked)
    try {
      userActionStore.isLoadingView = true;
      await userConfigService.changeStatus({
        email: userReq.email,
        active: checked
      })
      userActionStore.isLoadingView = false;
      toastService.success();
    } catch (error) {
      userActionStore.isLoadingView = false;
      formik.setFieldValue(name, !checked)
      toastService.handleError(error);
    }
  }
  const history = useHistory();
  useEffect(() => {
    loadListJobTitle();
    loadListGroupRole();
    loadListSegmentHotel();
    loadListItemGroup();
  }, [loadListJobTitle, loadListGroupRole, loadListSegmentHotel, loadListItemGroup])
  useEffect(() => {

    loadData();
  }, [loadData])


  const formItemStyle = { margin: 8, width: "45%" };
  if (!userSessionDto?.isMaster) {
    history.push({
      pathname: `/secure/dashboard`,
    });
  }

  const cancel = () => {
    history.push({
      pathname: `/secure/user-config`,
    });
  }

  const jobs = [{
    jobTitleCode: "", jobTitleName: "Chọn chức danh",
  }].concat(jobTitles.map(v => {
    return {
      jobTitleCode: v.jobTitleCode,
      jobTitleName: v.jobTitleName
    }
  }));
  let jobFilters = jobs;

  const roles = groupRoles.map(v => {
    return {
      groupRoleCode: v.groupRoleCode,
      groupRoleName: v.groupRoleName
    }
  })

  const columns = [
    {
      title: 'Mã Cơ Sở',
      field: 'segment',
      editable: 'never'
    },
    {
      title: 'Tên Cở Sở',
      field: 'segment',
      lookup: segmentHotels.reduce((result, item) => {
        result[item.segment] = item.segmentName;
        return result
      }, {}),
      editComponent: (tableData) => {
        return <Select value={tableData.value || ''} onChange={(e) => {
          tableData.onChange(String(e.target.value));
        }}>
          {segmentHotels.map((type) => <MenuItem key={type.segment} value={type.segment}>{type.segmentName}</MenuItem>)}
        </Select>;
      }
    },
  ]

  const onChangePage = (pageIndex, pageSize) => {
    if (pageSize !== req.pageSize) {
      req.pageIndex = 1;
    } else {
      req.pageIndex = Number(pageIndex) + 1;
    }
    req.pageSize = 1000;
    loadData();
  }

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [segmentModals, setsegmentModals] = React.useState([]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setIsModalVisible(false);
    if (segmentModals.length > 0) {
      var items = [];
      var hasSegments = data.map(i => i.segment);
      segmentModals.forEach(i => {
        if (!hasSegments.includes(i)) {
          items.push({
            segment: i,
            segmentName: i
          })
        }
      });
      updateItems(items);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className={classes.root}>

      <Grid container>
        <Grid item xs={2} sm={2}>
          <Paper className={classes.paper}>
            <ul className={classes.navLinkContainer}>
              <li className={classes.tabs}><Link activeClass="active" to="information" spy={true} smooth={true}>Thông tin chung</Link></li>
              <li className={classes.tabs}><Link to="role" spy={true} smooth={true}>Chọn nhóm phân quyền</Link></li>
              <li className={classes.tabs}><Link to="company" spy={true} smooth={true}>Cơ sở</Link></li>
            </ul>
          </Paper>
        </Grid>
        <Grid item xs={10} sm={10}>
          <Spin indicator={<Loading3QuartersOutlined style={{ fontSize: 40 }} spin />} spinning={isLoadingView} style={{ top: '50vh' }}>
            <form onSubmit={formik.handleSubmit}>
              <Paper className={classes.paper}>
                <div id="FormDetail">
                  {/* Information */}
                  <Element id="information" name="information">
                    <Box display="flex" p={1}>
                      <Box p={1} flexGrow={1} style={{ paddingTop: "14px" }}>
                        <Info style={{ verticalAlign: "middle" }} />
                        <h4 className={classes.title}>Thông Tin Chung</h4>
                      </Box>
                      <Box p={1}>
                        <FormGroup>
                          <FormControlLabel

                            control={<Switch name="active" checked={formik.values.active} onChange={toggleChecked} color="primary" />}
                            label="Trạng Thái" labelPlacement="start" name="status"
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                    <form noValidate autoComplete="off">
                      <div className={classes.margin}>
                        <TextField
                          style={formItemStyle}
                          className={classes.margin}
                          InputLabelProps={{ shrink: true, }}
                          margin="normal"
                          name="companyCode"
                          label={translate("[UserConfigDetail]Company code")}
                          required
                          placeholder={translate("[UserConfigDetail]Input company code")}
                          value={formik.values.companyCode}
                          onChange={formik.handleChange}
                          error={formik.touched.companyCode && Boolean(formik.errors.companyCode)}
                          helperText={formik.touched.companyCode && formik.errors.companyCode}

                        />
                        <TextField
                          style={formItemStyle}
                          className={classes.margin}
                          InputLabelProps={{ shrink: true, }}
                          margin="normal"
                          name="employeeCode"
                          label={translate("[UserConfigDetail]Employee code")}
                          required
                          placeholder={translate("[UserConfigDetail]Input employee code")}
                          value={formik.values.employeeCode}
                          onChange={formik.handleChange}
                          error={formik.touched.employeeCode && Boolean(formik.errors.employeeCode)}
                          helperText={formik.touched.employeeCode && formik.errors.employeeCode}

                        />
                      </div>

                      <div className={classes.margin}>
                        <TextField
                          style={formItemStyle}
                          className={classes.margin}
                          InputLabelProps={{ shrink: true, }}
                          margin="normal"
                          name="fullName"
                          label={translate("[UserConfigDetail]Full name")}
                          required
                          placeholder={translate("[UserConfigDetail]Input full name")}
                          value={formik.values.fullName}
                          onChange={formik.handleChange}
                          error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                          helperText={formik.touched.fullName && formik.errors.fullName}

                        />
                        <FormControl className={classes.margin} style={{ width: "45%" }}>
                          <InputLabel
                            error={formik.touched.jobTitleCode && Boolean(formik.errors.jobTitleCode)}
                            shrink id="jobTitleCode">
                            {translate("[UserConfigDetail]Job title")}
                          </InputLabel>
                          <AntdSelect
                            name="jobTitleCode"
                            style={{ width: '100%', marginTop: '16px' }}
                            className={classes.antSelectSelector}
                            allowClear
                            onChange={(newValue) => {
                              formik.setFieldValue('jobTitleCode', newValue)
                            }}
                            showSearch
                            filterOption={(input, option) => option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            value={formik.values.jobTitleCode}>
                            {jobFilters.map((item) => {
                              return <Option
                                key={item.jobTitleName} value={item.jobTitleCode}>{item.jobTitleName}</Option>
                            })}
                          </AntdSelect>
                          {/* <Select
                            // labelId="role"
                            // id="role" 
                            name="jobTitleCode"
                            value={formik.values.jobTitleCode}
                            onChange={formik.handleChange}
                            error={formik.touched.jobTitleCode && Boolean(formik.errors.jobTitleCode)}
                            displayEmpty
                            className={classes.selectEmpty}>
                            {jobs.map(item => {
                              return (
                                <MenuItem key={item.jobTitleCode} value={item.jobTitleCode}>{item.jobTitleName}</MenuItem>
                              )
                            })}
                          </Select> */}
                          {/* <FormHelperText error={formik.touched.fullName && Boolean(formik.errors.fullName)} >
                          {formik.touched.fullName && Boolean(formik.errors.fullName) ? formik.touched.fullName && formik.errors.fullName : ""}
                        </FormHelperText> */}
                        </FormControl>
                      </div>

                      <div className={classes.margin}>
                        <TextField
                          style={formItemStyle}
                          className={classes.margin}
                          InputLabelProps={{ shrink: true, }}
                          margin="normal"
                          name="email"
                          label={translate("[UserConfigDetail]Input login name")}
                          required
                          placeholder={translate("[UserConfigDetail]Input login name")}
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          error={formik.touched.email && Boolean(formik.errors.email)}
                          helperText={formik.touched.email && formik.errors.email}

                        />
                        <FormControl className={classes.margin} style={{ width: "45%" }}>
                          <InputLabel
                            error={formik.touched.jobTitleCode && Boolean(formik.errors.jobTitleCode)}
                            shrink id="isMaster">
                            {translate("[UserConfigDetail]Account type")}
                          </InputLabel>
                          <Select
                            name="isMaster"
                            value={formik.values.isMaster}
                            onChange={formik.handleChange}
                            error={formik.touched.jobTitleCode && Boolean(formik.errors.jobTitleCode)}
                            displayEmpty
                            className={classes.selectEmpty}>
                            <MenuItem key={'1'} value={true}>{translate("[UserConfigDetail]Root account")}</MenuItem>
                            <MenuItem key={'2'} value={false}>{translate("[UserConfigDetail]Normal account")}</MenuItem>
                          </Select>
                        </FormControl>
                      </div>

                      <div className={classes.margin}>

                        <FormControl className={classes.margin} style={{ width: "45%" }}>
                          <InputLabel
                            error={formik.touched.jobTitleCode && Boolean(formik.errors.jobTitleCode)}
                            shrink id="department">
                            {translate("[UserConfigDetail]Department")}
                          </InputLabel>
                          <AntdSelect
                            name="department"
                            style={{ width: '100%', marginTop: '16px' }}
                            className={classes.antSelectSelector}
                            allowClear
                            onChange={(newValue) => {
                              formik.setFieldValue('department', newValue)
                            }}
                            showSearch
                            filterOption={(input, option) => option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            value={formik.values.department}>
                            {itemGroupFilter.map((item) => {
                              return <Option
                                key={item.itemGroupName} value={item.itemGroupCode}>{item.itemGroupName}</Option>
                            })}
                          </AntdSelect>
                          {/* <Select
                            // labelId="role"
                            // id="role" 
                            name="department"
                            value={formik.values.department}
                            onChange={formik.handleChange}
                            error={formik.touched.department && Boolean(formik.errors.department)}
                            displayEmpty
                            className={classes.selectEmpty}>
                            {itemGroups.map(item => {
                              return (
                                <MenuItem key={item.itemGroupCode} value={item.itemGroupCode}>{item.itemGroupName}</MenuItem>
                              )
                            })}
                          </Select> */}
                          {/* <FormHelperText error={formik.touched.fullName && Boolean(formik.errors.fullName)} >
                          {formik.touched.fullName && Boolean(formik.errors.fullName) ? formik.touched.fullName && formik.errors.fullName : ""}
                        </FormHelperText> */}
                        </FormControl>
                      </div>

                    </form>
                  </Element>

                  {/* Role */}
                  <Element id="role" name="role">
                    <Box display="flex" p={1}>
                      <Box p={1} flexGrow={1} style={{ paddingTop: "14px" }}>
                        <PersonAdd style={{ verticalAlign: "middle" }} />
                        <h4 className={classes.title}>Quyền Truy Cập</h4>
                      </Box>
                    </Box>
                    <form noValidate autoComplete="off">
                      <div className={classes.margin}>
                        <FormControl className={classes.formControl} style={{ width: "45%" }}>
                          <InputLabel
                            error={formik.touched.jobTitleCode && Boolean(formik.errors.jobTitleCode)}
                            shrink id="jobTitleCode">
                            {translate("[UserConfigDetail]User group")}
                          </InputLabel>
                          <Select
                            // labelId="role"
                            // id="role" 
                            multiple
                            name="groups"
                            value={formik.values.groups}
                            onChange={formik.handleChange}
                            error={formik.touched.groups && Boolean(formik.errors.groups)}
                            displayEmpty
                            // open={false}
                            // onblu
                            // renderValue={(selecteds) => {
                            //   //return selecteds.join(",");
                            //   const data = roles.filter(v => selecteds.includes(v.groupRoleCode)).map(v => v.groupRoleName);
                            //   return data.join(",");
                            // }}
                            renderValue={(selecteds) => (
                              <div style={{ display: 'flex', flexWrap: 'wrap', }}>
                                {roles.filter(v => selecteds.includes(v.groupRoleCode)).map(v => (
                                  <Chip size="small" key={v.groupRoleCode} variant="outlined" label={v.groupRoleName}
                                    clickable
                                    onDelete={() => { }}
                                    deleteIcon={<DoneIcon style={{ color: "green" }} />} />
                                ))}

                              </div>
                            )
                            }
                            className={classes.selectEmpty}>
                            {roles.map((item, idx) => {
                              return (
                                <MenuItem key={item.groupRoleCode + "" + idx} value={item.groupRoleCode}>
                                  <Checkbox color="primary" checked={formik.values.groups.indexOf(item.groupRoleCode) > -1} />
                                  <ListItemText primary={item.groupRoleName} />
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </div>



                    </form>
                  </Element>

                  {/* Company */}
                  <Element id="company" name="company">
                    <Box display="flex" p={1}>
                      <Box p={1} flexGrow={1} style={{ paddingTop: "14px" }}>
                        <Apartment style={{ verticalAlign: "middle" }} />
                        <h4 className={classes.title}>Cơ Sở</h4>
                      </Box>
                    </Box>
                    <Modal title="Thêm cơ sở" visible={isModalVisible} onOk={handleOk} okText="Chọn" onCancel={handleCancel}>
                      <AntdSelect
                        style={{ width: '100%' }}
                        mode="multiple"
                        allowClear
                        placeholder="Chọn cơ sở"
                        maxTagCount="responsive"
                        onChange={(newValue) => {
                          setsegmentModals(newValue);
                        }}
                        value={segmentModals}>
                        {segmentHotels.map((item, index) => {
                          return <Option key={index} value={item.segment}>{item.segmentName}</Option>
                        })}
                      </AntdSelect>
                    </Modal>
                    <Button variant="outlined" color="primary" className={classes.margin} type="button" onClick={showModal}>
                      Thêm
                    </Button>
                    <DataGridEditable
                      // onRowAdd={showModal}
                      // totalCount={total}
                      onRowUpdate={update}
                      onRowDelete={async (item) => {
                        if (type === "ADD") {
                          const { segment, segmentName } = item;
                          userActionStore.data = data.filter(v => v.segment !== segment)
                        } else {
                          removeSegmentByUser(item);
                        }
                      }}
                      paging={false}
                      isLoading={isLoading}
                      columns={columns}
                      data={data}
                      onChangePage={onChangePage}
                      action="company" />
                  </Element>
                </div>
                {/* Submit */}
                <Box display="flex" flexDirection="row-reverse" p={1} m={1}>
                  <Box p={1}>
                    <Button variant="outlined" color="primary" className={classes.margin} onClick={cancel}>
                      Hủy
                    </Button>
                    <Button variant="contained" color="primary" type="submit" className={classes.margin}>
                      Lưu
                    </Button>
                  </Box>
                </Box>
              </Paper>
            </form>
          </Spin>
        </Grid>

      </Grid>


    </div>
  );
}



export default withMobx(UserConfigDetail)