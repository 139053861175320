
import { Anchor, Row, Col, Form, Input, Button, Select, Table, Spin, Divider, Modal } from 'antd';
import { toJS } from 'mobx';
import React, { useEffect, useState } from 'react';
import { withMobx } from '~/hooks';

import { languageStore } from '~/stores/language';
import { masterDataStore } from '~/stores/master-data';
import { userActionStore } from '~/stores/user-config';


// @material-ui/icons
import Info from "@material-ui/icons/Info";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Apartment from "@material-ui/icons/Apartment";

import { PlusOutlined, } from '@ant-design/icons';

const { Link } = Anchor;


const UserAction = (props) => {

  const pageSizeOptions = ['10', '20', '50', '100']
  const { translate } = languageStore;
  const { userReq, save, isLoadingView, isLoading, loadData,
    data, total, req, isLoadingModal, isModalVisible, showModal,
    type,
    handleCancel, saveOrgranizationMatrix, loadListGroupRoleByUser } = userActionStore;

  const { loadListJobTitle, loadListGroupRole, loadListSegmentHotel, jobTitles, segmentHotels, groupRoles } = masterDataStore;

  const divStyle = {
    // overflowY: "scroll",
    padding: '40px',
    height: 'calc(100vh - 200px)',
    // boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
  };

  const anchorData = [
    {
      id: "1", title: "Thông tin chung",
    },
    {
      id: "2", title: "Chọn nhóm phân quyền",
    },
    {
      id: "3", title: "Cơ sở",
    },

  ]




  const columns = [
    {
      title: translate("[UserAction]Segment code"),
      dataIndex: 'segment',
      key: 'segment',
    },
    {
      title: translate("[UserAction]Segment name"),
      dataIndex: 'segmentName',
      key: 'segmentName',
    },

  ];

  const [form] = Form.useForm();
  const onFinish = async (values) => {
    const { jobTitleCode, groups } = values;
    const { jobTitleName } = jobTitles.find(v => v.jobTitleCode === jobTitleCode);
    const dataGrs = groups.map(v => {
      const { groupName } = groupRoles.find(t => t.groupCode === v)
      return {
        groupCode: v,
        groupName
      }
    })

    save({
      ...values,
      jobTitleName,
      groups: dataGrs,
      segments: data.map(v => v.segment)
    });
  }


  useEffect(() => {
    form.setFieldsValue({
      ...userReq
    })
  }, [])

  useEffect(() => {
    loadListJobTitle();
    loadListGroupRole();
    loadListSegmentHotel()
  }, [loadListJobTitle, loadListGroupRole, loadListSegmentHotel])
  useEffect(() => {
    loadListGroupRoleByUser();
    loadData();

  }, [loadListGroupRoleByUser, loadData])

  const onChange = (event) => {
    const { name, value } = event.target
    Object.assign(userReq, {
      [name]: value
    })
  }

  const jobs = [{
    jobTitleCode: "", jobTitleName: "Chọn chức danh",
  }].concat(jobTitles.map(v => {
    return {
      jobTitleCode: v.jobTitleCode,
      jobTitleName: v.jobTitleName
    }
  }))

  const roles = groupRoles.map(v => {
    return {
      groupCode: v.groupCode,
      groupName: v.groupName
    }
  })

  const segments = [{
    segment: "", segmentName: "Chọn cơ sở",
  }].concat(segmentHotels.map(v => {
    return {
      segment: v.segment,
      segmentName: v.segmentName
    }
  }))

  const onChangeSelect = (segment) => {
    userActionStore.segment = segment;
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (selected) => {
    console.log('selectedRowKeys changed: ', selected);
    setSelectedRowKeys(selected);
    // setSelectedRowKeys(Array.from(new Set(selectedRowKeys.concat(selected))))
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: 50
  };

  const handleOk = () => {
    saveOrgranizationMatrix()
  };


  return (

    <div >
      <Spin spinning={isLoadingView}>
        <Row >
          <Col span={4} style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
            <div style={{ marginTop: 10 }}>
              <Anchor
                style={{ marginLeft: 20 }}
                affix={false}
                getContainer={() => document.getElementById("content-scroll-layout")}
                showInkInFixed={true}

              >
                {anchorData.map(item => (
                  <Link
                    key={item.id} href={`#${item.id}`}
                    title={<h6>{item.title}</h6>}
                  />
                ))}
              </Anchor>
            </div>
          </Col>
          <Col span={20} >
            <div id="content-scroll-layout" style={divStyle}>
              <Form
                size="large"
                layout="vertical"
                onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              >
                <div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Info style={{ verticalAlign: "middle" }} />
                    <h5 style={{ paddingLeft: 10 }} id={anchorData[0].id}>{`${anchorData[0].title}`}</h5>
                  </div>
                  <Row gutter={[10, 0]}>
                    <Col span={12}>
                      <Form.Item

                        label="Mã công ty"
                        name="companyCode"
                        initialValue={userReq.companyCode}
                        rules={[{ required: true, message: 'Please input your company code!' }]}
                      >
                        <Input onChange={onChange} name="companyCode" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Mã nhân viên"
                        name="employeeCode"
                        initialValue={userReq.employeeCode}
                        rules={[{ required: true, message: 'Please input your employee code!' }]}
                      >
                        <Input onChange={onChange} name="employeeCode" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Họ và tên"
                        name="fullName"
                        initialValue={userReq.fullName}
                        rules={[{ required: true, message: 'Please input your full name!' }]}
                      >
                        <Input onChange={onChange} name="fullName" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        rules={[{ required: true, message: translate('Please select title!') }]}
                        name="jobTitleCode"
                        label={translate("Title")}
                        hasFeedback
                        initialValue={userReq.jobTitleCode}
                      >
                        <Select defaultValue={""} placeholder={translate("Please select title")}>
                          {jobs.map(item => <Select.Option key={JSON.stringify(item)} value={item.jobTitleCode}>{item.jobTitleName}</Select.Option>)}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Email"
                        name="email"
                        initialValue={userReq.email}

                        rules={[{ required: true, message: 'Please input your email!' }]}
                      >
                        <Input onChange={onChange} name="email" />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>


                <div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <PersonAdd style={{ verticalAlign: "middle" }} />
                    <h5 style={{ paddingLeft: 10, }} id={anchorData[1].id}>{`${anchorData[1].title}`}</h5>
                  </div>
                  <Row gutter={[10, 0]}>
                    <Col span={12}>
                      <Form.Item
                        rules={[{ required: true, message: translate('Please select group role!') }]}
                        name="groups"
                        label={translate("Group role")}
                        hasFeedback
                        initialValue={userReq.groups}
                      >
                        <Select mode="multiple" placeholder={translate("Please select group role")}>
                          {roles.map(item => <Select.Option key={JSON.stringify(item)} value={item.groupCode}>{item.groupName}</Select.Option>)}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Apartment style={{ verticalAlign: "middle" }} />
                    <h5 style={{ paddingLeft: 10, }} id={anchorData[2].id}>{`${anchorData[2].title}`}</h5>
                  </div>
                  <div style={{ display: "flex", justifyContent: "flex-end", paddingRight: 20 }}>
                    <Button onClick={showModal} icon={<PlusOutlined />} type="primary">{translate("[CommonAction]Add")}</Button>
                  </div>
                  <Divider type="vertical" />
                  <Table
                    size="large" className="table-striped" loading={isLoading} columns={columns}
                    dataSource={data}
                    rowSelection={rowSelection}
                    rowKey={(record, index) => record.id}
                    pagination={{
                      defaultPageSize: Number(pageSizeOptions[0]),
                      defaultCurrent: 1,
                      showQuickJumper: true,
                      showSizeChanger: true,
                      pageSizeOptions: pageSizeOptions,
                      total,
                      onShowSizeChange: (pageIndex, pageSize) => {
                        req.pageIndex = pageIndex;
                        req.pageSize = pageSize;
                        loadData();
                      },
                      onChange: async (pageIndex, pageSize) => {
                        req.pageIndex = pageIndex;
                        req.pageSize = pageSize;
                        loadData();
                      }
                    }}>
                  </Table>
                  <Modal title={translate("Chọn cơ sở")} visible={isModalVisible} okText={translate("[Common]Save")} onOk={handleOk} onCancel={handleCancel}>
                    <Select style={{ width: '100%' }} onChange={onChangeSelect} defaultValue={""} placeholder={translate("Please select group role")}>
                      {segments.map(item => <Select.Option key={JSON.stringify(item)} value={item.segment}>{item.segmentName}</Select.Option>)}
                    </Select>
                  </Modal>
                </div>
                <Divider type="vertical" />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button type="primary" htmlType="submit" size="large" >
                    SAVE
                  </Button>
                </div>
                <Divider type="vertical" />
                <Divider type="vertical" />
              </Form>

            </div>
          </Col>
        </Row>
      </Spin>
    </div>


  )
}

export default withMobx(UserAction);