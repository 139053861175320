import { rootApiService } from "./@global";
const Enpoint = {
  list: "/portal/api/business/users/list",
  save: "/portal/api/business/users/save",
  changeStatus: "/portal/api/business/users/change-status",
  removeSegmentByUser: "/portal/api/business/users/remove-segment-by-user",
  delete: "/portal/api/business/users/delete",
  listTemplateByUserSegment: "/portal/api/business/users/list-template-by-user-segment",
};
export class UserConfigService {
  async list(params) {
    return rootApiService.get(Enpoint.list, params);
  }

  async save(body) {
    return rootApiService.post(Enpoint.save, body);
  }

  changeStatus = ({ email, active }) => {
    return rootApiService.post(Enpoint.changeStatus, { email, active });
  }
  removeSegmentByUser = ({ email, segment }) => {
    return rootApiService.delete(Enpoint.removeSegmentByUser, { email, segment });
  }

  delete = ({ email }) => {
    return rootApiService.delete(Enpoint.delete, { email });
  }


  async listTemplateByUserSegment({ pageIndex = 1, pageSize = 10, email, segment }) {
    return rootApiService.get(Enpoint.listTemplateByUserSegment, {
      pageIndex,
      pageSize,
      email,
      segment
    });
  }
}
export default new UserConfigService();
