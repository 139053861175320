import { makeAutoObservable, toJS } from "mobx"
import { ApiException } from "~/@core/dto";
import { EPeriodType } from "~/common/enums";
import { toastService } from "~/services";
import fileReportService from "~/services/file-report.service";
import masterDataService from "~/services/master-data.service";
import userConfigService from "~/services/user-config.service";
import { authStore } from "../auth";
import { languageStore } from "../language";



class ModalUploadDto {
  constructor() {
    makeAutoObservable(this)
  }
  visible = false;
  isLoading = false;
  open = () => {
    this.visible = true;
  }
  close = () => {
    this.visible = false;
  }
}

class TemplateHistoryDto {
  constructor() {
    makeAutoObservable(this)
  }
  isLoading = true;
  data = [];
  total = 0;
  loadData = async ({ pageIndex = 1, pageSize = 100, type = "all", templateCode = "", segment="" }) => {
    this.isLoading = true;
    try {
      const { data, total } = await fileReportService.listTemplateHistory({
        segment,
        templateCode,
        pageIndex,
        pageSize,
        type
      });
      this.data = data;
      this.total = total;
    } catch (error) {
      toastService.handleError(error);
    }
    this.isLoading = false;
  }
}

class UploadTemplateReq {
  constructor() {
    makeAutoObservable(this)
  }

  segment = "";
  type = "";
  period = "";
  periodType = "";
  templateCode = "";
  file = "";


  getPickerType = () => {
    if (this.periodType === EPeriodType.date) {
      return "date";
    }
    if (this.periodType === EPeriodType.month) {
      return "month";
    }

    if (this.periodType === EPeriodType.year) {
      return "year";
    }
    return "date";
  }

}

export class ErrorFileDto {
  constructor() {
    makeAutoObservable(this)
  }
  urlDownload = "";
  total = 0;
  errors = 0;

  get success() {
    return Number(this.total) - Number(this.errors)
  }
  get templateName() {
    if (!this.urlDownload) {
      return ""
    }
    const arr = decodeURI(this.urlDownload).split("/")
      .reduce((result, item) => {
        if (item.includes("?")) {
          result = result.concat(item.split("?"))
        } else {
          result.push(item);
        }
        return result
      }, []);
    if (arr.length === 0) {
      return ""
    }
    const fileName = arr.find(v => v.includes(".xlsx"))
    if (!fileName) {
      return ""
    }
    const output = fileName.replace("?", "");
    if (output.length > 20) {
      return output.substr(0, 17) + "...";
    }
    return output;
  }
}


export class MessageErrorDto {
  constructor() {
    makeAutoObservable(this);
  }
  file = "";
  messageServer = ""

}


class FormReportStore {
  constructor() {
    makeAutoObservable(this);
  }

  messageErrorDto = new MessageErrorDto();

  keySearch = "";


  onChangeKeySearch = (event) => {
    const { value = "" } = event.target;
    this.keySearch = value;
  }

  templateCodeSelected = "";

  modalUploadDto = new ModalUploadDto();
  templateHistoryDto = new TemplateHistoryDto();
  uploadTemplateReq = new UploadTemplateReq();

  isLoading = false;

  errorFileDto = new ErrorFileDto();




  fileList = [];
  setFileList = (fileList = []) => {
    this.fileList = fileList;
  }

  listTemplate = [];
  listSegment = [];

  listTemplateFilter = [];


  get segmentName() {
    try {
      const { segment = "" } = this.uploadTemplateReq
      const segmentSelect = this.listSegment.find(v => v.segment === segment);
      if (segmentSelect) {
        return segmentSelect.segmentName
      }
      return ""
    } catch (error) {
      return "";
    }
  };

  loadListTemplate = async () => {
    try {
      formReportStore.keySearch = "";
      const { userSessionDto = {} } = authStore;
      const { segment } = this.uploadTemplateReq
      const { data } = await userConfigService.listTemplateByUserSegment({
        pageIndex: 1,
        pageSize: 1000,
        email: userSessionDto?.email,
        segment
      })
      this.listTemplate = data;
      // this.listTemplateFilter = data;
      this.filterListTemplate()
    } catch (error) {
      this.listTemplate = []
    }
  }

  filterListTemplate = () => {
    if (!this.keySearch) {
      this.listTemplateFilter = this.listTemplate;
    }
    this.listTemplateFilter = this.listTemplate.filter(v => v.templateName.removeAccents().includes(this.keySearch.removeAccents()))

  }

  loadListSegment = async () => {
    try {
      this.isLoading = true;
      const { userSessionDto = {} } = authStore;
      const res = await masterDataService.listOrgranizationMatrix({
        pageIndex: 1,
        pageSize: 1000,
        email: userSessionDto?.email || ""
      })
      this.listSegment = res.data;
      this.isLoading = false;
    } catch (error) {
      toastService.handleError(error);
      this.isLoading = false;
    }
  }
  dowloadTemplate = async (templateCode) => {
    this.templateHistoryDto.isLoading = true;
    try {
      const { data } = await fileReportService.dowloadTemplate(templateCode);
      if (!data || !data.url) {
        throw new ApiException("Empty url download")
      }
      // this.urlDownload = data.url;
      window.location.assign(data.url);

      this.templateHistoryDto.isLoading = false;
      toastService.success();
    } catch (err) {
      this.templateHistoryDto.isLoading = false;
      toastService.handleError(err);
    }
  }

  dowloadTemplateHistory = async (id) => {
    this.templateHistoryDto.isLoading = true;
    try {
      const { data } = await fileReportService.dowloadTemplateHistory(id);
      if (!data || !data.url) {
        throw new ApiException("Empty url download")
      }
      // this.urlDownload = data.url;
      window.location.assign(data.url);
      // fileReportService.dowloadUrl({
      //   url: data.url,
      //   fileName: "abc.xlsx"
      // })
      this.templateHistoryDto.isLoading = false;
      toastService.success();
    } catch (err) {
      this.templateHistoryDto.isLoading = false;
      // toastService.handleError(err);
    }
  }



  handleChangeDataType = (type) => {
    this.uploadTemplateReq.type = type;
  }

  handleChangeTemplate = (templateCode) => {
    this.uploadTemplateReq.templateCode = templateCode;
    if (!templateCode) {
      this.uploadTemplateReq.periodType = "";
    }
    if (templateCode) {
      try {
        const slt = this.listTemplate.find(v => v.templateCode === templateCode);
        this.uploadTemplateReq.periodType = slt.periodType;
      } catch (error) {
      }
    }
  }

  pipePeriodType = ({ periodType, period }) => {
    if (periodType === EPeriodType.date) {
      return period
    }
    if (periodType === EPeriodType.month) {
      return period + "-1";
    }
    if (periodType === EPeriodType.year) {
      return period + "-1-1";
    }
    return period;
  }

  checkDuplicate = 0;

  verifyAndUpload = async () => {
    const { translate } = languageStore;
    this.messageErrorDto = new MessageErrorDto();
    this.modalUploadDto.isLoading = true;
    try {
      const { period, templateCode, type, periodType, segment = "" } = this.uploadTemplateReq;
      if (!this.fileList || this.fileList.length === 0) {
        this.messageErrorDto.file = translate("[Common]Please select file upload");
        this.modalUploadDto.isLoading = false;
        return;
      }

      if (this.checkDuplicate === 0) {
        const { data, isSuccess } = await fileReportService.duplicateCheck({
          period: this.pipePeriodType({ periodType, period }),
          segmentCode: segment,
          templateCode,
          type
        });
        if (Boolean(data.is_duplicated)) {
          this.checkDuplicate = 1;
          this.modalUploadDto.isLoading = false;
        } else {
          this.checkDuplicate = 2;
          try {
            const fmData = new FormData();
            const { period, templateCode, type, periodType } = this.uploadTemplateReq
            this.fileList.forEach(file => {
              fmData.append("file", file)
            })
            fmData.append("segmentCode", segment);
            fmData.append("period", this.pipePeriodType({ periodType, period }));
            fmData.append("type", type);
            fmData.append("templateCode", templateCode);
            const { data, isSuccess } = await fileReportService.uploadTemplate(fmData);
            this.modalUploadDto.isLoading = false;
            this.templateHistoryDto.loadData({
              templateCode: this.templateCodeSelected,
              pageIndex: 1,
              pageSize: 100,
              type: "all"
            });
            this.modalUploadDto.close();
            toastService.success();
          } catch (err) {
            if (err.errors
              && err.errors.errorCode === "ERR_INVALID_FILE"
              && err.errors.data
              && err.errors.data.url
            ) {
              const { errors = {} } = err;
              const { errors: qtyError, total, url: urlDownload } = errors.data;
              this.errorFileDto.errors = qtyError;
              this.errorFileDto.total = total;
              this.errorFileDto.urlDownload = urlDownload;
            } else {
              // toastService.handleError(err);
              const messageServer = err.message || "Unknown";
              this.messageErrorDto.messageServer = translate(messageServer);
            }
            this.modalUploadDto.isLoading = false;
          }
        }
      } else {

        try {
          const fmData = new FormData();
          const { period, templateCode, type, periodType } = this.uploadTemplateReq
          this.fileList.forEach(file => {
            fmData.append("file", file)
          })
          fmData.append("segmentCode", segment);
          fmData.append("period", this.pipePeriodType({ periodType, period }));
          fmData.append("type", type);
          fmData.append("templateCode", templateCode);
          const { data, isSuccess } = await fileReportService.uploadTemplate(fmData);
          this.modalUploadDto.isLoading = false;
          this.templateHistoryDto.loadData({
            templateCode: this.templateCodeSelected,
            pageIndex: 1,
            pageSize: 100,
            type: "all"
          });
          this.modalUploadDto.close();
          toastService.success();
        } catch (err) {
          if (err.errors
            && err.errors.errorCode === "ERR_INVALID_FILE"
            && err.errors.data
            && err.errors.data.url
          ) {
            const { errors = {} } = err;
            const { errors: qtyError, total, url: urlDownload } = errors.data;
            this.errorFileDto.errors = qtyError;
            this.errorFileDto.total = total;
            this.errorFileDto.urlDownload = urlDownload;
          } else {
            const messageServer = err.message || "Unknown";
            this.messageErrorDto.messageServer = translate(messageServer);
            // toastService.handleError(err);

          }
          this.modalUploadDto.isLoading = false;
        }
      }


    } catch (error) {
      this.modalUploadDto.isLoading = false;
      toastService.handleError(error);
    }
  }

  upload = async () => {
    this.modalUploadDto.isLoading = true;
    try {
      const fmData = new FormData();
      const { period, templateCode, type, periodType, segment = "" } = this.uploadTemplateReq
      this.fileList.forEach(file => {
        fmData.append("file", file)
      })
      fmData.append("segmentCode", segment);
      fmData.append("period", this.pipePeriodType({ periodType, period }));
      fmData.append("type", type);
      fmData.append("templateCode", templateCode);
      const { data, isSuccess } = await fileReportService.uploadTemplate(fmData);
      this.modalUploadDto.isLoading = false;
      toastService.success();
    } catch (err) {
      this.modalUploadDto.isLoading = false;
      toastService.handleError(err);
    }
  }

  onChangeDate = (date, dateString) => {
    this.uploadTemplateReq.period = dateString;
  }


}

export const formReportStore = new FormReportStore()