import { makeAutoObservable } from "mobx"
import { toastService } from "~/services";
import powerbiService from "~/services/powerbi.service";
export class ReportItem {
  reportId = "";
  embedUrl = "";
  name = "";
  nameLv1 = "";
  nameLv2 = "";
  nameLv3 = "";
  defineName = "";
  constructor() {
    makeAutoObservable(this);
  }
}


class ManagementReportStore {
  constructor() {
    makeAutoObservable(this);
  }
  visible = true;
  dataSource = [];
  nameLv1Active = "";
  nameLv2Active = "";
  isLoading = false;


  selectedItem = new ReportItem();


  loadData = async () => {
    this.isLoading = true;
    try {
      const res = await powerbiService.listReport();
      this.dataSource = res;
      this.nameLv1Active = this.listNameLv1[0];
      this.nameLv2Active = this.listNameLv2[0] || "";

      this.isLoading = false;
      await new Promise(resolve => {
        setTimeout(() => {
          resolve("")
        }, 200);
      })
      this.showDrawer();
    } catch (error) {
      toastService.handleError(error);
      this.isLoading = false;
    }
  }


  onChangeTab = (activeKey) => {
    this.nameLv1Active = activeKey;
    this.nameLv2Active = this.listNameLv2[0] || "";

  }

  showDrawer = () => {
    this.visible = true;
  };

  onClose = () => {
    this.visible = false;
  };

  get dataLv1() {
    return this.dataSource.filter(v => v.nameLv1)
  }

  get listNameLv1() {
    var listNames = Array.from(new Set(this.dataLv1.map(v => v.nameLv1)));
    if(listNames.length == 0){
      return listNames;
    }
    const index = [1, 2, 0];
    const output = index.map(i => listNames[i]);
    return output;
    //return Array.from(new Set(this.dataLv1.map(v => v.nameLv1)));
  }



  get dataLv2() {
    if (!this.nameLv1Active) {
      return []
    }
    return this.dataLv1.filter(v => v.nameLv1 === this.nameLv1Active)
  }
  get listNameLv2() {
    return Array.from(new Set(this.dataLv2.map(v => v.nameLv2)))
  }

  get dataLv3() {
    if (!this.nameLv1Active || !this.nameLv2Active) {
      return []
    }
    return this.dataLv2.filter(v => v.nameLv2 === this.nameLv2Active)
  }

  selectedReport = async (item) => {
    this.selectedItem = item;
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve("");
      }, 200);
    })
    this.onClose();
  }

  selectedNameLv2 = (nameLv2) => {
    this.nameLv2Active = nameLv2;
  }


}

export const managementReportStore = new ManagementReportStore()