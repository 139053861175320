import React, { } from 'react'
import { Provider as MobxProvider } from "mobx-react";
import { Switch, Route, useHistory } from "react-router-dom";
import { stores } from './stores';
import AppContainer from "./AppContainer";
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./NavigationClient";

const App = ({ pca }) => {
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);
  return (
    <MobxProvider {...stores}>
      <MsalProvider instance={pca}>
        <AppContainer />
      </MsalProvider>
    </MobxProvider>
  );
};
export default App;

