import React, { useState, useEffect } from 'react';
import "./index.less";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { optionalApiService, rootApiService } from '~/services';
import { KeyLocalStore } from '~/common/constants';
const ExampleView = () => {


  const [state, setState] = useState({
    accessToken: "",
    embedUrl: "",
    reportId: ""
  })


  const execute = async () => {
    const { embedUrl, reportId, accessToken } = await rootApiService.get("/portal/api/business/mock/report-powerbi")
    setState({
      reportId,
      embedUrl,
      accessToken
    })
  }

  useEffect(() => {
    execute()
  }, [])

  return (
    <div style={{ width: '100%', height: '80vh' }}>
      {state.accessToken && <PowerBIEmbed
        embedConfig={{
          type: 'report',   // Supported types: report, dashboard, tile, visual and qna
          id: state.reportId,
          embedUrl: state.embedUrl,
          accessToken: localStorage.getItem(KeyLocalStore.accessToken),
          tokenType: models.TokenType.Aad,
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: true
              }
            },
          }
        }}

        eventHandlers={
          new Map([
            ['loaded', function () { console.log('Report loaded'); }],
            ['rendered', function () { console.log('Report rendered'); }],
            ['error', function (event) { console.log(event.detail); }]
          ])
        }

        cssClassName={"powerbi-container"}
        getEmbeddedComponent={(embeddedReport) => {
          window.report = embeddedReport;
        }}
      />}
    </div>
  );
};

export default ExampleView;