import { makeAutoObservable } from "mobx";

export class HistoryReportReq {
  constructor() {
    makeAutoObservable(this);
  }
  pageIndex = 1;
  pageSize = 10;
  type = "";
  templateCode = "";
  user = "";
  period_start = "";
  period_end = "";
  segment = "";
  isUpload="";
  only_lastest="";
}