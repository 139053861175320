import { makeAutoObservable, observable } from "mobx"
import fileReportService from "~/services/file-report.service";
import { toastService } from "~/services";
import { HistoryReportReq } from "~/dto";
import { ApiException } from "~/@core/dto";


class DataReconciliationStore {

  constructor() {
    makeAutoObservable(this);
  }
  isLoading = false;
  data = [];
  total = 0;
  req = new HistoryReportReq();
  selectedItem = undefined;
  loadData = async () => {
    this.isLoading = true;
    var request = {
      pageIndex: this.req.pageIndex,
      pageSize: this.req.pageSize
    };
    try {
      if (!this.req.type) {
        request.type = "all";
      }
      if (this.req.templateCode) {
        request.templateCode = this.req.templateCode;
      }
      if (this.req.user) {
        request.user = this.req.user;
      }
      if (this.req.period_start) {
        request.period_start = this.req.period_start;
      }
      if (this.req.period_end) {
        request.period_end = this.req.period_end;
      }
      if (this.req.segment) {
        request.segment = this.req.segment;
      }
      if (this.req.isUpload || this.req.isUpload == 0) {
        request.isUpload = this.req.isUpload;
      }
      if (this.req.only_lastest || this.req.only_lastest == 0) {
        request.only_lastest = this.req.only_lastest;
      }
      
      const { data, total } = await fileReportService.listTemplateHistoryReport(request);
      this.data = data;
      this.total = total;
    } catch (error) {
      toastService.handleError(error);
    }
    this.isLoading = false;
  }

  mergeTemplateHistory = async (obj) => {
    this.isLoading = true;
    try {
      const { data } = await fileReportService.mergedDownload(obj);
      if (!data || !data.url) {
        throw new ApiException("Empty url download")
      }
      window.location.assign(data.url);
      this.isLoading = false;
      toastService.success();
    } catch (err) {
      this.isLoading = false;
      toastService.handleError(err);
    }
  }

  dowloadTemplateHistory = async (id) => {
    this.isLoading = true;
    try {
      const { data } = await fileReportService.dowloadTemplateHistory(id);
      if (!data || !data.url) {
        throw new ApiException("Empty url download")
      }
      // this.urlDownload = data.url;
      window.location.assign(data.url);
      // fileReportService.dowloadUrl({
      //   url: data.url,
      //   fileName: "abc.xlsx"
      // })
      this.isLoading = false;
      toastService.success();
    } catch (err) {
      this.isLoading = false;
      // toastService.handleError(err);
    }
  }

}

export const dataReconciliationStore = new DataReconciliationStore();