import React, { useState, useEffect, useRef } from "react";
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import { Table, Switch, Button, Divider, Form, Input, Col, Row, PageHeader, Select, Tag, DatePicker, Checkbox, TreeSelect } from 'antd';
import IconButton from '@material-ui/core/IconButton';
import { PlusOutlined, FilterOutlined, CloseOutlined, SearchOutlined, RollbackOutlined, DownloadOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import { masterDataStore } from '~/stores/master-data';
import { languageStore } from "~/stores/language";
import { withMobx } from "~/hooks";
import { dataAggregationStore } from "~/stores/data-aggregation";

import styles from "../../assets/jss/material-dashboard-react/components/userConfigStyle.js";
import moment from "moment";
const { RangePicker } = DatePicker;
const useStyles = makeStyles(styles);
const DataAggregation = () => {
  const { Option } = Select;
  const { translate } = languageStore;
  const { loadListFormTemplate, loadKPITemplates, kpiTemplates, formTempates, loadMenuFormReport, menuFormReport, treeFormTemplates } = masterDataStore;
  const { isLoading, loadData, data, total, req } = dataAggregationStore;
  const classes = useStyles();
  const pageSizeOptions = ['10', '20', '50', '100']
  const [openFilter, setOpenFilter] = React.useState(false);
  const [heightContent, setHeightContent] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const ref = useRef(null);
  const history = useHistory();
  const [showExpandSearch, setShowExpandSearch] = useState(false);
  const showHideAdvance = () => {
    setShowExpandSearch(!showExpandSearch);
  }
  const [segmentsFilter, setSegmentsFilter] = React.useState([]);
  const [formTemplatesFilter, setFormTemplatesFilter] = React.useState([]);
  const [kpiTemplatesFilter, setkpiTemplatesFilter] = React.useState([]);
  const [month, setMonth] = React.useState(moment());
  const [upload_type, setUploadType] = React.useState(moment());
  const handleOnChange = event => {
    req.user = event.target.value;
  };

  const search = () => {
    loadData();
  }
  useEffect(() => {
    setHeightContent(ref.current.clientHeight);
  }, []);
  useEffect(() => {
    loadData();
    loadListFormTemplate();
    loadKPITemplates();
    loadMenuFormReport();
  }, [loadData, loadListFormTemplate, loadKPITemplates, loadMenuFormReport])


  const openFilterBox = (openFilter) => {
    setOpenFilter(openFilter);
  }


  const columns = [
    {
      dataIndex: "segment_code",
      key: "segment_code",
      title: "Cơ sở/Bộ phận",
      render: (value, record) => {
        var term = menuFormReport.find(i => i.segment == value);
        if (term) {
          return <span>{term.segment} - {term.name}</span>
        } else {
          return <span>{value}</span>
        }
      },
    },
    {
      dataIndex: "templateCode",
      key: "templateCode",
      title: "Template Code"
    },
    {
      dataIndex: "kpi_code",
      key: "kpi_code",
      title: "KPI code"
    },
    {
      dataIndex: "month",
      key: "month",
      title: "Tháng"
    },
    {
      dataIndex: "year",
      key: "year",
      title: "Năm"
    },
    {
      dataIndex: "input_data",
      key: "input_data",
      title: "Giá trị upload"
    },
    {
      dataIndex: "user",
      key: "user",
      title: "User upload"
    },
    {
      dataIndex: "upload_type",
      key: "upload_type",
      title: "Loại dữ liệu",
      render: (value) => {
        return <span>{value == "reconcile" ? "Đã đối soát" : value == "unreconcile" ? "Chưa đối soát" : ""}</span>
      },
    },
    {
      dataIndex: "createdDate",
      key: "createdDate",
      title: "Ngày upload"
    },
  ];

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };
  const scrollY = `calc(100vh - (${heightContent}px + 300px))`;


  const onSelectChange = (selected) => {
    console.log('selectedRowKeys changed: ', selected);
    setSelectedRowKeys(selected);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: 50,
  };
  return (
    <div>
      <div ref={ref} >
        <PageHeader style={{ padding: "0px 0px 20px 0px" }} title="Báo cáo tổng hợp dữ liệu upload"
          extra={[
            <Button icon={showExpandSearch ? <CloseOutlined /> : <FilterOutlined />} onClick={showHideAdvance}>
              {showExpandSearch ? "Đóng tìm kiếm" : "Tìm kiếm nâng cao"}
            </Button>,
            <Button icon={<DownloadOutlined />} onClick={() => {
              req.is_download = true;
              loadData();
            }}>
              Download excel
            </Button>,
          ]}
        ></PageHeader>
        {showExpandSearch ? <Form layout="vertical" {...formItemLayout}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Cơ sở/Bộ phận">
                <TreeSelect
                  style={{ width: '100%' }}
                  treeCheckable={true}
                  multiple={true}
                  placeholder="Chọn cơ sở/bộ phận"
                  filterTreeNode={(search, item) => {
                    return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                  }}
                  value={segmentsFilter}
                  allowClear
                  onChange={(newValue) => {
                    setSegmentsFilter(newValue);
                    req.segment_code = newValue.length > 0 ? newValue.join(",") : "";
                  }}
                  maxTagCount="responsive"
                  treeData={treeFormTemplates}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Chỉ tiêu">
                <Select
                  mode="multiple"
                  allowClear
                  value={kpiTemplatesFilter}
                  placeholder="Chọn chỉ tiêu"
                  onChange={(newValue) => {
                    req.kpi_code = newValue.length > 0 ? newValue.join(",") : "";
                    var templates = kpiTemplates.filter(x => newValue.includes(x.kpiCode)).map(x => x.templateCode);
                    req.templateCode = templates.length > 0 ? [...new Set(templates)].join(",") : "";
                    setFormTemplatesFilter([...new Set(templates)]);
                    setkpiTemplatesFilter(newValue);
                  }}
                  maxTagCount="responsive"
                >
                  {kpiTemplates.map((item, index) => {
                    return <Option key={index} value={item.kpiCode}>{item.kpiCode}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Template">
                <Select
                  mode="multiple"
                  allowClear
                  value={formTemplatesFilter}
                  placeholder="Chọn template"
                  onChange={(newValue) => {
                    req.templateCode = newValue.length > 0 ? newValue.join(",") : "";
                    setFormTemplatesFilter(newValue);
                  }}
                  maxTagCount="responsive"
                >
                  {formTempates.map((item, index) => {
                    return <Option key={index} value={item.templateCode}>{item.templateCode} - {item.templateName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="User">
                <Input placeholder="Nhập email" name="keyword" value={req.user} onKeyPress={event => {
                  if (event.key === 'Enter') {
                    req.is_download = null;
                    loadData();
                  }
                }}
                  onChange={handleOnChange}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Tháng">
                <DatePicker value={month} style={{ width: '100%' }} onChange={(value, dateString) => {
                  setMonth(value);
                  req.month = value != null ? value.toDate().getMonth() + 1 : null;
                  req.year = value != null ? value.year() : null;
                }} picker="month" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                style={{ width: '100%' }}
                label={translate("[FormReportView]Data type")}
                name="DataType"
              >
                <Select
                  style={{ width: '100%' }}
                  defaultValue="" onChange={(value) => {
                    setUploadType(value);
                    req.upload_type = value
                  }}>
                  <Option value={""}>{translate("[FormReportView]Select data type")}</Option>
                  <Option value={"reconcile"}>{translate("[FormReportView]Reconcile")}</Option>
                  <Option value={"unreconcile"}>{translate("[FormReportView]Unreconcile")}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label=" ">
                <Button icon={<SearchOutlined />} onClick={() => {
                  req.pageIndex = 1;
                  req.is_download = null;
                  search();
                }} type="primary">{translate("[Button]Search")}</Button>
                <Button icon={<RollbackOutlined />} onClick={() => {
                  setFormTemplatesFilter([]);
                  setMonth(moment());
                  setkpiTemplatesFilter([]);
                  setSegmentsFilter([]);
                  setUploadType("");
                  req.month = moment().month() + 1;
                  req.year = moment().year();
                  req.segment_code = "";
                  req.templateCode = "";
                  req.kpi_code = "";
                  req.is_download = null;
                  req.pageIndex = 1;
                  req.upload_type = "";
                  search();
                }} style={{ marginLeft: 12 }}></Button>
              </Form.Item>
            </Col>
          </Row>
        </Form> : null}
        <div style={{ width: '100%' }} >
          <div className={classes.container}>
            <Row style={{ width: '100%', height: '100%' }}>
              <Table
                size="large" className="table-striped" scroll={{ y: scrollY }} loading={isLoading} columns={columns}
                dataSource={data}
                // rowSelection={rowSelection}
                rowKey={(record, index) => record.id}
                pagination={{
                  defaultPageSize: Number(pageSizeOptions[0]),
                  defaultCurrent: 1,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  pageSizeOptions: pageSizeOptions,
                  total,
                  onShowSizeChange: (pageIndex, pageSize) => {
                    req.pageIndex = pageIndex;
                    req.pageSize = pageSize;
                    req.is_download = null;
                    loadData();
                  },
                  onChange: async (pageIndex, pageSize) => {
                    req.pageIndex = pageIndex;
                    req.pageSize = pageSize;
                    req.is_download = null;
                    loadData();
                  }
                }}>
              </Table>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withMobx(DataAggregation);