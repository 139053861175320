import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import { Button } from '@material-ui/core';

// @material-ui/icons
import FilterList from '@material-ui/icons/FilterList';

// core components
import SearchBar from "material-ui-search-bar";

import styles from "../../assets/jss/material-dashboard-react/components/filterStyle.js";

const useStyles = makeStyles(styles);

export default function Filter(props) {
  const classes = useStyles();
  const { openFilter, openFilterBox } = props;

  const handleOpenFilter = () => {
    openFilterBox(!openFilter);
  }

  return (
    <div className={classes.container}>
      <div className={classes.searchBar}>
        <SearchBar placeholder="Search by keywords..." />
      </div>
      <div className={classes.filterBar}>
        <Button variant="outlined" color="primary" className={classes.btnFilter} onClick={handleOpenFilter}>
          <FilterList />
        </Button>
      </div>
    </div>

  );
}

Filter.propTypes = {
  openFilter: PropTypes.bool,
  openFilterBox: PropTypes.func
};
