const viewDetailStyle = (theme) => ({
  root: {
    flexGrow: 1,
    "& h6": {
      display: "none"
    }
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  navLinkContainer: {
    width: "200px",
    "list-style": "none",
    padding: "0"
  },
  tabs: {
    padding: "5px 15px",
    borderLeft: "1px solid #ccc",
    cursor: "pointer"
  },
  margin: {
    margin: theme.spacing(1),
  },
  title: {
    display: "inline-block",
    verticalAlign: "middle",
    margin: "0 10px"
  },
  btnAdd: {
    textTransform: "none",
    width: "100px",
    margin: "0 10px"
  },
  pr5: {
    paddingRight: "5px" 
  },
  formControl: {
    margin: theme.spacing(1)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  antSelectSelector:{
    borderBottom:'1px solid rgba(0, 0, 0, 0.42) !important',
    borderTop:'hidden',
    borderLeft:'hidden',
    borderRight:'hidden',
  }
});

export default viewDetailStyle;