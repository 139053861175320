

import { rootApiService } from "./@global";
const Enpoint = {
  list: "/portal/api/business/report/list",

};
class ReportService {
  async list() {
    return rootApiService.get(Enpoint.list);
  }
}

export default new ReportService();