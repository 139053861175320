import React from "react";
import {
  UnorderedListOutlined,
  FileDoneOutlined,
  FullscreenExitOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { Card, Col, Row } from 'antd';
import { useHistory } from "react-router";
import { authStore } from '~/stores/auth';

export default function Dashboard() {
  const history = useHistory();
  const { userSessionDto } = authStore;
  const gotoView = (path) => {
    history.push(path)
  }

  return (
    <div style={{ background: "#ececec", width: '100%', height: '100%', padding: 20 }}>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Card title="Báo cáo quản trị">
            <Row justify="center">
              <a href="/secure/management-report">
                <FileDoneOutlined style={{ color: "#3f51b5", fontSize: 200 }} />
              </a>
            </Row>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Báo cáo đối soát">
            <Row justify="center">
              <a href="/secure/data-reconciliation">
                <FileDoneOutlined style={{ color: "#3f51b5", fontSize: 200 }} />
              </a>
            </Row>
          </Card>
        </Col>
        {/* <Col span={8}>
          <Card title="Chỉ tiêu">
            <Row justify="center">
              <FullscreenExitOutlined style={{ color: "#3f51b5", fontSize: 200 }} />
            </Row>
          </Card>
        </Col> */}
        {<Col span={8}>
          <Card title="Biểu mẫu">
            <Row justify="center">
              <UnorderedListOutlined style={{ color: "#3f51b5", fontSize: 200 }} />
            </Row>
          </Card>
        </Col>}
        {userSessionDto?.isMaster ? <Col span={8}>
          <Card title="Người dùng">
            <Row justify="center">
              <a href="/secure/user-config">
                <UserOutlined style={{ color: "#3f51b5", fontSize: 200 }} />
              </a>
            </Row>
          </Card>
        </Col> : ''}
        {userSessionDto?.isMaster ? <Col span={8}>
          <Card title="Nhóm người dùng">
            <Row justify="center">
              <a href="/secure/group-role">
                <UsergroupAddOutlined style={{ color: "#3f51b5", fontSize: 200 }} />
              </a>
            </Row>
          </Card>
        </Col> : ''}
      </Row>
    </div>
  );
}
