import { makeAutoObservable } from "mobx";
import masterDataService from "~/services/master-data.service";



class MasterDataStore {



  constructor() {
    makeAutoObservable(this);
  }
  jobTitles = [];
  segmentHotels = [];
  groupRoles = [];

  menuFormReport = [];

  formTempates = [];

  treeMenuTemplates = [];

  treeFormTemplates = [];

  itemGroups = [];

  kpiTemplates = [];

  loadKPITemplates = async () => {
    const res = await masterDataService.listKPITemplate();
    this.kpiTemplates = res;
  }

  loadListJobTitle = async () => {
    const res = await masterDataService.listJobTitle();
    this.jobTitles = res;
  }


  loadListItemGroup = async () => {
    const res = await masterDataService.listItemGroup();
    this.itemGroups = res;
  }

  loadListSegmentHotel = async () => {
    const res = await masterDataService.listSegmentHotel();
    this.segmentHotels = res;
  }
  loadListGroupRole = async () => {
    const res = await masterDataService.listGroupRole();
    this.groupRoles = res;
  }


  loadListFormTemplate = async () => {
    const res = await masterDataService.listFormTemplate();
    this.formTempates = res;
  }

  loadListTreeMenuFormTemplate = async () => {
    const res = await masterDataService.listTreeMenuFormTemplate();
    this.treeMenuTemplates = res;
  }

  makeTreeData = (data = []) => {
    const outputs = [];
    for (const item of data) {
      const {
        name = "",
        segment = "",
        children = []
      } = item;
      if (children.length) {
        const listChild = this.makeTreeData(children)
        outputs.push({
          title: name,
          children: listChild,
          value: segment,
        })
      } else {
        outputs.push({
          title: name,
          value: segment,
        })
      }
    }
    return outputs;
  }




  loadMenuFormReport = async () => {
    const res = await masterDataService.listMenuFormReport();
    this.menuFormReport = res;
    this.treeFormTemplates = this.makeTreeData(res);
  }



}

export const masterDataStore = new MasterDataStore()