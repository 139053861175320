import { makeAutoObservable } from "mobx";
import { ApiException } from "~/@core/dto";
import { OrgranizationMatrixReq, UserReq } from "~/dto";
import { toastService } from "~/services";
import masterDataService from "~/services/master-data.service";
import userConfigService from "~/services/user-config.service";
import { masterDataStore } from "../master-data";
import { userConfigStore } from "./user-config.store";



class UserActionStore {
  constructor() {
    makeAutoObservable(this);
  }

  isLoadingView = false;

  type = "ADD";
  userReq = new UserReq();


  isLoading = false;

  isLoadingModal = false;
  data = [];
  total = [];

  req = new OrgranizationMatrixReq();
  isModalVisible = false;

  segment = "";

  showModal = () => {
    this.isModalVisible = true;
  };

  handleCancel = () => {
    this.isModalVisible = false;
  };


  save = async (userReq) => {
    this.isLoadingView = true;
    try {
      await userConfigService.save(userReq);
      this.isLoadingView = false;
      toastService.success();
    } catch (error) {
      this.isLoadingView = false;
      toastService.handleError(error);
    }
  }


  loadData = async () => {
    try {
      this.isLoading = true;
      const { pageIndex = 1, pageSize = 1000 } = this.req
      const res = await masterDataService.listOrgranizationMatrix({
        pageIndex,
        pageSize,
        email: this.userReq.email || ""
      })
      this.data = res.data;
      this.total = res.total;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  }

  update = async (item) => {
    try {
      this.isLoading = true;
      const { segment, segmentName } = item;
      const res = await masterDataService.saveOrgranizationMatrix({
        segment: segment,
        email: this.userReq.email
      });

      if (!this.data.find(v => v.segment === segment)) {
        this.data = [{
          segment,
          segmentName
        }].concat(this.data);
        this.total = this.total + 1;
      }

      this.isLoading = false;
      toastService.success();
    } catch (error) {
      this.isLoading = false;
      toastService.handleError(error);
    }
  }



  saveOrgranizationMatrix = async () => {
    try {
      if (!this.segment) {
        throw new ApiException("Vui lòng chọn cơ sở");
      }
      if (this.type === "ADD") {
        const { segment } = this;
        const { segmentName } = masterDataStore.segmentHotels.find(v => v.segment === segment);

        if (!this.data.find(v => v.segment === segment)) {
          this.data = this.data.concat([{
            segment,
            segmentName
          }])
        }
        this.total = this.data.length;
        toastService.success();
        this.isModalVisible = false;
      } else {

        this.isLoadingModal = true;
        const res = await masterDataService.saveOrgranizationMatrix({
          segment: this.segment,
          email: this.userReq.email
        });
        this.isLoadingModal = false;
        toastService.success();
        this.isModalVisible = false;
        this.loadData();

      }
    } catch (error) {
      this.isLoadingModal = false;
      toastService.handleError(error);
    }
  }

  removeSegmentByUser = async (item) => {
    try {
      this.isLoading = true;
      const { segment, segmentName } = item;
      const res = await userConfigService.removeSegmentByUser({
        segment: segment,
        email: this.userReq.email
      });
      this.data = this.data.filter(v => v.segment !== segment);
      this.total = this.total - 1;
      this.isLoading = false;
      toastService.success();
    } catch (error) {
      this.isLoading = false;
      toastService.handleError(error);
    }
  }
}

export const userActionStore = new UserActionStore();