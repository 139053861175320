import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Box, Button } from "@material-ui/core";

// @material-ui/icons
import FilterList from "@material-ui/icons/FilterList";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";

// core components
import Dropdown from './../Dropdown/Dropdown';
import DatePicker from "../DatePicker/DatePicker";


import styles from "../../assets/jss/material-dashboard-react/components/filterBoxStyle.js";

const useStyles = makeStyles(styles);

export default function FilterBox(props) {
  const classes = useStyles();
  const { filterData } = props;

  return (
    <Grid item xs={12} sm={12} style={{margin: "10px 0"}}>
      <Paper className={classes.paper}>
        <Box display="flex" p={1}>
          <Box p={1} flexGrow={1} style={{paddingTop: "14px"}}>
            <FilterList style={{verticalAlign: "middle"}}/>
            <h3 className={classes.title}>Bộ Lọc</h3>
          </Box>
          <Box p={1}>
            <Button color="primary" className={classes.btnFilter}>Xóa Tất Cả</Button>
            <Button variant="outlined" color="primary" className={classes.btnFilter}>
              <AddCircleOutline className={classes.pr5} />
              Thêm Bộ Lọc
            </Button>
          </Box>
        </Box>
        { filterData.map( (filter, index) => {
            return (
              <Box className={classes.boxContainer} key={index}>
                { filter.type === "dropdown"
                    ? <Dropdown item={filter} />
                    : null
                }

                { filter.type === "datetime_picker"
                    ? <DatePicker />
                    : null
                }
              </Box>
            );
          })
        }
      </Paper>
    </Grid>
  );
}

FilterBox.propTypes = {
  filterData: PropTypes.arrayOf(PropTypes.object)
};
