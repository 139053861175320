import React from "react";
import PropTypes from 'prop-types';

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

// @material-ui/icons
// core components


import styles from "../../assets/jss/material-dashboard-react/components/formStyle.js";

const useStyles = makeStyles(styles);

export default function CompanyForm(props) {
  const classes = useStyles();
  const { res, onCloseDialog } = props;
  const [form, setForm] = React.useState({
    detail: {
      id_company: '',
      status: false,
      name_company: '',
      location: '',
      description: ''
    }
  });
  
  React.useEffect( () => {
      initForm();
  }, [res]);

  const initForm = () => {
    res && setForm(...[res]);
    console.log(form);
  }

  const handleChangeText = (event) => {
    setForm(prevState => {
      let key = event.target.id;
      let detail = Object.assign({}, prevState.detail);
      detail[key] = event.target.value;                 
      return { detail };  
    });
  }

  const toggleChecked = (event) => {
    setForm(prevState => {
      let detail = Object.assign({}, prevState.detail);
      detail.status = event.target.checked;                 
      return { detail };  
    });
  }

  const handleChangeLocation = (event) => {
    setForm(prevState => {
      let detail = Object.assign({}, prevState.detail);
      detail.location = event.target.value;                 
      return { detail };  
    });
  }

  const handleSave = () => {
    console.log(form);
    onCloseDialog();
  }

  const handleClose = () => {
    onCloseDialog();
  }

  return (
    <Box className={classes.formContainer}>
      <form noValidate  autoComplete="off">
        <div className={classes.line}>
          <TextField id="id_company" label="Mã Cơ Sở" style={{ margin: 8,width: "60%" }} required
            placeholder="Nhập mã cơ cở" margin="normal" InputLabelProps={{shrink: true,}} value={form.detail.id_company} onChange={handleChangeText} />
          <FormGroup className={classes.switch}>
            <FormControlLabel
              control={<Switch checked={form.status} onChange={toggleChecked} color="primary" />}
              label="Active" labelPlacement="start" name="status"
            />
          </FormGroup>
        </div>
        <div className={classes.line}>
          <TextField id="name_company" label="Tên Cơ Sở" style={{ margin: 8,width: "100%" }} required
            placeholder="Nhập tên cơ cở" margin="normal" InputLabelProps={{shrink: true,}} value={form.detail.name_company} onChange={handleChangeText} />
        </div>
        <div className={classes.line} style={{padding: "7px"}}>
          <FormControl className={classes.margin} style={{width: "100%"}}>
            <InputLabel shrink id="GroupTarget">
              Khu Vực Sở Hữu
            </InputLabel>
            <Select labelId="GroupTarget" id="GroupTarget" value={form.detail.location} onChange={handleChangeLocation} displayEmpty className={classes.selectEmpty}>
              <MenuItem value="">Chọn Khu Vực Sở Hữu</MenuItem>
              <MenuItem value={'nhatrang'}>VinpearlLand Nha Trang</MenuItem>
              <MenuItem value={'danang'}>VinpearlLand Da Nang</MenuItem>
              <MenuItem value={'phuquoc'}>VinpearlLand Phu Quoc</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={classes.line}>
          <label className={classes.label}>Miêu Tả</label>
          <TextareaAutosize maxRows={4} aria-label="empty textarea" placeholder="Nhập Miêu Tả" className={classes.textarea} value={form.detail.description} />
        </div>
      </form>
      <Box className={classes.footerDialog}>
        <Button variant="contained" color="primary" onClick={handleSave} className={classes.btn}>
          Lưu
        </Button>
        <Button variant="outlined" color="primary" onClick={handleClose} className={classes.btn}>
          Hủy
        </Button>
      </Box>
    </Box>
  );
}

CompanyForm.propTypes = {
  res: PropTypes.object,
  onCloseDialog: PropTypes.func
};
