import React, { useEffect, useState, useRef } from 'react'
import { Table, Switch, Button, Divider, Form, Input, Col, Row, PageHeader, Select, Popconfirm } from 'antd';


import { PlusOutlined, FilterOutlined, CloseOutlined, SearchOutlined, RollbackOutlined } from '@ant-design/icons';
// @material-ui/icons
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import BorderColor from '@material-ui/icons/BorderColor';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { useFormik } from 'formik';

import { languageStore } from '~/stores/language';
import { userActionStore } from '~/stores/user-config';
import userConfigService from '~/services/user-config.service';
import { toastService } from '~/services';
// core components
import { withMobx } from '~/hooks';
import { useHistory } from 'react-router-dom';
import { authStore } from '~/stores/auth';
import { groupRoleDetailStore, groupRoleListStore } from '~/stores/group-role';
import groupRoleService from '~/services/group-role.service';
import { GroupRoleDto } from '~/dto';
import { toJS } from 'mobx';

const GroupRoleView = (props) => {
  const pageSizeOptions = ['10', '20', '50', '100']
  const { translate } = languageStore;

  const { data = [], total = 0, loadData, req, isLoading, deleteGroupRole } = groupRoleListStore;
  const { Option } = Select;
  const [showExpandSearch, setShowExpandSearch] = useState(false);
  const showHideAdvance = () => {
    setShowExpandSearch(!showExpandSearch);
  }
  const { userSessionDto } = authStore;
  const [filter, setFilter] = useState({
    keyword: '', isActive: ''
  });
  const handleOnChange = event => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
    req.filter = filter;
  };
  const [heightContent, setHeightContent] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const ref = useRef(null);
  const history = useHistory();
  const gotoActionView = () => {
    history.push({
      pathname: `/secure/group-role/update`,
    });
  }
  const formik = useFormik({
    initialValues: {
      groupName: String,
      status: String
    },
  });
  const changeStatus = () => {

  }
  const handleEdit = async (item) => {
    console.log('-------------------');
    console.log(toJS(item));
    console.log('-------------------');
    const groupRoleDto = new GroupRoleDto();
    groupRoleDetailStore.groupRoleDto = Object.assign(groupRoleDto, item)
    groupRoleDetailStore.type = "EDIT";
    gotoActionView()
  }
  const cancel = (e)=> {
    console.log(e);
  }
  const toggleStatus = async (record, checked, index) => {
    data[index].active = checked;
    groupRoleListStore.isLoading = true;
    try {
      await groupRoleService.changeStatus({
        groupRoleCode: record.groupRoleCode,
        active: checked
      })
      groupRoleListStore.isLoading = false;
      toastService.success();
    } catch (error) {
      groupRoleListStore.isLoading = false;
      data[index].active = !checked;
      toastService.handleError(error);
    }

  }

  if(!userSessionDto?.isMaster){
    history.push({
      pathname: `/secure/dashboard`,
    });
  }
  const columns = [
    {
      dataIndex: "groupRoleName",
      key: "groupRoleName",
      title: translate("[GroupRoleView]Group"),
      // render: (value, record, index) => {
      //     return <span>{moment(value).add('7', "hours").format('DD-MM-YYYY HH:mm:ss')}</span>;
      // }
    },

    {

      dataIndex: "active",
      key: "active",
      title: translate("[GroupRoleView]Status"),
      width: "200px",
      render: (value, record, index) => {
        return (
          <Switch defaultChecked={Boolean(record.active)} onChange={(checked) => toggleStatus(record, checked, index)} />
        )
      }
    },
    {
      key: "action",
      title: translate("[Common]Action"),
      width: "200px",
      render: (value, record, index) => {
        return (
          <span>
            <div>
              <IconButton aria-label="filter list" onClick={handleEdit.bind(null, record)}>
                <BorderColor />
              </IconButton>
              <IconButton aria-label="filter list">
                <Popconfirm
                  title="Bạn có chắn muốn xóa item này không?"
                  onConfirm={deleteGroupRole.bind(null, record)}
                  onCancel={cancel}
                  okText="Có"
                  cancelText="Không"
                  placement="topRight"
                >
                  <a href="#"><DeleteIcon /></a>
                </Popconfirm>
              </IconButton>
              {/* <IconButton aria-label="filter list">
                <RemoveCircleOutlineIcon onClick={deleteGroupRole.bind(null, record, index)} />

              </IconButton> */}
            </div>
          </span>
        )
      }
    }
  ];

  const search = () => {
    loadData();
  }
  useEffect(() => {
    req.filter = filter;
  }, [filter]);
  useEffect(() => {
    setHeightContent(ref.current.clientHeight);
  }, []);

  useEffect(() => {
    req.pageIndex = 1;
    req.filter = filter;
    loadData()
  }, [loadData, req]);

  const onSelectChange = (selected) => {
    // console.log('selectedRowKeys changed: ', selected);
    setSelectedRowKeys(selected);
    // setSelectedRowKeys(Array.from(new Set(selectedRowKeys.concat(selected))))
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: 50,
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div ref={ref}>
        <PageHeader style={{ padding: "0px 0px 20px 0px" }} title={translate("[MainMenu]Group user")}
          extra={[
            <Button icon={showExpandSearch ? <CloseOutlined /> : <FilterOutlined />} onClick={showHideAdvance}>
              {showExpandSearch ? "Đóng tìm kiếm" : "Tìm kiếm nâng cao"}
            </Button>,
            <Button onClick={async () => {
              groupRoleDetailStore.groupRoleDto = new GroupRoleDto();
              groupRoleDetailStore.reportCodeSelecteds = [];
              groupRoleDetailStore.type = "ADD";
              gotoActionView();
            }} icon={<PlusOutlined />} type="primary">{translate("[CommonAction]Add")}</Button>,
          ]}
        ></PageHeader>
        {showExpandSearch ? <Form layout="vertical" {...formItemLayout}>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item label={translate("[GroupRoleView]FilterGroup")}>
                <Input placeholder={translate("[GroupRoleView]FilterGroupPlaceHolder")} name="keyword" value={filter.keyword} onKeyPress={event => {
                  if (event.key === 'Enter') {
                    loadData();
                  }
                }}
                  onChange={handleOnChange}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={translate("[GroupRoleView]Status")}>
                <Select placeholder={translate("[GroupRoleView]FilterStatusPlaceHolder")} showSearch
                  name="isActive"
                  value={filter.isActive}
                  onChange={(value) => {
                    if (value) {
                      setFilter({ ...filter, ["isActive"]: value });
                    }
                  }}
                >
                  <Option value="true">Active</Option>
                  <Option value="false">Inactive</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label=" ">
                <Button icon={<SearchOutlined />} onClick={search} type="primary">{translate("[Button]Search")}</Button>
                <Button icon={<RollbackOutlined />} onClick={() => {
                  setFilter({ keyword: '', isActive: '' });
                  req.filter = {keyword: '', isActive: ''};
                  search();
                }} style={{ marginLeft: 12 }}></Button>
              </Form.Item>
            </Col>
          </Row>
        </Form> : null}
        <Table
          bordered
          size="large" className="table-striped"
          // scroll={{ y: scrollY }}
          loading={isLoading}
          columns={columns}
          dataSource={data}

          rowKey={(record, index) => record.id}
          pagination={{
            defaultPageSize: Number(pageSizeOptions[0]),
            defaultCurrent: 1,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: pageSizeOptions,
            total,
            onShowSizeChange: (pageIndex, pageSize) => {
              req.pageIndex = pageIndex;
              req.pageSize = pageSize;
              loadData();
            },
            onChange: async (pageIndex, pageSize) => {
              req.pageIndex = pageIndex;
              req.pageSize = pageSize;
              loadData();
            }
          }}>
        </Table>
      </div>
    </div>
  )
}

export default withMobx(GroupRoleView)
