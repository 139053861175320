import React, { useState, useEffect, useRef } from "react";
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import { Table, Switch, Button, Divider, Form, Input, Col, Row, PageHeader, Select, Tag, DatePicker, Checkbox, TreeSelect } from 'antd';
import IconButton from '@material-ui/core/IconButton';
import { PlusOutlined, FilterOutlined, CloseOutlined, SearchOutlined, RollbackOutlined, DownloadOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import { masterDataStore } from '~/stores/master-data';
import { languageStore } from "~/stores/language";
import { withMobx } from "~/hooks";
import { dataReconciliationStore } from "~/stores/data-reconciliation";

import styles from "../../assets/jss/material-dashboard-react/components/userConfigStyle.js";
const { RangePicker } = DatePicker;
const useStyles = makeStyles(styles);
const DataReconciliation = () => {
  const { Option } = Select;
  const { translate } = languageStore;
  const { loadListFormTemplate, loadKPITemplates, kpiTemplates, formTempates, loadMenuFormReport, menuFormReport, treeFormTemplates } = masterDataStore;
  const { isLoading, loadData, data, total, req, dowloadTemplateHistory, mergeTemplateHistory } = dataReconciliationStore;
  const classes = useStyles();
  const pageSizeOptions = ['10', '20', '50', '100']
  const [openFilter, setOpenFilter] = React.useState(false);
  const [heightContent, setHeightContent] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const ref = useRef(null);
  const history = useHistory();
  const [showExpandSearch, setShowExpandSearch] = useState(false);
  const showHideAdvance = () => {
    setShowExpandSearch(!showExpandSearch);
  }
  const [segmentsFilter, setSegmentsFilter] = React.useState([]);
  const [onlyLastestFilter, setOnlyLastestFilter] = React.useState([]);
  const [formTemplatesFilter, setFormTemplatesFilter] = React.useState([]);
  const [kpiTemplatesFilter, setkpiTemplatesFilter] = React.useState([]);
  const [periodDate, setPeriodDate] = React.useState();
  const [isUpload, setIsUpload] = React.useState();
  const [onlyLastest, setOnlyLastestd] = React.useState();
  const handleOnChange = event => {
    req.user = event.target.value;
  };

  const search = () => {
    loadData();
  }
  useEffect(() => {
    setHeightContent(ref.current.clientHeight);
  }, []);
  useEffect(() => {
    loadData();
    loadListFormTemplate();
    loadKPITemplates();
    loadMenuFormReport();
  }, [loadData, loadListFormTemplate, loadKPITemplates, loadMenuFormReport])


  const openFilterBox = (openFilter) => {
    setOpenFilter(openFilter);
  }

  const dowloadHistory = (row) => {
    dowloadTemplateHistory(row.id)
  };

  const downloadMergeTemplateHistoryType1 = () => {
    if (rowSelection.selectedRowKeys.length > 0) {
      var list_event = rowSelection.selectedRowKeys.join(",");
      mergeTemplateHistory({
        list_event: list_event,
        download_type: 1
      });
    }
    // mergeTemplateHistory(row.id)
  };

  const downloadMergeTemplateHistoryType0 = () => {
    if (rowSelection.selectedRowKeys.length > 0) {
      var list_event = rowSelection.selectedRowKeys.join(",");
      mergeTemplateHistory({
        list_event: list_event,
        download_type: 0
      });
    }
    // mergeTemplateHistory(row.id)
  };


  const columns = [
    {
      dataIndex: "user",
      key: "user",
      title: "User",
    },
    {
      dataIndex: "segment_code",
      key: "segment_code",
      title: "Cơ sở/Bộ phận",
      render: (value, record) => {
        var term = menuFormReport.find(i => i.segment == value);
        if (term) {
          return <span>{term.segment} - {term.name}</span>
        } else {
          return <span>{value}</span>
        }
      },
    },
    {
      dataIndex: "templateCode",
      key: "templateCode",
      title: "Template Code"
    },
    {
      dataIndex: "period",
      key: "period",
      title: "Kỳ Báo Cáo Tháng"
    },
    {
      title: translate("[Common]Status"),
      dataIndex: 'errorRecords',
      key: 'status',
      render: (value, record) => {
        if (value === 0) {
          return <Tag color="success">{translate("[Common]Success")}</Tag>
        }
        return <Tag color="error">{translate("[Common]Fail")}</Tag>
      }
    },
    {
      dataIndex: "updatedDate",
      key: "updatedDate",
      title: "Ngày cập nhật"
    },
    {
      key: "action",
      title: translate("[Common]Action"),

      width: "200px",
      render: (value, record) => {
        return (
          <span>
            <Button icon={<DownloadOutlined />} size={'middle'} onClick={dowloadHistory.bind(null, record)}>
              {translate("[FormReportView]Download form report")}
            </Button>
          </span>
        )
      }
    }
  ];

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };
  const scrollY = `calc(100vh - (${heightContent}px + 300px))`;


  const onSelectChange = (selected) => {
    console.log('selectedRowKeys changed: ', selected);
    setSelectedRowKeys(selected);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: 50,
  };
  return (
    <div>
      <div ref={ref} >
        <PageHeader style={{ padding: "0px 0px 20px 0px" }} title="Báo cáo đối soát dữ liệu"
          extra={[
            <Button icon={showExpandSearch ? <CloseOutlined /> : <FilterOutlined />} onClick={showHideAdvance}>
              {showExpandSearch ? "Đóng tìm kiếm" : "Tìm kiếm nâng cao"}
            </Button>,
            <Button icon={<DownloadOutlined />} onClick={downloadMergeTemplateHistoryType1}>
              Báo cáo tổng hợp
            </Button>,
            <Button icon={<DownloadOutlined />} onClick={downloadMergeTemplateHistoryType0}>
              Báo cáo chia sheet
            </Button>
          ]}
        ></PageHeader>
        {showExpandSearch ? <Form layout="vertical" {...formItemLayout}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Cơ sở/Bộ phận">
                <TreeSelect
                  style={{ width: '100%' }}
                  treeCheckable={true}
                  multiple={true}
                  placeholder="Chọn cơ sở/bộ phận"
                  filterTreeNode={(search, item) => {
                    return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                  }}
                  value={segmentsFilter}
                  allowClear
                  onChange={(newValue) => {
                    setSegmentsFilter(newValue);
                    req.segment = newValue.length > 0 ? newValue.join(",") : "";
                  }}
                  maxTagCount="responsive"
                  treeData={treeFormTemplates}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Chỉ tiêu">
                <Select
                  mode="multiple"
                  allowClear
                  value={kpiTemplatesFilter}
                  placeholder="Chọn chỉ tiêu"
                  onChange={(newValue) => {
                    var templates = kpiTemplates.filter(x => newValue.includes(x.kpiCode)).map(x => x.templateCode);
                    req.templateCode = templates.length > 0 ? [...new Set(templates)].join(",") : "";
                    setFormTemplatesFilter([...new Set(templates)]);
                    setkpiTemplatesFilter(newValue);
                  }}
                  maxTagCount="responsive"
                >
                  {kpiTemplates.map((item, index) => {
                    return <Option key={index} value={item.kpiCode}>{item.kpiCode}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Template">
                <Select
                  mode="multiple"
                  allowClear
                  value={formTemplatesFilter}
                  placeholder="Chọn template"
                  onChange={(newValue) => {
                    req.templateCode = newValue.length > 0 ? newValue.join(",") : "";
                    setFormTemplatesFilter(newValue);
                  }}
                  maxTagCount="responsive"
                >
                  {formTempates.map((item, index) => {
                    return <Option key={index} value={item.templateCode}>{item.templateCode} - {item.templateName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="User">
                <Input placeholder="Nhập email" name="keyword" value={req.user} onKeyPress={event => {
                  if (event.key === 'Enter') {
                    loadData();
                  }
                }}
                  onChange={handleOnChange}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Trạng thái">
                <Select placeholder="Chọn trạng thái" showSearch
                  value={isUpload}
                  onChange={(value) => {
                    setIsUpload(value);
                    req.isUpload = value;
                  }}
                >
                  <Option value="1">Thành công</Option>
                  <Option value="0">Thất bại</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item label="Kỳ báo cáo tháng">
                <RangePicker value={periodDate}
                  onChange={(value, dateString) => {
                    setPeriodDate(value);
                    req.period_start = dateString[0];
                    req.period_end = dateString[1];
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Dữ liệu upload mới nhất">
                <Checkbox onChange={(e) => {
                  req.only_lastest = e.target.checked ? "1" : "0";
                  if (e.target.checked) {
                    setOnlyLastestd(true)
                  } else {
                    setOnlyLastestd(null)
                  }
                }} checked={onlyLastest}></Checkbox>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label=" ">
                <Button icon={<SearchOutlined />} onClick={()=>{
                  req.pageIndex = 1;
                  search()
                  }} type="primary">{translate("[Button]Search")}</Button>
                <Button icon={<RollbackOutlined />} onClick={() => {
                  setIsUpload();
                  setFormTemplatesFilter([]);
                  setPeriodDate();
                  setkpiTemplatesFilter([]);
                  setSegmentsFilter([]);
                  setOnlyLastestd(null);
                  req.isUpload = "";
                  req.period_end = "";
                  req.period_start = "";
                  req.segment = "";
                  req.templateCode = "";
                  req.user = "";
                  req.only_lastest = "";
                  req.pageIndex = 1;
                  search();
                }} style={{ marginLeft: 12 }}></Button>
              </Form.Item>
            </Col>
          </Row>
        </Form> : null}
        <div style={{ width: '100%' }} >
          <div className={classes.container}>
            <Row style={{ width: '100%', height: '100%' }}>
              <Table
                size="large" className="table-striped" scroll={{ y: scrollY }} loading={isLoading} columns={columns}
                dataSource={data}
                rowSelection={rowSelection}
                rowKey={(record, index) => record.id}
                pagination={{
                  defaultPageSize: Number(pageSizeOptions[0]),
                  defaultCurrent: 1,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  pageSizeOptions: pageSizeOptions,
                  total,
                  onShowSizeChange: (pageIndex, pageSize) => {
                    req.pageIndex = pageIndex;
                    req.pageSize = pageSize;
                    loadData();
                  },
                  onChange: async (pageIndex, pageSize) => {
                    req.pageIndex = pageIndex;
                    req.pageSize = pageSize;
                    loadData();
                  }
                }}>
              </Table>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withMobx(DataReconciliation);