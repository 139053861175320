export var EHttpStatus;
(function (EHttpStatus) {
    EHttpStatus[EHttpStatus["CONTINUE"] = 100] = "CONTINUE";
    EHttpStatus[EHttpStatus["SWITCHING_PROTOCOLS"] = 101] = "SWITCHING_PROTOCOLS";
    EHttpStatus[EHttpStatus["PROCESSING"] = 102] = "PROCESSING";
    EHttpStatus[EHttpStatus["EARLYHINTS"] = 103] = "EARLYHINTS";
    EHttpStatus[EHttpStatus["OK"] = 200] = "OK";
    EHttpStatus[EHttpStatus["CREATED"] = 201] = "CREATED";
    EHttpStatus[EHttpStatus["ACCEPTED"] = 202] = "ACCEPTED";
    EHttpStatus[EHttpStatus["NON_AUTHORITATIVE_INFORMATION"] = 203] = "NON_AUTHORITATIVE_INFORMATION";
    EHttpStatus[EHttpStatus["NO_CONTENT"] = 204] = "NO_CONTENT";
    EHttpStatus[EHttpStatus["RESET_CONTENT"] = 205] = "RESET_CONTENT";
    EHttpStatus[EHttpStatus["PARTIAL_CONTENT"] = 206] = "PARTIAL_CONTENT";
    EHttpStatus[EHttpStatus["AMBIGUOUS"] = 300] = "AMBIGUOUS";
    EHttpStatus[EHttpStatus["MOVED_PERMANENTLY"] = 301] = "MOVED_PERMANENTLY";
    EHttpStatus[EHttpStatus["FOUND"] = 302] = "FOUND";
    EHttpStatus[EHttpStatus["SEE_OTHER"] = 303] = "SEE_OTHER";
    EHttpStatus[EHttpStatus["NOT_MODIFIED"] = 304] = "NOT_MODIFIED";
    EHttpStatus[EHttpStatus["TEMPORARY_REDIRECT"] = 307] = "TEMPORARY_REDIRECT";
    EHttpStatus[EHttpStatus["PERMANENT_REDIRECT"] = 308] = "PERMANENT_REDIRECT";
    EHttpStatus[EHttpStatus["BAD_REQUEST"] = 400] = "BAD_REQUEST";
    EHttpStatus[EHttpStatus["UNAUTHORIZED"] = 401] = "UNAUTHORIZED";
    EHttpStatus[EHttpStatus["PAYMENT_REQUIRED"] = 402] = "PAYMENT_REQUIRED";
    EHttpStatus[EHttpStatus["FORBIDDEN"] = 403] = "FORBIDDEN";
    EHttpStatus[EHttpStatus["NOT_FOUND"] = 404] = "NOT_FOUND";
    EHttpStatus[EHttpStatus["METHOD_NOT_ALLOWED"] = 405] = "METHOD_NOT_ALLOWED";
    EHttpStatus[EHttpStatus["NOT_ACCEPTABLE"] = 406] = "NOT_ACCEPTABLE";
    EHttpStatus[EHttpStatus["PROXY_AUTHENTICATION_REQUIRED"] = 407] = "PROXY_AUTHENTICATION_REQUIRED";
    EHttpStatus[EHttpStatus["REQUEST_TIMEOUT"] = 408] = "REQUEST_TIMEOUT";
    EHttpStatus[EHttpStatus["CONFLICT"] = 409] = "CONFLICT";
    EHttpStatus[EHttpStatus["GONE"] = 410] = "GONE";
    EHttpStatus[EHttpStatus["LENGTH_REQUIRED"] = 411] = "LENGTH_REQUIRED";
    EHttpStatus[EHttpStatus["PRECONDITION_FAILED"] = 412] = "PRECONDITION_FAILED";
    EHttpStatus[EHttpStatus["PAYLOAD_TOO_LARGE"] = 413] = "PAYLOAD_TOO_LARGE";
    EHttpStatus[EHttpStatus["URI_TOO_LONG"] = 414] = "URI_TOO_LONG";
    EHttpStatus[EHttpStatus["UNSUPPORTED_MEDIA_TYPE"] = 415] = "UNSUPPORTED_MEDIA_TYPE";
    EHttpStatus[EHttpStatus["REQUESTED_RANGE_NOT_SATISFIABLE"] = 416] = "REQUESTED_RANGE_NOT_SATISFIABLE";
    EHttpStatus[EHttpStatus["EXPECTATION_FAILED"] = 417] = "EXPECTATION_FAILED";
    EHttpStatus[EHttpStatus["I_AM_A_TEAPOT"] = 418] = "I_AM_A_TEAPOT";
    EHttpStatus[EHttpStatus["MISDIRECTED"] = 421] = "MISDIRECTED";
    EHttpStatus[EHttpStatus["UNPROCESSABLE_ENTITY"] = 422] = "UNPROCESSABLE_ENTITY";
    EHttpStatus[EHttpStatus["FAILED_DEPENDENCY"] = 424] = "FAILED_DEPENDENCY";
    EHttpStatus[EHttpStatus["TOO_MANY_REQUESTS"] = 429] = "TOO_MANY_REQUESTS";
    EHttpStatus[EHttpStatus["INTERNAL_SERVER_ERROR"] = 500] = "INTERNAL_SERVER_ERROR";
    EHttpStatus[EHttpStatus["NOT_IMPLEMENTED"] = 501] = "NOT_IMPLEMENTED";
    EHttpStatus[EHttpStatus["BAD_GATEWAY"] = 502] = "BAD_GATEWAY";
    EHttpStatus[EHttpStatus["SERVICE_UNAVAILABLE"] = 503] = "SERVICE_UNAVAILABLE";
    EHttpStatus[EHttpStatus["GATEWAY_TIMEOUT"] = 504] = "GATEWAY_TIMEOUT";
    EHttpStatus[EHttpStatus["HTTP_VERSION_NOT_SUPPORTED"] = 505] = "HTTP_VERSION_NOT_SUPPORTED";
})(EHttpStatus || (EHttpStatus = {}));
