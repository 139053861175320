
import './index.less';
import { Layout, Menu, Dropdown, Divider } from 'antd';
import { useMsal } from "@azure/msal-react";
import {
  LogoutOutlined, UserOutlined
} from '@ant-design/icons';
import { ReactComponent as ViSvg } from "~/assets/header/vietnam.svg";

import { ReactComponent as EnUsSvg } from "~/assets/header/en_US.svg";


import logo512 from "~/assets/images/avatar.png";
import { languageStore } from "~/stores/language";
import { authStore } from "~/stores/auth";
const { Header, Sider, Content } = Layout;

const Secureheader = (props) => {
  const { instance } = useMsal();
  const { translate, changeLanguage, localeKey } = languageStore;
  // const { logout } = authStore;


  const logout = () => {
    instance.logoutRedirect();
  }

  const changeLang = (menuInfo) => {
    changeLanguage(menuInfo.key);
  };
  const menu = (
    <Menu>
      <Menu.Divider />
      <Menu.Item key="1">
        <span>
          <UserOutlined />
          <span style={{ marginLeft: 5 }}>
            {localStorage.getItem("userSession")?JSON.parse(localStorage.getItem("userSession")).name:''}
          </span>
        </span>
      </Menu.Item>
      <Menu.Item key="2">
        <span>
          <LogoutOutlined />
          <span style={{ marginLeft: 5 }} onClick={logout}>
            {translate("[LoginView]Logout")}
          </span>
        </span>
      </Menu.Item>
    </Menu>
  );
  return (
    <Header className="header-container" >
      <div style={{ display: 'flex', justifyContent: 'end', paddingRight: 20 }}>
        {/* <Dropdown
          trigger={["click"]}
          overlay={
            <Menu onClick={changeLang}>
              <Menu.Item style={{ textAlign: "left" }} key="vi">
                <ViSvg />
                {translate("VietNam")}
              </Menu.Item>
              <Menu.Item style={{ textAlign: "left" }} key="en">
                <EnUsSvg />
                {translate("English")}
              </Menu.Item>
            </Menu>
          }
        >
          <span>
            {localeKey === "vi" ? <span> {' '} VN {' '} <ViSvg /> </span> : <span> {' '} EN {' '} <EnUsSvg /> </span>}
          </span>
        </Dropdown> */}
        <span>
          {localeKey === "vi" ? <span> {' '} VN {' '} <ViSvg /> </span> : <span> {' '} EN {' '} <EnUsSvg /> </span>}
        </span>
        <Divider type="vertical" />
        <Dropdown overlay={menu} placement="bottomRight">
          <img src={logo512} className="avatar" alt="avatar" />
        </Dropdown>
      </div>
    </Header>
  )
}

export default Secureheader