import { makeAutoObservable } from "mobx";
import {
  HomeOutlined,
  UnorderedListOutlined,
  FileDoneOutlined,
  SettingOutlined
} from '@ant-design/icons';
import { languageStore } from "../language";
import masterDataService from "~/services/master-data.service";
import { toastService } from "~/services";
import { v4 } from "uuid";
import { authStore } from "../auth";



const makeMenuUi = (data = [], parentSegment = v4()) => {
  const outputs = [];
  for (const item of data) {
    const {
      name = "",
      segment = "",
      children = []
    } = item;
    if (children.length) {
      const listChild = makeMenuUi(children, parentSegment + "-" + segment)
      outputs.push({
        path: "",
        title: name,
        nodeId: ["form", "report", parentSegment, segment].join("-"),
        children: listChild
      })
    } else {
      outputs.push({
        path: "/secure/form-report/" + segment,
        title: name,
        nodeId: ["form", "report", parentSegment, segment].join("-"),
      })
    }

  }
  return outputs;
}

export class LayoutConfigStore {

  constructor() {
    makeAutoObservable(this);
  }
  menuFixedData = [
    //  Trang chủ 0
    {
      path: "/secure/dashboard",
      labeIcon: HomeOutlined,
      title: "Trang chủ",
      nodeId: "dashboard",
      children: []
    },
    // Báo cáo 1
    {
      path: "",
      labeIcon: FileDoneOutlined,
      title: "Báo Cáo",
      nodeId: "reprot",
      children: [
        {
          path: "/secure/management-report",
          // labeIcon: AssignmentOutlined,
          title: "Báo Cáo Quản Trị",
          nodeId: "management-report",
        },
        {
          path: "",
          // labeIcon: AssignmentOutlined,
          title: "Báo Cáo Đối Soát",
          nodeId: "reconciliation",
          children: [
            {
              path: "/secure/data-reconciliation",
              // labeIcon: AssignmentOutlined,
              title: "Đối Soát Dữ Liệu",
              nodeId: "data-reconciliation",
            },
            {
              path: "/secure/data-aggregation",
              // labeIcon: AssignmentOutlined,
              title: "Tổng hợp dữ liệu upload",
              nodeId: "data-aggregation",
            }
          ]
        },

      ]
    },

    // Biểu mẫu 2
    {
      path: "",
      labeIcon: UnorderedListOutlined,
      title: "Biểu mẫu",
      nodeId: "form-report",
      children: [
      ]
    },

    // Cấu hình

    {
      path: "",
      labeIcon: SettingOutlined,
      title: "Cấu Hình",
      nodeId: "configuration",
      children: [
        // {
        //   path: "/secure/target-config",
        //   // labeIcon: SettingsOutlined,
        //   title: "Chỉ Tiêu",
        //   nodeId: "configuration-item",

        // },
        // {
        //   path: "",
        //   // labeIcon: SettingsOutlined,
        //   title: "Dữ Liệu Tổng Quát",
        //   nodeId: "configuration-company-data-1",
        //   children: [
        //     {
        //       path: "/secure/update-master-data-report-item",
        //       // labeIcon: SettingsOutlined,
        //       title: "Chỉ tiêu & Báo cáo",
        //       nodeId: "update-master-data-report-item",
        //     }
        //   ]
        // }
      ]
    },
  ];
  isloadedMenu = false;

  menus = this.menuFixedData;

  collapsed = false;
  toggle = () => {
    this.collapsed = !this.collapsed
  };

  loadMenu = async () => {
    const { isMaster = false } = authStore?.userSessionDto || {};
    const { menuFixedData } = this;
    try {
      const menuFormReport = await masterDataService.listMenuFormReport();
      const menuFormReportUi = makeMenuUi(menuFormReport);
      menuFixedData[2].children = menuFormReportUi;
      if (isMaster) {
        menuFixedData[3].children = [
          // {
          //   path: "/secure/target-config",
          //   // labeIcon: SettingsOutlined,
          //   title: "Chỉ Tiêu",
          //   nodeId: "configuration-item",

          // },
          {
            path: "/secure/group-role",
            // labeIcon: SettingsOutlined,
            title: languageStore.translate("[MainMenu]Group user"),
            nodeId: "configuration-group-role",

          },
          {
            path: "/secure/user-config",
            // labeIcon: SettingsOutlined,
            title: "Người Dùng",
            nodeId: "configuration-user-config",
          },
          // {
          //   path: "",
          //   // labeIcon: SettingsOutlined,
          //   title: "Dữ Liệu Tổng Quát",
          //   nodeId: "configuration-company-data-1",
          //   children: [
          //     {
          //       path: "/secure/update-master-data-report-item",
          //       // labeIcon: SettingsOutlined,
          //       title: "Chỉ tiêu & Báo cáo",
          //       nodeId: "update-master-data-report-item",
          //     }
          //   ]
          // }
        ]
      } else {
        menuFixedData[3] = {};
        // menuFixedData[3].children = [
        //   {
        //     path: "/secure/target-config",
        //     // labeIcon: SettingsOutlined,
        //     title: "Chỉ Tiêu",
        //     nodeId: "configuration-item",

        //   },
        //   {
        //     path: "",
        //     // labeIcon: SettingsOutlined,
        //     title: "Dữ Liệu Tổng Quát",
        //     nodeId: "configuration-company-data-1",
        //     children: [
        //       {
        //         path: "/secure/update-master-data-report-item",
        //         // labeIcon: SettingsOutlined,
        //         title: "Chỉ tiêu & Báo cáo",
        //         nodeId: "update-master-data-report-item",
        //       }
        //     ]
        //   }
        // ]
      }
    } catch (error) {
      menuFixedData[3] = {};
      toastService.handleError(error);
    }

    this.menus = [...menuFixedData];
    this.isloadedMenu = true;
  }

}

export const layoutConfigStore = new LayoutConfigStore();