import { message as messageRef, } from "antd";
import { authStore } from "~/stores/auth";
import { navService } from "./nav.service";
export class ToastService {
  config() {
    // messageRef.config({
    //   maxCount: 3,
    // })
  }
  info(content, duration, onClose) {
    this.config();
    return messageRef.info(content, duration, onClose);
  }
  success(content = "DONE", duration, onClose) {
    this.config();
    return messageRef.success(content, duration, onClose);
  }
  ;
  error(content, duration, onClose) {
    this.config();
    return messageRef.error(content, duration, onClose);
  }
  ;
  warning(content, duration, onClose) {
    this.config();
    return messageRef.warning(content, duration, onClose);
  }
  ;
  loading(content, duration, onClose) {
    this.config();
    return messageRef.loading(content, duration, onClose);
  }
  ;
  open(args) {
    this.config();
    return messageRef.open(args);
  }
  ;
  handleError(err) {
    console.log('-------------------');
    console.log({ err });
    console.log('-------------------');
    if (err.httpCode === 401) {
      this.error("Hết phiên đăng nhập hoặc Tài khoản không có quyền truy cập");
      authStore.logout();
      return;
    }
    // if (err.message === "User not existed system") {
    //   this.error(err.message || "Unknown");
    //   authStore.logout();
    //   return;
    // }
    this.error(err.message || "Unknown");
  }
}
export const toastService = new ToastService();
