import { makeAutoObservable, observable } from "mobx"
import { UserListReq } from "~/dto";
import { toastService } from "~/services";
import userConfigService from "~/services/user-config.service";



class UserConfigStore {

  constructor() {
    makeAutoObservable(this);
  }
  isLoading = false;
  data = [];
  total = 0;
  req = new UserListReq();
  selectedItem = undefined;
  loadData = async () => {
    try {
      this.isLoading = true;
      const { pageIndex, pageSize, filter } = this.req;
      let filters = "";
      if (filter.isActive) {
        filters = `u.ACTIVE=${filter.isActive}`
      }
      if (filter.keyword) {
        filters = filters ? `${filters} and (REGEXP_CONTAINS(LOWER(u.EMAIL),LOWER('${filter.keyword}')) or REGEXP_CONTAINS(LOWER(u.FULL_NAME),LOWER('${filter.keyword}')))`
          : `REGEXP_CONTAINS(LOWER(u.EMAIL),LOWER('${filter.keyword}')) or REGEXP_CONTAINS(LOWER(u.FULL_NAME),LOWER('${filter.keyword}'))`
      }
      const res = await userConfigService.list({
        pageIndex,
        pageSize,
        filters
      })
      this.data = res.data;
      this.total = res.total;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      toastService.handleError(error);
    }
  }

  delete = async (item) => {
    try {
      this.isLoading = true;
      const { email } = item;
      const res = await userConfigService.delete({ email });
      this.data = this.data.filter(v => v.email !== email);
      this.total = this.total - 1;
      this.isLoading = false;
      toastService.success();
    } catch (error) {
      this.isLoading = false;
      toastService.handleError(error);
    }
  }



}
export const userConfigStore = new UserConfigStore()