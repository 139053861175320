import React from "react";
import PropTypes from 'prop-types';

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';

// @material-ui/icons
import GetApp from '@material-ui/icons/GetApp';
import Publish from '@material-ui/icons/Publish';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import Loop from '@material-ui/icons/Loop';

// core components
import UploadDialog from "../Dialog/UploadDialog";
import AddDialog from "../Dialog/AddDialog";

import styles from "../../assets/jss/material-dashboard-react/components/enhancedTableToolbarStyle.js";

const useStyles = makeStyles(styles);

export default function EnhancedTableToolbar(props) {
  const classes = useStyles();
  const [openUpload, setOpenUpload] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [type, setType] = React.useState("");
  const { numSelected, rows, hiddenBtnAdd, hiddenBtnDownloadForm, hiddenBtnExport, hiddenBtnUpload, showHistoryTitle, showHideAlert, title } = props;
  const [form, setForm] = React.useState({
    detail: {
      id_company: '',
      status: false,
      name_company: '',
      location: '',
      description: ''
    }
  });

  const openUploadDialog = () => {
    setOpenUpload(true);
  }

  const openAddDialog = () => {
    setOpenAdd(true);
    setType("add");
  }

  const handleCloseDialogUpload = () => {
    setOpenUpload(false);
  }

  const handleCloseDialogAdd = () => {
    setOpenAdd(false);
  }

  return (
    <Toolbar
      className={classes.root}>
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <div>
          { showHistoryTitle
              ? <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                  <Loop />
                  Lịch Sử Cập Nhật
                </Typography>
              : <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                  Số lượng: {rows.length}
                </Typography>
          }
        </div>
        
      )}
      <div>
        { hiddenBtnDownloadForm
          ? <Button variant="outlined" color="primary" className={classes.btnToolbar}>
              <GetApp className={classes.pr5} />
              Tải Biểu Mẫu
            </Button>
          : null
        }

        { hiddenBtnExport
          ? null
          : <Button variant="outlined" color="primary" className={classes.btnToolbar}>
              <GetApp className={classes.pr5} />
              Tải Xuống
            </Button>
        }
        
        { hiddenBtnUpload
          ? null
          : <Button variant="outlined" color="primary" className={classes.btnToolbar} onClick={openUploadDialog}>
              <Publish className={classes.pr5} />
              Tải Lên
            </Button>
        }
        

        { hiddenBtnAdd
          ? null
          : <Button variant="contained" color="primary" className={classes.btnToolbar} onClick={openAddDialog}>
              <AddCircleOutline className={classes.pr5} />
              Thêm
            </Button>
        }
      </div>

      <UploadDialog openUpload={openUpload} onCloseDialog={handleCloseDialogUpload} showHideAlert={showHideAlert}></UploadDialog>

      <AddDialog openAdd={openAdd} onCloseDialog={handleCloseDialogAdd} title={title} type={type} form={form}></AddDialog>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  rows: PropTypes.array,
  hiddenBtnAdd: PropTypes.bool,
  hiddenBtnDownloadForm: PropTypes.bool,
  hiddenBtnExport: PropTypes.bool,
  hiddenBtnUpload: PropTypes.bool,
  showHistoryTitle: PropTypes.bool,
  showHideAlert: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string
};
