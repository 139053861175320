import { languageStore } from "./language";
import { authStore } from "./auth"
import { userConfigStore, userActionStore } from "./user-config"
import { layoutConfigStore, masterDataStore } from "./master-data"
import { groupRoleListStore, groupRoleDetailStore } from "./group-role";
import { formReportStore } from "./form-report";
import { dataReconciliationStore } from "./data-reconciliation";
import { dataAggregationStore } from "./data-aggregation";
export const stores = {
  authStore: authStore,
  languageStore: languageStore,
  userConfigStore: userConfigStore,
  userActionStore: userActionStore,
  masterDataStore: masterDataStore,
  groupRoleListStore: groupRoleListStore,
  groupRoleDetailStore: groupRoleDetailStore,
  formReportStore: formReportStore,
  layoutConfigStore: layoutConfigStore,
  dataReconciliationStore: dataReconciliationStore,
  dataAggregationStore: dataAggregationStore,
};
